import { Delete, Edit } from "@mui/icons-material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { Box, Grid, IconButton, Tabs, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Button from "../../../components/buttons/Button";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import CardTitle from "../../dashboard/CardTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import TextInput from "../../../fields/text/TextInput";
import { useDispatch } from "react-redux";
import Storage from "../../../utils/Storage";
import {
  // createProjectAhj,
  getAhjCodes,
} from "../../../redux/slices/projectSlice";
import { modalStyle } from "../../constants/ConstData";
import BasicModal from "../../modal/Modal";
import CloseIcon from "../../../assets/images/close";
import useWindowSize from "../../../utils/useWindowSize";
import {
  a11yProps,
  CustomTabPanel,
  StyledTab,
} from "../../constants/Constants";
import ModalHead from "../../modal/ModalHead";
import ModalBody from "../../modal/ModalBody";
import AhjGenInfo from "./AhjGenInfo";
import {
  createAHJInfo,
  getAHJInfo,
} from "../../../redux/slices/additionalInfoSlice";
import Loading from "../../loading/Loading";
import { useParams } from "react-router-dom";

const AhjInfo = () => {
  const [openViewModal, setOpenViewModal] = useState(false);
  // const [ahjValue, setAhjValue] = useState(ahjDetails ?? {});
  // const [description, setDescription] = useState(null);
  const projectData = useSelector((state) => state.project.projectData);
  const ahjDetails = useSelector((state) => state.additionalInfo.ahjDetails);

  const ahjLoading = useSelector((state) => state.additionalInfo.ahjLoading);

  const snackbarData = useSelector((state) => state.form.snackbarData);
  const addInfoloading = useSelector((state) => state.additionalInfo.loading);

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [tabValue, setTabValue] = useState(0);
  const [genIfoEdit, setGenIfoEdit] = useState(false);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    //resolver: yupResolver(schema),
    defaultValues: {
      ahj: "",
      // codeDetails: [
      //   {
      //     itemid: null,
      //     code: "",
      //     data: "",
      //   },
      // ],
    },
  });
  // const { fields, append, prepend, remove } = useFieldArray({
  //   name: "codeDetails",
  //   control,
  // });
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const onSubmit = (ahjData) => {
    // let codeObjs = {};
    // if (ahjData.codeDetails.length > 0) {
    //   ahjData.codeDetails.map((item) => {
    //     codeObjs[`${item.data}`] = item.code;
    //   });

    if (ahjDetails?.id) {
      dispatch(
        createAHJInfo({
          url: `/crm/org/${orgId}/proj/${projectData.id}/AHJ`,
          params: {
            data: "ahjdtl",
            // orgId,
            // id: projectData.id,
          },
          data: {
            ahjdtl: [{ ...ahjData, id: ahjDetails?.id }],
          },
        })
      );
    } else {
      dispatch(
        createAHJInfo({
          url: `/crm/org/${orgId}/proj/${projectData.id}/AHJ`,
          params: {
            data: "ahjdtl",
            // orgId,
            // id: projectData.id,
          },
          data: {
            ahjdtl: [ahjData],
          },
        })
      );
    }
  };

  // const ahjCodes = useSelector((state) => state.project.ahjCodes);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle form submission
  // };

  // const onSubmit = (data) => {
  //   // Handle form submission logic here
  // };
  // const [rows, setRows] = useState([{ code: "", data: "" }]);
  // const [codesData, setCodesData] = useState([]);
  // const handleAddRow = () => {
  //   setRows([...rows, { code: "", data: "" }]);
  //   setCodesData([...codesData, { codeName: "", data: "" }]);
  // };

  // const handleChange = (index, field, value) => {
  //   const updatedRows = [...rows];
  //   updatedRows[index] = { ...updatedRows[index], [field]: value };
  //   setRows(updatedRows);
  // };

  // const handleRemoveRow = (index) => {
  //   setRows(rows.filter((_, rowIndex) => rowIndex !== index));
  //   setCodesData(codesData.filter((_, rowIndex) => rowIndex !== index));
  // };

  const editAhjGenIfo = () => {
    setOpenViewModal(true);
    setGenIfoEdit(true);
  };

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (ahjDetails?.ahj) {
      setValue("ahj", ahjDetails?.ahj);
    }

    if (ahjDetails?.utilityProvider) {
      setValue("utilityProvider", ahjDetails?.utilityProvider);
    }

    if (ahjDetails?.utlReq) {
      setValue("utlReq", ahjDetails?.utlReq);
    }

    if (ahjDetails?.lineside) {
      setValue("lineside", ahjDetails?.lineside);
    }

    if (ahjDetails?.pvmeter) {
      setValue("pvmeter", ahjDetails?.pvmeter);
    }

    if (ahjDetails?.elev) {
      setValue("elev", ahjDetails?.elev);
    }
  }, [ahjDetails]);

  const loadAdditionalInfo = () => {
    dispatch(
      getAHJInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=ahjdtl`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (
      snackbarData?.createSuccess &&
      snackbarData?.params?.data === "ahjdtl"
    ) {
      console.log("params=----------->", snackbarData?.params);
      loadAdditionalInfo();
      setOpenViewModal(false);
    }
  }, [snackbarData]);

  // const Codes = [
  //   {
  //     id: 1,
  //     label: "ifc",
  //   },
  //   {
  //     id: 2,
  //     label: "ibc",
  //   },
  //   {
  //     id: 3,
  //     label: "irc",
  //   },
  //   {
  //     id: 4,
  //     label: "nec",
  //   },
  //   {
  //     id: 5,
  //     label: "iecc",
  //   },
  //   {
  //     id: 6,
  //     label: "fpc",
  //   },
  //   {
  //     id: 7,
  //     label: "fbc",
  //   },
  // ];
  // const loadCodes = () => {
  //   dispatch(
  //     getAhjCodes({
  //       url: `/crm/org/${orgId}/AHJ/list`,
  //       params: {
  //         orgId,
  //         state: data.addressDetails?.state,
  //         county: data.addressDetails?.county,
  //       },
  //     })
  //   );
  // };
  // useEffect(() => {
  //   loadCodes();
  // }, []);
  if (ahjLoading || addInfoloading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <CardTitle title="Utility Details" type="project_view"></CardTitle>
      <div className="card_content">
        <TextField
          // id="standard-password-input"
          label={"State"}
          //autoComplete="current-password"
          value={projectData.addressDetails?.state}
          variant="standard"
          sx={{
            width: width < 1367 ? "280px" : "300px",
          }}
          /// disabled={true}
          //style={{ marginBottom: "10px" }}
        />

        <TextField
          // id="standard-password-input"
          value={projectData.addressDetails?.county}
          label={"County"}
          autoComplete="current-password"
          variant="standard"
          sx={{
            width: width < 1367 ? "280px" : "300px",
            // marginBottom: "10px",
          }}
        />
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "10px" }}
          >
            <Tabs
              // textColor="secondary"
              // indicatorColor="secondary"

              TabIndicatorProps={{
                style: {
                  backgroundColor: "#189d3d",
                },
              }}
              value={tabValue}
              onChange={handleTab}
              aria-label="basic tabs example"
            >
              <StyledTab
                // icon={<HomeWorkIcon />}
                iconPosition="start"
                label="AHJ"
                sx={{
                  fontSize: "10px",
                }}
                {...a11yProps(0)}
              />
              <StyledTab
                // icon={<PointOfSaleIcon />}
                iconPosition="start"
                label="Utility Provider"
                sx={{
                  fontSize: "10px",
                }}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={tabValue} index={0}>
            <Controller
              name={`ahj`}
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <TextInput
                  {...field}
                  variant="mui"
                  name="ahj"
                  label="AHJ"
                  inputClass="input_field_style"
                  fieldSize="small"
                  marginBottom="0px"
                  fieldStyle="standard"
                  autoFocus={false}
                />
              )}
            />

            <div style={{ marginTop: "20px" }}>
              <div className="dashboard_card_title_wrapper_project_view p_0">
                <p className={"dashboard_card_title_no_bg "}>
                  AHJ General Info
                </p>
                <IconButton
                  className="marginL10"
                  size="small"
                  onClick={editAhjGenIfo}
                >
                  <Edit />
                </IconButton>
              </div>
              <div style={{ marginTop: "10px" }}>
                {ahjDetails?.genReq ? (
                  <>
                    <p>
                      {ahjDetails.genReq.split("").length > 15
                        ? ahjDetails.genReq.substring(0, 15) + "..."
                        : ahjDetails.genReq}

                      {ahjDetails.genReq.split("").length > 15 && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenViewModal(true)}
                        >
                          Read more
                        </span>
                      )}
                    </p>
                  </>
                ) : (
                  <Button
                    type="button"
                    onClick={editAhjGenIfo}
                    className="default_button link_blue border_none p_0"
                  >
                    Add AHJ General Info
                  </Button>
                )}
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Controller
              name={`utilityProvider`}
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <TextInput
                  {...field}
                  variant="mui"
                  name="utilityProvider"
                  label="Utility Provider"
                  inputClass="input_field_style"
                  fieldSize="small"
                  marginBottom="0px"
                  fieldStyle="standard"
                  autoFocus={false}
                />
              )}
            />

            <div className="d_flex align_items_center justify_content_between">
              <Controller
                name={`utlReq`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="utlReq"
                    label="Utility Requirement"
                    inputClass="input_field_style"
                    fieldSize="small"
                    marginBottom="0px"
                    fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />

              <Controller
                name={`lineside`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="lineside"
                    label="Line Side"
                    inputClass="input_field_style"
                    fieldSize="small"
                    marginBottom="0px"
                    marginLeft="10px"
                    fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </div>
            <div className="d_flex align_items_center justify_content_between">
              <Controller
                name={`pvmeter`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="pvmeter"
                    label="PV meter"
                    inputClass="input_field_style"
                    fieldSize="small"
                    marginBottom="0px"
                    fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />

              <Controller
                name={`elev`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="elev"
                    label="Elevation"
                    inputClass="input_field_style"
                    fieldSize="small"
                    marginBottom="0px"
                    marginLeft="10px"
                    fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </div>
          </CustomTabPanel>
          {/* {rows.map((row, index) => (
            <div
              style={{ width: "300px" }}
              key={index}
              className="d_flex justify_content_between marginT10"
            >
              <AutoCompleteAsync
                sx={{ width: "150px",}}
                name={`code${index}`}
                label="Code"
                options={Codes}
                onChange={(event, newValue, reason) => {
                  if(reason==='clear'){

                  }else{
                    
                  }
                  // if (reason === "clear") {
                  //   handleChange(index,`code${index}`,'')
                  //   setOfficeSelected(null);
                  // } else {
                  //   handleChange(index,`code${index}`,newValue)

                  //   setOfficeSelected(newValue);
                  //}
                }}
                renderOption={(props, option) => (
                  <Box style={{ fontSize: 13 }} {...props}>
                    {option.label}
                  </Box>
                )}
                getOptionLabel={(option) => {
                  if (option.name) {
                    return option?.name;
                  } else {
                    return "Please Select";
                  }
                }}
                variant="standard"
              />
              <TextField
                label="Data"
                value={row.data}
                onChange={(e) => handleChange(index, "data", e.target.value)}
                autoComplete="current-password"
                variant="standard"
                sx={{ width: "100px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                {rows.length > 1 && (
                  <IconButton onClick={() => handleRemoveRow(index)}>
                    <Delete style={{ cursor: "pointer" }} />
                  </IconButton>
                )}
              </div>
            </div>
          ))} */}
          {/* {fields.map((field, index) => (
            <div
              style={{ width: "350px" }}
              key={field.id}
              className="d_flex justify_content_between marginT10"
            >
              <Controller
                name={`codeDetails.${index}.code`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <AutoCompleteAsync
                    sx={{ width: "150px", marginTop: "1px" }}
                    // name={`code${index}`}
                    {...field}
                    label="Code"
                    options={ahjCodes}
                    value={codesData[index]}
                    onChange={(event, newValue, reason) => {
                      console.log("reason", reason);
                      console.log("new value", newValue);
                      if (reason === "clear") {
                      } else {
                        let temp = codesData;
                        temp[index] = {
                          ...codesData[index],
                          code: newValue.label,
                        };
                        console.log("temp", temp);
                        setCodesData(temp);
                        setValue(`codeDetails.${index}.code`, newValue.id);
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box style={{ fontSize: 13 }} {...props}>
                        {option.name}
                      </Box>
                    )}
                    getOptionLabel={(option) => {
                      if (option.name) {
                        return option?.name;
                      } else {
                        return "Please Select";
                      }
                    }}
                    variant="standard"
                  />
                )}
              />

              <Controller
                name={`codeDetails.${index}.data`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Data"
                    autoComplete="current-password"
                    variant="standard"
                    sx={{ width: "100px" }}
                  />
                )}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                {fields.length > 0 && (
                  <IconButton onClick={() => remove(index)}>
                    <Delete style={{ cursor: "pointer" }} />
                  </IconButton>
                )}
              </div>
            </div>
          ))} */}
          {/* <div
            className="d_flex justify_content_center "
            style={{ cursor: "pointer", marginTop: "20px", width: "300px" }}
          >
            <AddCircleOutline
              onClick={(e) => {
                e.preventDefault();
                append({
                  itemid: null,
                  code: "",
                  data: "",
                });
              }}
            />
          </div> */}
          <div>
            <Button type="submit" className="primary_button marginT10">
              Save
            </Button>
          </div>
        </form>
        <BasicModal
          isOpen={openViewModal}
          onClose={() => setOpenViewModal(false)}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            // height: "500px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <>
            <ModalHead title="AHJ General Info">
              <div className="modal_header_button_wrapper">
                <IconButton
                  onClick={() => {
                    setOpenViewModal(false);
                    setGenIfoEdit(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </ModalHead>

            <ModalBody>
              <AhjGenInfo genIfoEdit={genIfoEdit} />
            </ModalBody>
          </>
        </BasicModal>
      </div>
    </div>
  );
};

export default AhjInfo;
