import { useDispatch, useSelector } from "react-redux";
import ActivityTimeline from "../../activity/ActivityTimeline";
import Loading from "../../loading/Loading";
import { useEffect } from "react";
import { getProjectProgress } from "../../../redux/slices/projectSlice";
import Storage from "../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectProgress = ({ loadProjectStatusList }) => {
  const { t } = useTranslation();
  const progressData = useSelector((state) => state.project.progressData);
  const progressLoading = useSelector((state) => state.project.progressLoading);
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );

  const createData = useSelector((state) => state.form.createData);
  const dispatch = useDispatch();
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const { id } = useParams();
  const loadProjectsProgess = () => {
    dispatch(
      getProjectProgress({
        url: `crm/org/${orgId}/proj/${id}/progress`,
        params: {},
      })
    );
  };
  useEffect(() => {
    loadProjectsProgess();
    loadProjectStatusList();
  }, []);

  useEffect(() => {
    if (createData?.createSuccess) {
      loadProjectsProgess();
    }
  }, [createData]);

  if (progressLoading) {
    return <Loading />;
  }

  return (
    <ActivityTimeline
      timelineData={projectStatusList}
      progressData={progressData}
      type="project_progress"
    />
  );
};

export default ProjectProgress;
