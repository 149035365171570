import * as React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { unstable_capitalize as capitalize } from "@mui/utils";
import { Chip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Padding } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    success: {
      main: "#37906b14",
      contrastText: "#189D3D",
    },
    warning: {
      main: "#faf0bcc2",
      contrastText: "#F89A0E",
    },

    error: {
      main: "#90373714",
      contrastText: "#F80E0E",
    },
  },
});

function ColorShowcase({ color, status, type, size }) {
  return (
    <Chip
      style={{
        cursor: "pointer",
        border: "1px solid #ff6347",
        width:'100%'
      }}
      label={capitalize(status)}
      color={color}
      size={size ?? "small"}
      siz
      // onClick={handleClick}
      onDelete={() => {}}
      deleteIcon={type === "dropdown" ? <ArrowDropDownIcon /> : <></>}
    />
  );
}

ColorShowcase.propTypes = {
  color: PropTypes.oneOf(["success", "warning", "error"]).isRequired,
};

export default function RottingStatus({ status, statusType, type, size }) {
  return (
    <ThemeProvider theme={theme}>
      <ColorShowcase
        status={status}
        color={statusType}
        type={type}
        size={size}
      />
    </ThemeProvider>
  );
}
