import React, { useEffect } from "react";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import DefaultAssigneNew from "../view/DefaultAssigneNew";
import { useSelector } from "react-redux";
import { getGroupList, getUserListOrg } from "../../../redux/slices/orgSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const MultipleAssignee = () => {
  const { id } = useParams();
  const userList = useSelector((state) => state.org.userListOrg);
  const group = useSelector((state) => state.org.group);
  const dispatch = useDispatch();
  const createData = useSelector((state) => state.form.createData);

  const loadGroups = () => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${id}`,
        params: {},
      })
    );
  };
  const loadUsers = () => {
    dispatch(
      getUserListOrg({
        url: `/crm/user/organization/${id}/users`,
      })
    );
  };
  useEffect(() => {
    loadGroups();
  }, [createData]);
  useEffect(() => {
    loadUsers();
  }, [createData]);
  return (
    <CardItem height="890px">
      <div className="card_container">
        {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
        <div style={{ padding: "20px" }}>
          <ToolBar title={"MultipleAssigne"}></ToolBar>
        </div>
        <div style={{ padding: "20px" }}>
          <DefaultAssigneNew users={userList} groups={group} />
        </div>
      </div>
    </CardItem>
  );
};

export default MultipleAssignee;
