import { createSlice } from "@reduxjs/toolkit";

const materialSlice = createSlice({
  name: "material",
  initialState: {
    materials: [],
    moduleList: [],
    inverterList: [],
    materialType: [],
    bomList: [],
    loading: false,
    bomLoading: false,
  },
  reducers: {
    createNewMaterials(state) {
      state.loading = true;
      return state;
    },
    createNewBom(state) {
      state.bomLoading = true;
      return state;
    },
    getBomList(state) {
      state.bomLoading = true;
      return state;
    },

    getMaterialType(state) {
      state.loading = true;
      return state;
    },

    editMaterials(state) {
      state.loading = true;
      return state;
    },
    editBom(state) {
      state.bomLoading = true;
      return state;
    },

    getMaterialList(state) {
      state.loading = true;
      return state;
    },
    getModuleList(state) {
      state.loading = true;
      return state;
    },
    getInverterList(state) {
      state.loading = true;
      return state;
    },
    setMaterialList: (state, action) => {
      state.materials = action.payload;
      state.loading = false;
    },
    setModuleList: (state, action) => {
      state.moduleList = action.payload;
      state.loading = false;
    },
    setInverterList: (state, action) => {
      state.inverterList = action.payload;
      state.loading = false;
    },
    setMaterialType: (state, action) => {
      state.materialType = action.payload;
      state.loading = false;
    },
    setBomList: (state, action) => {
      state.bomList = action.payload;
      state.bomLoading = false;
    },
    setMaterialLoadingFalse: (state, action) => {
      state.loading = false;
    },
    setBomLoadingFalse: (state, action) => {
      state.bomLoading = false;
    },
  },
});

export const {
  createNewMaterials,
  editMaterials,
  getMaterialList,
  getModuleList,
  getInverterList,
  getMaterialType,
  setMaterialType,
  setMaterialList,
  setModuleList,
  setInverterList,
  setMaterialLoadingFalse,
  createNewBom,
  getBomList,
  setBomList,
  editBom,
  setBomLoadingFalse,
} = materialSlice.actions;

export default materialSlice.reducer;
