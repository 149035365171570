import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import EscalatorIcon from "@mui/icons-material/Escalator";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Autocomplete, Box, Grid, Tabs, TextField } from "@mui/material";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  linkWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import profileImage from "../../assets/images/project.jpeg";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
} from "../../components/constants/Constants";
import GroupsCard from "../../components/userProfile/groups/GroupsCard";
import ProjectsCard from "../../components/userProfile/projects/ProjectsCard";
import { auth } from "../../firebase";
import { getUser } from "../../redux/slices/authSlice";
import { setCreateFailed } from "../../redux/slices/formSlice";
import { getGroupList } from "../../redux/slices/orgSlice";
import {
  getProfileData,
  getProfileProjectData,
} from "../../redux/slices/profileSlice";
import { CardItem } from "../../utils/CardItem";
import Storage from "../../utils/Storage";
import { useParams } from "react-router-dom";
import PhoneNumberView from "../../components/PhoneNumber/PhoneNumberView";

const UserNewProfile = () => {
  const { id } = useParams();
  const userId = id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const isOwner = currentOrg?.isOwner;
  const [showNumberField, setShowNumberField] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const currentOrganization = useSelector((state) => state.auth.currentOrg);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [projectParams, setProjectParams] = useState({
    prev: 0,
    assigned: 0,
    created: 1,
  });

  const dispatch = useDispatch();
  const addMobileNumber = () => {
    setShowNumberField(true);
  };
  const confirmationResult = useSelector(
    (state) => state.auth.confirmationResult
  );
  const sendOtp = async () => {
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptcha
      );
      setConfirmationData(confirmation);
    } catch (err) {
      console.log(err);
    }
  };
  const verifyOtp = async () => {
    try {
      const userData = PhoneAuthProvider.credential(
        confirmationData.verificationId,
        otp
      );
      if (userData) {
        linkPhoneEmail(userData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const linkPhoneEmail = async (userData) => {
    await linkWithCredential(userDetails, userData)
      .then((usercred) => {
        const user = usercred.user;
        dispatch(
          getUser({
            url: `crm/user`,
            params: {},
          })
        );
        setConfirmationData(null);
      })
      .catch((error) => {
        dispatch(
          setCreateFailed({
            message: error.message,
            createSuccess: false,
            statusCode: 404,
          })
        );
      });
  };
  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setProjectParams({
        prev: 0,
        assigned: 0,
        created: 1,
      });
      setReloadProjects(!reloadProjects);
    } else if (newValue === 2) {
      setProjectParams({
        prev: 0,
        assigned: 1,
        created: 0,
      });
      setReloadProjects(!reloadProjects);
    } else if (newValue === 3) {
      setProjectParams({
        prev: 1,
        assigned: 0,
        created: 0,
      });
      setReloadProjects(!reloadProjects);
    }
    setValue(newValue);
  };
  const profileData = useSelector((state) => state.profile.profileData);
  const group = useSelector((state) => state.org.group);
  const orgInfo = useSelector((state) => state.auth.orgInfo);
  const projects = useSelector((state) => state.profile.profileProjectData);
  const [reloadProjects, setReloadProjects] = useState(false);

  const getProfile = async () => {
    dispatch(getProfileData({ url: `crm/user/profile`, params: {} }));
  };
  const getGroups = () => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${
          selectedOrganization
            ? selectedOrganization.id
            : currentOrganization.id
        }`,
        params: {},
      })
    );
  };

  const getProjects = () => {
    dispatch(
      getProfileProjectData({
        url: `/crm/user/organization/${
          selectedOrganization
            ? selectedOrganization.id
            : currentOrganization.id
        }/projects`,
        params: {
          id: selectedOrganization
            ? selectedOrganization.id
            : currentOrganization.id,
          ...projectParams,
          page: 1,
          pageSize: 10,
        },
      })
    );
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    getGroups();
    getProjects();
  }, [selectedOrganization, projectParams, reloadProjects]);

  // useEffect(() => {
  //   getProjects();
  // }, [selectedOrganization, reloadProjects]);

  // useEffect(()=>{

  // },[])
  return (
    <div className="project_view">
      <Grid sx={{ marginTop: "16px" }} container spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={3}>
          <CardItem height="810px">
            <div
              className="d_flex flex_column align_items_center justify_content_center"
              style={{ paddingTop: "20px" }}
            >
              <img
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                src={profileImage}
                alt="Profile"
              />
              <h2>{userInfo?.name}</h2>
              <div>
                <div className="d_flex">
                  <p>
                    <b>Email:</b>
                  </p>
                  <p className="marginL10">
                    {profileData.email ? profileData.email : ""}
                  </p>
                </div>
                <div className="d_flex">
                  <p>
                    <b>Mobile:</b>
                  </p>
                  {userInfo?.phone || userDetails?.phoneNumber ? (
                    <p className="marginL10">
                      {/* {userInfo?.phone || userDetails?.phoneNumber} */}
                      <PhoneNumberView
                        phoneNumber={
                          userInfo?.phone || userDetails?.phoneNumber
                        }
                      />
                    </p>
                  ) : showNumberField ? (
                    <div className="marginL10">
                      <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber("+" + phone)}
                      />
                      <button
                        className="primary_button_small marginT10"
                        onClick={sendOtp}
                      >
                        Verify
                      </button>

                      <div className="marginT10" id="recaptcha"></div>

                      {confirmationData && (
                        <div>
                          <input
                            type="text"
                            placeholder="Verification Code"
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          <button
                            className="primary_button_small marginT10"
                            onClick={verifyOtp}
                          >
                            Verify OTP
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p
                      onClick={addMobileNumber}
                      className="marginL10 link_blue"
                    >
                      Add Mobile Number
                    </p>
                  )}
                </div>
              </div>
            </div>
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <CardItem height="810px">
            <div className="card_container">
              {isOwner ? (
                <>
                  <div
                    style={{
                      paddingTop: "20px",
                      paddingLeft: "35px",
                      paddingRight: "55px",
                    }}
                  >
                    <div className="d_flex justify_content_between">
                      <div className="d_flex justify_content_center">
                        <p>
                          <b>
                            {selectedOrganization
                              ? selectedOrganization.name
                              : currentOrganization.name}
                          </b>
                        </p>
                      </div>

                      <div>
                        <Autocomplete
                          defaultValue={currentOrganization.name}
                          disablePortal
                          clearIcon={null}
                          id="combo-box-demo"
                          options={orgInfo?.map((item) => {
                            return {
                              label: item.name,
                              id: item.id,
                            };
                          })}
                          onChange={(e, value) => {
                            setValue(0);
                            setSelectedOrganization({
                              id: value.id,
                              name: value.label,
                            });
                          }}
                          sx={{ width: 300 }}
                          renderInput={(params) => {
                            return (
                              <>
                                <TextField {...params} label="Organization" />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="">
                <Box
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  className="card_content"
                >
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#189d3d",
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <StyledTab
                      icon={<EscalatorIcon />}
                      iconPosition="start"
                      label="Groups"
                      sx={{
                        fontSize: "10px",
                      }}
                      {...a11yProps(0)}
                    />
                    <StyledTab
                      icon={<LocalActivityIcon />}
                      iconPosition="start"
                      label="Created Projects"
                      sx={{
                        fontSize: "10px",
                      }}
                      {...a11yProps(1)}
                    />
                    <StyledTab
                      icon={<DonutSmallIcon />}
                      iconPosition="start"
                      label="Assigned Projects"
                      sx={{
                        fontSize: "10px",
                      }}
                      {...a11yProps(2)}
                    />
                    <StyledTab
                      icon={<DonutSmallIcon />}
                      iconPosition="start"
                      label="Previously Assigned Projects"
                      sx={{
                        fontSize: "10px",
                      }}
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>
                <div
                  className="card_content"
                  style={{ height: "575px", overflowY: "scroll" }}
                >
                  <CustomTabPanel value={value} index={0}>
                    <div style={{ padding: "20px" }}>
                      {group.length > 0 ? (
                        group?.map((grp) => (
                          <GroupsCard
                            groupName={grp.name}
                            groupDescription={grp.desc}
                          />
                        ))
                      ) : (
                        <p className="text_center">No groups found</p>
                      )}
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div style={{ paddingTop: "20px" }}>
                      {projects.length > 0 ? (
                        projects.map((item) => <ProjectsCard project={item} />)
                      ) : (
                        <>
                          <p className="text_center">No projects found</p>
                        </>
                      )}
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <div style={{ paddingTop: "20px" }}>
                      {projects.length > 0 ? (
                        projects.map((item) => <ProjectsCard project={item} />)
                      ) : (
                        <>
                          <p className="text_center">No projects found</p>
                        </>
                      )}
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <div style={{ paddingTop: "20px" }}>
                      {projects.length > 0 ? (
                        projects.map((item) => <ProjectsCard project={item} />)
                      ) : (
                        <>
                          <p className="text_center">No projects found</p>
                        </>
                      )}
                    </div>
                  </CustomTabPanel>
                </div>
              </div>
            </div>
          </CardItem>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserNewProfile;
