import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../../redux/slices/titleSlice";
import { useEffect, useState } from "react";
import ToolBar from "../../../components/toolbar/ToolBar";
import Button from "../../../components/buttons/Button";
import PlusIcon from "../../../assets/images/plus";
import BasicModal from "../../../components/modal/Modal";
import "./style.scss";
import { ProjectListItem } from "../../../components/project/list/ProjectListItem";
import ProjectForm from "../create/ProjectForm";
import { getProjectDashboard } from "../../../redux/slices/projectSlice";
import Loading from "../../../components/loading/Loading";
import { modalStyle } from "../../../components/constants/ConstData";
import FilterIcon from "../../../assets/images/filter";
import { Menu, MenuItem, Typography } from "@mui/material";
import Storage from "../../../utils/Storage";
import { clearCurrentFile } from "../../../redux/slices/fileUploadSlice";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";

const filterList = [
  { name: "All Projects", value: 1 },
  { name: "My Projects", value: 0 },
];

const ProjectList = () => {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(null);

  // const orgId = JSON.parse(sessionStorage.getItem("org"))?.id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const loading = useSelector((state) => state.project.loading);
  const [isOpen, setIsOpen] = useState(false);
  const createData = useSelector((state) => state.form.createData);
  const isOwner =
    Storage.read(`org${userInfo?.id}`)?.isModerator === "true" ? true : false;

  const dashValue = Storage.read(`dashowner${userInfo?.id}`);
  const [dashowner, setDashowner] = useState(
    isOwner
      ? dashValue
        ? dashValue
        : { name: "All Projects", value: 1 }
      : null
  );
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const orgLoading = useSelector((state) => state.org.loading);

  const projectDashboard = useSelector(
    (state) => state.project.projectDashboard
  );
  const navigate = useNavigate();

  // const iconSx = {
  //   backgroundColor: "rgba(55, 144, 107, 0.08)",
  //   borderRadius: "4px",
  //   marginRight: "10px",
  // };

  const createNewProject = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const loadProjects = (ownerValue) => {
    const baseUrl = `crm/org/${orgId}/proj/dashboard`;
    dispatch(
      getProjectDashboard({
        url:
          ownerValue && ownerValue === 1
            ? `${baseUrl}?isOrg=${ownerValue}`
            : `${baseUrl}`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (orgId)
      if (dashowner?.value) {
        loadProjects(dashowner?.value);
      } else {
        loadProjects();
      }
  }, [dashowner, orgId]);

  const handleFilters = (event) => {
    event.preventDefault();

    setOpenFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setOpenFilter(null);
  };

  useEffect(() => {
    dispatch(setTitle("Projects"));
    // loadProjects();
  }, []);

  useEffect(() => {
    if (!orgLoading && orgInfo && orgInfo.length === 0) {
      navigate("/org/create");
    }
  }, [orgInfo]);

  useEffect(() => {
    if (createData?.createSuccess) {
      if (dashowner?.value) {
        loadProjects(dashowner?.value);
      } else {
        loadProjects();
      }
      dispatch(clearCurrentFile());
    }
  }, [createData]);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOwner) {
      setDashowner(dashValue ? dashValue : { name: "All Projects", value: 1 });
    }
  }, [isOwner]);

  if (!isOpen && loading) {
    return <Loading />;
  }

  return (
    <div className="project">
      <ToolBar title="Projects">
        <div className="d_flex align_items_center">
          {/* <CustomIconButton icon={WindowIcon} sx={iconSx} />
          <CustomIconButton icon={MenuIcon} sx={iconSx} />
          <Button
            type="submit"
            className="default_button d_flex align_items_center marginR10"
            leftIcon={<FilterIcon color="#75787D" />}
          >
            Filters
          </Button>
          <Button
            type="submit"
            className="default_button d_flex alnpm i react-beautiful-dndign_items_center marginR10"
            leftIcon={<SortIcon color="#75787D" />}
          >
            Sort by
          </Button> */}
          {isOwner && (
            <>
              <Button
                type="submit"
                className="default_button d_flex align_items_center marginR10"
                leftIcon={<FilterIcon color="#75787D" />}
                onClick={handleFilters}
              >
                {dashowner?.name}
              </Button>
              <Menu
                sx={{
                  mt: "30px",
                  ".css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "150px",
                  },
                }}
                style={{ height: "400px" }}
                id="menu-appbar"
                anchorEl={openFilter}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(openFilter)}
                onClose={handleCloseFilterMenu}
              >
                {filterList?.map((filter, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      // loadProjects(filter.value);
                      Storage.write(`dashowner${userInfo?.id}`, filter);
                      setDashowner(filter);
                      // setStatus(status);
                      // // setValue("statusId", status?.id);
                      // const statusData = {
                      //   status: {
                      //     id: status?.id,
                      //     name: status?.name,
                      //   },
                      // };
                      // dispatch(
                      //   updateProjectStatus({
                      //     url: `crm/org/${orgId}/proj/${data?.projectId}/status`,
                      //     data: statusData,
                      //     params: {},
                      //   })
                      // );
                      handleCloseFilterMenu();
                    }}
                    // {...register("statusId", {})}
                  >
                    {/* <IconButton sx={{ p: 0, marginRight: "8px" }}>
                          <Avatar
                            style={{ width: "30px", height: "30px" }}
                            {...stringAvatar(user?.name)}
                          />
                        </IconButton> */}
                    <div className="d_flex align_items_center w_100">
                      <div
                        style={{
                          width: "20%",
                          // display: "flex",
                          // alignItems: "center",
                        }}
                      >
                        {filter?.value === dashowner?.value && (
                          <DoneIcon fontSize="12px" color="success" />
                        )}
                      </div>
                      <div style={{ width: "80%" }}>
                        <Typography className="font_12 m_0">
                          {filter?.name}
                        </Typography>
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          {projectDashboard?.head?.length > 0 && (
            <Button
              type="submit"
              className="primary_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "18px",
                padding: "5px 20px",
              }}
              leftIcon={<PlusIcon color="#fff" />}
              onClick={createNewProject}
            >
              Add Project
            </Button>
          )}
        </div>
      </ToolBar>
      {/* <div style={{ width: "100%", overflowX: "auto" }} className=""> */}
      <ProjectListItem projectDashboard={projectDashboard} />
      <BasicModal isOpen={isOpen} onClose={handleClose} sx={modalStyle}>
        <ProjectForm onClose={handleClose} />
      </BasicModal>
      {/* </div> */}
    </div>
  );
};

export default ProjectList;
