import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/slices/titleSlice";
import Loading from "../../components/loading/Loading";
import CustomBigCalendar from "../../components/calendar/CustomBigCalendar";
import { useCallback } from "react";
import Button from "../../components/buttons/Button";
import GoogleIcon from "@mui/icons-material/Google";
import AppointmentForm from "../../components/project/view/appointment/AppointmentForm";
import { getAppointmentList } from "../../redux/slices/appointmentSlice";
import BasicModal from "../../components/modal/Modal";
import EventView from "./EventView";
import { modalStyle } from "../../components/constants/ConstData";
import { formatEvents } from "../../components/constants/Constants";
import Storage from "../../utils/Storage";

const Calendar = () => {
  const dispatch = useDispatch();

  const [events, setEvents] = useState([]);
  const [eventItem, setEventItem] = useState({});
  const accessToken = localStorage.getItem("access_token");
  var CLIENT_ID =
    "240602337-6vqi21gvcff244s5copse3tpueofpe7i.apps.googleusercontent.com";
  var API_KEY = process.env.REACT_APP_FIREBASE_AUTH_API_KEY;
  // var DISCOVERY_DOCS = [
  //   "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  // ];
  var SCOPES = "https://www.googleapis.com/auth/calendar.events";
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;

  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endDate: new Date(),
    endTime: new Date(),
  });

  const appointmentList = useSelector(
    (state) => state.appointment.appointmentList
  );
  const loading = useSelector((state) => state.appointment.loading);

  const loadAppointmentList = () => {
    dispatch(
      getAppointmentList({
        url: `crm/org/${orgId}/appointments`,
        params: {},
      })
    );
  };

  const handleClose = () => {
    setIsViewOpen(false);
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  const handleSelectEvent = (event) => {
    setIsViewOpen(true);
    setEventItem({ appointmentData: event });
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#189d3d";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "10px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      padding: "5px",
    };
    return {
      style: style,
    };
  };

  useEffect(() => {
    dispatch(setTitle("Calendar"));
    if (orgId) {
      loadAppointmentList();
    }
  }, [orgId]);

  useEffect(() => {
    if (appointmentList) {
      setEvents(formatEvents(appointmentList));
    }
  }, [appointmentList]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ padding: "25px" }}>
      {events && (
        <CustomBigCalendar
          events={events}
          onSelectEvent={handleSelectEvent}
          eventStyleGetter={eventStyleGetter}
        />
      )}

      <BasicModal
        isOpen={isViewOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: { xs: "auto" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "420px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <EventView
          view="calendar"
          eventItem={eventItem}
          onClose={handleClose}
        />
      </BasicModal>
      <BasicModal
        isOpen={isFormOpen}
        onClose={handleFormClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <AppointmentForm
          type="proj"
          // loadData={loadData}
          onClose={handleFormClose}
          value={formValue}
        />
      </BasicModal>
    </div>
  );
};

export default Calendar;
