import { createSlice } from "@reduxjs/toolkit";


const permissionSlice=createSlice({
    name:"permissions",
    initialState:{
        creatingPermissions:false,
        loadingPermissions:false,
        permissionsData:[]
    },
    reducers:{
        getPermissions(state){
            state.loadingPermissions=true;
            console.log("getting permissions");
            return state;
        },
        setPermissions(state,action){
            state.loadingPermissions=false;
            state.permissionsData=action.payload;
           // state.creatingPermissions=false;
            console.log("users*************inside set permissions")
           // return state;
        },
        createPermission(state){
           // console.log('inside create module slice');
           state.creatingPermissions=true;
            return state;
        }
    }
});

export const  {
    createPermission,
    getPermissions,
    setPermissions
}=permissionSlice.actions;

export default permissionSlice.reducer;