import { useEffect } from "react";
import LoginBg from "../../assets/images/loginbg.png";
import Button from "../../components/buttons/Button";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getUserLogin,
  getUserResetPassword,
} from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/loading/Loading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email address is invalid")
    .required("Email is required"),
});

const ResetPassword = () => {
  const loading = useSelector((state) => state.auth.loading);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const orgInfo = useSelector((state) => state.org.orgInfo);
  const orgLoading = useSelector((state) => state.org.loading);
  const createData = useSelector((state) => state.form.createData);

  const location = useLocation();
  // let { from } = location.state;

  const onSubmit = async (data) => {
    dispatch(getUserResetPassword(data));
  };

  useEffect(() => {
    if (createData?.createSuccess) {
      navigate("/login");
    }
  }, [createData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="reset_password">
      {/* <div className="login_left">
        <img className="login_bg_image" src={LoginBg} alt="Background" />
        <div className="login_left_text_wrapper">
          <div className="text_content_wrapper">
            <p className="logo_text">Logo</p>
            <h1 className="empower_title">
              Empowering society with sustainable{" "}
              <span className="dark_text">energy resources!</span>
            </h1>
            <p className="empower_content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
            <h2 className="login_now_text">Login Now!</h2>
          </div>
        </div>
      </div> */}
      <div className="reset_wrapper">
        <div className="login_form_wrapper">
          <h4 className="lets_started">Reset Password</h4>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <label className="text_medium">Email</label>
            <input
              type="email"
              name="email"
              placeholder="please add your email to reset your password..."
              {...register("email", {
                required: true,
                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              })}
            />

            <p className="error_message marginB10">{errors.email?.message}</p>

            <Button type="submit" className="button_lg primary_button">
              Send
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
