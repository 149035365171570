// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite_user_modal_header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.invite_user_modal_body {
  padding: 20px !important;
}

.invite_user_modal_heading {
  color: "#151515";
  font-family: "Montserrat";
  font-size: "20px";
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/organisation/view/InviteUserModal.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,aAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,yBAAA;EACA,iBAAA;EACA,gBAAA;AACJ","sourcesContent":[".invite_user_modal_header{\n    display: flex;\n    justify-content: space-between;\n    padding: 20px;\n}\n\n.invite_user_modal_body{\n    padding: 20px !important;\n}\n\n.invite_user_modal_heading{\n    color: \"#151515\";\n    font-family:'Montserrat' ;\n    font-size: '20px';\n    font-weight: 700;\n    // : \"Montserrat\",\n    // fontSize: \"20px\",\n    // fontStyle: \"normal\",\n    // fontWeight: \"700\",\n    // lineHeight: \"125%\",\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
