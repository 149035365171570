import Hotjar from "@hotjar/browser";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import CustomerChat from "./components/chat/CustomerChat";
import CustomDialog from "./components/dialog/CustomDialog";
import Loading from "./components/loading/Loading";
import Protected from "./components/protected/Protected";
import PublicRoute from "./components/public/PublicRoute";
import CustomizedSnackbars from "./components/snackbar/CustomizedSnackbars";
import { auth } from "./firebase";
import Layout from "./layout/Layout";
import AdminPanelLayout from "./layout/adminOrgLayout/AdminPanelLayout";
import { Home } from "./pages/Home";
import Insights from "./pages/Insights";
import Report from "./pages/Report";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import Calendar from "./pages/calendar/Calendar";
import Dashboard from "./pages/dashboard/Dashboard";
import GroupListRoot from "./pages/groups/list/GroupList";
import GroupView from "./pages/groups/view/GroupView";
import LeadList from "./pages/leads/list/LeadList";
import LeadView from "./pages/leads/view/LeadView";
import ModulesList from "./pages/modules/ModulesList";
import NewOrganisation from "./pages/organisation/NewOrganisation";
import OrganisationList from "./pages/organisation/OrgaisationList";
import CreateGroup from "./pages/organisation/groups/CreateGroup";
import GroupList from "./pages/organisation/groups/GroupList";
import MaterialForm from "./pages/organisation/materials/MaterialForm";
import MaterialList from "./pages/organisation/materials/MaterialList";
import TransferOwnership from "./pages/organisation/ownership/TransferOwnership";
import StatusList from "./pages/organisation/status/StatusList";
import UserInvite from "./pages/organisation/users/UserInvite";
import AssignRoles from "./pages/organisation/view2/AssignRoles";
import AssignRoles2 from "./pages/organisation/view2/AssignRoles2";
import CheckListViewNew from "./pages/organisation/view2/CheckListViewNew";
import GroupDetails from "./pages/organisation/view2/GroupDetails";
import Groups from "./pages/organisation/view2/Groups";
import Invites from "./pages/organisation/view2/Invites";
import Leads from "./pages/organisation/view2/Leads";
import MaterailsManage from "./pages/organisation/view2/MaterailsManage";
import Moderators from "./pages/organisation/view2/Moderators";
import MultipleAssignee from "./pages/organisation/view2/MultipleAssignee";
import Permissions from "./pages/organisation/view2/Permissions";
import Projects from "./pages/organisation/view2/Projects";
import RolesList from "./pages/organisation/view2/RolesList";
import Settings from "./pages/organisation/view2/Settings";
import Users from "./pages/organisation/view2/Users";
import WorkFlowAdmin from "./pages/organisation/view2/WorkFlowAdmin";
import WorkFlowChecklist from "./pages/organisation/view2/WorkFlowChecklist";
import WorkFlowStages from "./pages/organisation/view2/WorkFlowStages";
import WorkFlow from "./pages/organisation/workflow/Workflow";
import ProjectList from "./pages/projects/list/ProjectList";
import ProjectView from "./pages/projects/view/ProjectView";
import UserNewProfile from "./pages/user/UserNewProfile";
import UserList from "./pages/user/list/UserList";
import { getUserLogout, setUserDetails } from "./redux/slices/authSlice";
import { setDialog } from "./redux/slices/dialogSlice";
import useWindowSize from "./utils/useWindowSize";
import MaterailsList from "./pages/organisation/view2/MaterailsList";
import MaterialModules from "./pages/organisation/view2/MaterialModules";
import MaterialInvertor from "./pages/organisation/view2/MaterialInvertor";
import AssignedRolesUserList from "./pages/organisation/view2/AssignedRolesUserList";
import NoPermission from "./pages/NoPermission";

function App() {
  const [loading, setLoading] = useState(true);
  const siteId = 3586717;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  const [dialogOpen, setDialogOpen] = useState(false);

  const userDetails = useSelector((state) => state.auth.userDetails);
  const openDialog = useSelector((state) => state.dialog.openDialog);
  const expirationTime = JSON.parse(
    sessionStorage.getItem(
      `firebase:authUser:${process.env.REACT_APP_FIREBASE_AUTH_API_KEY}:[DEFAULT]`
    )
  )?.stsTokenManager?.expirationTime;
  const location = window.location;
  const dispatch = useDispatch();

  const { width } = useWindowSize();

  const staySignIn = () => {
    setLoading(true);
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        authUser.getIdToken().then((token) => {
          if (token) {
            sessionStorage.setItem("token", JSON.stringify(token));
            dispatch(setDialog(false));
            setLoading(false);
          }
        });
      }
    });
  };

  const logout = () => {
    dispatch(getUserLogout());
    dispatch(setDialog(false));
  };

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(setUserDetails(authUser));

        // Set timeout to show dialog 1 minute before the session expires

        authUser.getIdToken().then((token) => {
          if (token) {
            sessionStorage.setItem("token", JSON.stringify(token));
          }
        });

        setLoading(false);
      } else {
        dispatch(setUserDetails(null));
        setLoading(false);
      }
    });
  }, [userDetails]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {/* {width < 600 && (
        <BasicModal isOpen={true} sx={modalStyle}>
          <div className="modal_header">
            <p className="modal_title">Alert</p>
          </div>
          <div className="modal_body">
            <div className="d_flex flex_column align_items_center justify_content_center">
              <ReportGmailerrorredIcon sx={{ fontSize: "50px" }} />
              <p className="text_center">
                To use the CRM, view the app on the desktop
              </p>
            </div>
          </div>
        </BasicModal>
      )} */}
      <CustomizedSnackbars />

      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/passwordreset" element={<ResetPassword />} />

            <Route path="/chat/customer" element={<CustomerChat />} />
          </Route>

          <Route element={<Protected />}>
            <Route path="/" element={<Home />} />
            <Route path="/nopermission" element={<NoPermission />} />

            <Route exact path="/org">
              {/* <Route index element={<OrganisationList />} /> */}
              {/* <Route path="create" element={<NewOrganisation />} /> */}
              <Route path="workflow" element={<WorkFlow />} />
              <Route path=":id/edit" element={<NewOrganisation />} />
              <Route path=":id/user/invite" element={<UserInvite />} />
              <Route path=":id/group">
                <Route index element={<GroupList />} />
                <Route path="create" element={<CreateGroup />} />
                {/* <Route path=":groupId/edit" element={<CreateGroup />} /> */}
              </Route>
              <Route path=":id/status/manage">
                <Route index element={<StatusList />} />
              </Route>
              <Route path=":id/materials/manage">
                <Route index element={<MaterialList />} />
                <Route path="create" element={<MaterialForm />} />
              </Route>
              <Route
                path=":id/transfer/ownership"
                element={<TransferOwnership />}
              />
            </Route>

            <Route element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="leads">
                <Route index element={<LeadList />} />
                <Route path=":id" element={<LeadView />} />
              </Route>
              <Route path="/projects">
                <Route index element={<ProjectList />} />
                <Route path=":id" element={<ProjectView />} />
              </Route>
              <Route path="/insights" element={<Insights />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/report" element={<Report />} />
              <Route path="/profile" element={<UserNewProfile />} />
              <Route path="/modules" element={<ModulesList />} />
              <Route path="/users">
                <Route index element={<UserList />} />
                {/* <Route path=":id" element={<UserNewProfile/>}/> */}
                {/* <Route path="test" element={<UserView/>}/> */}
              </Route>
              <Route path="/groups">
                <Route index element={<GroupListRoot />} />
                <Route path=":id" element={<GroupView />} />
              </Route>
              <Route path="/org">
                <Route index element={<OrganisationList />} />
                {/* <Route path=":id" element={<OrganisationView />} /> */}
                <Route path="create" element={<NewOrganisation />} />
              </Route>
              <Route element={<AdminPanelLayout />}>
                <Route path="org/:id/users" element={<Users />} />
                <Route path="org/:id/moderators" element={<Moderators />} />
                <Route path="org/:id/invites" element={<Invites />} />
                <Route path="org/:id/roles" element={<RolesList />} />
                <Route
                  path="org/:id/roles/:role_id/assign_roles"
                  element={<AssignRoles />}
                />
                <Route
                  path="org/:id/roles/:role_id/assign_roles2"
                  element={<AssignRoles2 />}
                />
                <Route
                  path="org/:id/roles/:role_id/assigned_users"
                  element={<AssignedRolesUserList />}
                />
                <Route path="/org/:id/groups" element={<Groups />} />
                <Route
                  path="/org/:id/groups/:group_id"
                  element={<GroupDetails />}
                />
                <Route path="/org/:id/projects" element={<Projects />} />
                <Route path="/org/:id/leads" element={<Leads />} />
                <Route path="/org/:id/workflow" element={<WorkFlowAdmin />} />
                <Route
                  path="/org/:id/workflow/:status_id/stages"
                  element={<WorkFlowStages />}
                />
                <Route
                  path="/org/:id/workflow/:status_id/checklist"
                  element={<WorkFlowChecklist />}
                />
                <Route
                  path="/org/:id/workflow/multiple_assigne"
                  element={<MultipleAssignee />}
                />
                <Route
                  path="/org/:id/workflow/checklist"
                  element={<CheckListViewNew />}
                />
                <Route path="/org/:id/settings" element={<Settings />} />
                <Route
                  path="/org/:id/settings/manage_materiails"
                  element={<MaterailsManage />}
                />
                <Route path="/org/:id/permissions" element={<Permissions />} />
                <Route path="/org/:id/materials" element={<MaterailsList />} />
                <Route
                  path="/org/:id/materials/modules"
                  element={<MaterialModules />}
                />
                <Route
                  path="/org/:id/materials/inverter"
                  element={<MaterialInvertor />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <CustomDialog
        open={openDialog}
        title="Session Expired"
        message="Your session got expired"
        okText="Stay Signed In"
        cancelText="Logout"
        onOk={staySignIn}
        onCancel={logout}
      />
    </>
  );
}

export default App;
