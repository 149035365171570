/* eslint-disable */
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  timeout: 20000,
  // withCredentials: true,

  // headers: {
  //   Authorization: "Bearer " + userToken,
  // },
});

instance.interceptors.request.use(async (request) => {
  request.params = request.params || {};

  console.log("request.params=---------->", request.params);

  // if (process.env.NODE_ENV !== "production") {
  //   // eslint-disable-next-line no-console
  // }
  const tokenString = sessionStorage.getItem("token");

  let userToken;
  if (tokenString) {
    userToken = JSON.parse(tokenString);
  }

  request.headers.Authorization = "Bearer " + userToken;

  return request;
});

instance.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    // NotificationManager.error(error.response.data.data.message);

    if (error.response.status == 404) {
    }

    return Promise.reject(error.response);
  }
);

export default instance;

export const createError = (error) => {
  const message =
    error?.response?.data?.data?.message ||
    error?.data?.data?.message ||
    "Unknown error";
  const errors = error?.response?.data || error?.data || {};

  return { errors, message };
};

const cancelToken = axios.CancelToken.source().token;

const getData = async ({ url, params, id, headers }) => {
  try {
    // const headers = {
    //   // Accept: 'application/json',
    // };
    if (id) {
      url = `${url}/${id}`;
    }

    const response = await instance.get(url, {
      params,
      headers,
      cancelToken,
    });
    return response.data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw createError(error);
    }
  }
};

const postData = async ({
  url,
  timeout,
  data,
  params,
  id,
  headers = {},
  onUploadProgress,
}) => {
  const cancelToken = axios.CancelToken.source().token;
  try {
    const config = {
      timeout,
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress,
    };
    if (id) {
      url = `${url}/${id}`;
    }

    config.headers = { ...config.headers, ...headers };
    const response = await instance.post(url, data, {
      ...config,
      params,
      cancelToken,
    });
    return response.data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw createError(error);
    }
  }
};

const putData = async ({ url, id, data, params }) => {
  const cancelToken = axios.CancelToken.source().token;
  try {
    if (id) {
      url = `${url}/${id}`;
    }

    const config = {};

    const response = await instance.put(url, data, {
      ...config,
      params,
      cancelToken,
    });
    return response.data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error;
    }
  }
};

const patchData = async ({ url, id, data, params }) => {
  const cancelToken = axios.CancelToken.source().token;
  try {
    if (id) {
      url = `${url}/${id}`;
    }

    const config = {};

    const response = await instance.patch(url, data, {
      ...config,
      params,
      cancelToken,
    });
    return response.data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error;
    }
  }
};

const deleteData = async ({ url, params, id, data }) => {
  const cancelToken = axios.CancelToken.source().token;

  try {
    const headers = {
      Accept: "application/json",
    };
    if (id) {
      url = `${url}/${id}`;
    }

    const response = await instance.delete(url, {
      params,
      data,
      headers,
      cancelToken,
    });
    return response.data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      // throw createError(error);
    }
  }
};

const AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 20000,
});

AxiosInstance.interceptors.request.use(async (request) => {
  request.params = request.params || {};
  // if (process.env.NODE_ENV !== "production") {
  //   // eslint-disable-next-line no-console
  // }
  const tokenString = sessionStorage.getItem("token");

  let userToken;
  if (tokenString) {
    userToken = JSON.parse(tokenString);
  }

  request.headers.Authorization = "Bearer " + userToken;

  return request;
});

export { postData, getData, putData, deleteData, patchData, AxiosInstance };
