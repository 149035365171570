import React, { useEffect, useState } from "react";
import AutoCompleteAsync from "./AutoCompleteAsync";
import { Box } from "@mui/material";
import Button from "../../components/buttons/Button";
import { useDispatch } from "react-redux";
import { createProjectStatusGroup } from "../../redux/slices/projectSlice";
import { useParams } from "react-router-dom";

const AutoCompleteDefaultAssigne = ({
  name,
  type,
  label,
  // register,
  onChange,
  onGroupChange,
  // onUserChange,
  variant,
  value,
  view,
  userLabel,
  groupWidth,
  userWidth,
  horizontal,
  users,
  groups,
  alreadyPresentGroups,
  alreadyPresentUsers,
  status,
  setStatusGroupsUserData,
  updateGroupsAndUsersofStatus,
  saveUsersAndGroupsData,
  saveData,
}) => {
  const [openOption, setOpenOption] = useState(false);
  const [optionSelected, setOptionSelected] = useState("Groups");
  const [userSelected, setSelectedUser] = useState([...alreadyPresentUsers]);
  const [groupSelected, setGroupSelected] = useState([...alreadyPresentGroups]);
  const [openUser, setOpenUser] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  // const [saveData, setSaveData] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const saveMultipleAssigne = () => {
    //  updateGroupsAndUsersofStatus({groupSelected,userSelected})
    // dispatch(createProjectStatusGroup(
    //   {
    //     url:`/crm/org/${id}/status/map`,
    //     params:{orgId:id},
    //     data:{
    //     }
    //   }
    // ))
  };
  useEffect(() => {}, []);
  return (
    <>
      <label className="text_medium">{label}</label>
      <>
        <AutoCompleteAsync
          multiple={true}
          sx={{
            width: groupWidth ?? "100%",
            marginBottom: "10px",
            marginTop: "10px",
            marginRight: "20px",
          }}
          name={name}
          label="Select Groups"
          open={openGroup}
          onOpen={() => {
            setOpenGroup(true);
          }}
          onClose={() => {
            setOpenGroup(false);
          }}
          isOptionEqualToValue={(option, value) => option?.id === value.id}
          options={groups.map((item) => {
            return {
              ...item,
              id: item.id,
              value: item.id,
              label: item.name,
              name: item.name,
            };
          })}
          onChange={(event, newValue, reason) => {
            setGroupSelected([...newValue]);
            updateGroupsAndUsersofStatus(
              [...newValue],
              "groups",
              status.id,
              reason
            );
            saveData();
          }}
          renderOption={(props, option) => (
            <Box style={{ fontSize: 13 }} {...props}>
              {option.name}
            </Box>
          )}
          value={groupSelected}
          getOptionLabel={(option) => {
            if (option.name) {
              return option?.name;
            } else {
              return "Please Select";
            }
          }}
          variant={variant}
        />
      </>

      <>
        <AutoCompleteAsync
          multiple={true}
          sx={{
            width: groupWidth ?? "100%",
            marginBottom: "10px",
            marginTop: "10px",
            marginRight: "20px",
          }}
          name={name}
          label="Select Users"
          open={openUser}
          onOpen={() => {
            setOpenUser(true);
          }}
          onClose={() => {
            setOpenUser(false);
          }}
          isOptionEqualToValue={(option, value) => option?.id === value.id}
          options={users.map((item) => {
            return {
              ...item,
              id: item.userId,
              value: item.id,
              label: item.name,
              name: item.name,
            };
          })}
          onChange={(event, newValue, reason) => {
            setSelectedUser([...newValue]);
            updateGroupsAndUsersofStatus(
              [...newValue],
              "users",
              status.id,
              reason
            );
            saveData();
          }}
          renderOption={(props, option) => (
            <Box style={{ fontSize: 13 }} {...props}>
              {option.name}
            </Box>
          )}
          value={userSelected}
          getOptionLabel={(option) => {
            if (option.name) {
              return option?.name;
            } else {
              return "Please Select";
            }
          }}
          variant={variant}
        />
      </>
    </>
  );
};

export default AutoCompleteDefaultAssigne;
