import { createSlice } from "@reduxjs/toolkit";


const moduleSlice=createSlice({
    name:"module",
    initialState:{
        moduleList:[],
        modulesLoading:false
    },
    reducers:{
        getModuleList(state){
            state.modulesLoading=true;
            return state;
        },
        setModuleList(state,action){
            state.moduleList=action.payload;
            state.modulesLoading=false;
        },
        createModule(state){
            console.log('inside create module slice');
            return state;
        }
    }
});

export const  {
    getModuleList,
    setModuleList,
    createModule
}=moduleSlice.actions;

export default moduleSlice.reducer;