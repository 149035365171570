import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons/Button";
import CustomBreadcrumbOrg from "./CustomBreadCrumbOrg";
import { CardItem } from "../../../utils/CardItem";
import { Link, useParams } from "react-router-dom";
import { Box, Grid, IconButton, Tabs, Typography } from "@mui/material";
import PlusIcon from "../../../assets/images/plus";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
} from "../../../components/constants/Constants";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckListItemCard from "./CheckListItemCard";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import CloseIcon from "../../../assets/images/close";
import { useFieldArray, useForm } from "react-hook-form";
import "./ChekListCard.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import {
  createProjectStatuCheckListOrg,
  getPrjectstatusCheckListOrg,
} from "../../../redux/slices/orgSlice";
import { useSelector } from "react-redux";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import CustomDialog from "../../../components/dialog/CustomDialog";
const options = [
  { name: "Edit", value: "Edit" },
  { name: "Delete", value: "Delete" },
];
const CheckListCard = ({ goBack, projectStatus }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const [removedItems, setRemovedItems] = useState([]);
  const breadcrumbs = [
    <Link key="1" onClick={goBack}>
      <b>
        {projectStatus.displayName
          ? projectStatus.displayName
          : projectStatus.name}
      </b>
    </Link>,
    <Typography key="2" color="text.primary">
      Check List
    </Typography>,
  ];
  const checkLists = useSelector(
    (state) => state.org.projectStatusCheckListOrg
  );
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      desc: "checklist",
      items: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items", // Name of the field array
  });
  const {
    register: registerEditForm,
    control: controlEditForm,
    handleSubmit: handleSubmitEditForm,
    setValue: setEditFormValue,
    reset: resetEditForm,
  } = useForm({
    defaultValues: {
      name: "",
      desc: "",
      chklistid: "",
      statusid: "",
      isactive: "",
      items: [],
    },
  });
  const {
    fields: fieldsEditForm,
    append: appendEditForm,
    remove: removeEditForm,
  } = useFieldArray({
    control: controlEditForm,
    name: "items",
  });
  const [value, setValue] = useState(0);
  const [openAddCheckListModal, setOpenAddCheckListModal] = useState(false);
  const [openEditCheckListModal, setOpenEditCheckListModal] = useState(false);
  let dummyData = [0, 1, 2];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentValue(newValue);
  };
  // useEffect(()=>{

  // },[reload])
  const addCheckList = (data) => {
    let sendData = {
      chkdtldtl: [
        {
          name: data.name,
          desc: data.description,
          chklistid: 0,
          statusid: projectStatus.id,
          isactive: 1,
          items: data.items.map((item, index) => {
            return {
              id: 0,
              statusid: projectStatus.id,
              name: item.name,
              displayname: item.name,
              comments: item.description,
              order: index + 1,
              isdefault: 1,
              isactive: 1,
            };
          }),
        },
      ],
    };
    dispatch(
      createProjectStatuCheckListOrg({
        url: `/crm/org/${id}/checkList`,
        params: {
          orgId: id,
        },
        data: sendData,
      })
    );
  };
  const editCheckList = (data) => {
    let sendData = data;
    sendData.items = [...sendData.items, ...removedItems];
    sendData.items = sendData.items.map((item, index) => {
      return {
        ...item,
        order: index + 1,
      };
    });

    dispatch(
      createProjectStatuCheckListOrg({
        url: `/crm/org/${id}/checkList`,
        params: {
          orgId: id,
        },
        data: {
          chkdtldtl: [sendData],
        },
      })
    );
  };
  const loadCheckList = () => {
    dispatch(
      getPrjectstatusCheckListOrg({
        url: `/crm/org/${id}/checkList/0/status/${projectStatus.id}`,
        params: {
          orgId: id,
          id: 0,
          statusId: projectStatus.id,
        },
      })
    );
  };
  const handleSelect = (value) => {
    if (value === "Delete") {
      setOpenDeleteConfirmation(true);
    }
    if (value === "Edit") {
      setEditFormValue("name", checkLists[currentValue].mas_name);
      setEditFormValue("desc", checkLists[currentValue]?.mas_desc);
      setEditFormValue("chklistid", checkLists[currentValue]?.mas_id);
      setEditFormValue("statusid", checkLists[currentValue]?.mas_statusid);
      setEditFormValue("isactive", checkLists[currentValue]?.mas_isactive);

      // checkLists[currentValue].chklist_dtls.map((item,index)=>{
      //   setEditFormValue(`items[${index}].name`, item.mas_name);
      //   setEditFormValue(`items[${index}].description`, item.chk_comments);
      // })
      checkLists[currentValue].chklist_dtls.forEach((item) => {
        appendEditForm({
          name: item.chk_name,
          comments: item.chk_comments,
          displayname: item.chk_displayname,
          id: item.chk_id,
          statusid: item.chk_statusid,
          order: item.chk_order,
          isdefault: item.chk_default,
          isactive: item.chk_isactive,
        });
      });
      setOpenEditCheckListModal(true);
    }
  };
  const deleteCheckList = () => {
    let sendData = {
      chkdtldtl: [
        {
          name: checkLists[currentValue].mas_name,
          description: checkLists[currentValue].description,
          chklistid: checkLists[currentValue].mas_id,
          statusid: projectStatus.id,
          isactive: 0,
          items: checkLists[currentValue].chklist_dtls.map((item) => {
            return {
              id: item.chk_id,
              statusid: item.chk_statusid,
              name: item.chk_name,
              displayname: item.chk_displayname,
              comments: item.chk_comments,
              order: item.chk_order,
              isdefault: item.chk_default,
              isactive: item.chk_isactive,
            };
          }),
        },
      ],
    };
    dispatch(
      createProjectStatuCheckListOrg({
        url: `/crm/org/${id}/checkList`,
        params: {
          orgId: id,
        },
        data: sendData,
      })
    );
  };
  useEffect(() => {
    loadCheckList();
  }, []);
  return (
    <CardItem height="810px">
      <div style={{ padding: "20px" }}>
        <div className="d_flex justify_content_between">
          <div className="d_flex align_items_center">
            <CustomBreadcrumbOrg breadcrumbs={breadcrumbs} />
          </div>
          <Button
            type="submit"
            className="primary_button d_flex align_items_center"
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18px",
              padding: "5px 20px",
            }}
            leftIcon={<PlusIcon color="#fff" />}
            onClick={() => {
              setOpenAddCheckListModal(true);
            }}
          >
            Add CheckList
          </Button>
        </div>
        <div className="">
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="card_content"
          >
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#189d3d",
                },
              }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {checkLists.map((item, index) => {
                return (
                  <StyledTab
                    icon={<CheckBoxIcon />}
                    iconPosition="start"
                    label={item.mas_name}
                    sx={{
                      fontSize: "10px",
                    }}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </Box>
          <div
            className="card_content"
            style={{
              height: "575px",
              overflowY: "scroll",
              position: "relative",
            }}
          >
            {checkLists?.length > 0 && (
              <div style={{ position: "absolute", right: 10, top: 10 }}>
                <ThreeDotDropdown
                  options={options}
                  onSelect={(value) => handleSelect(value)}
                />
              </div>
            )}

            {checkLists.map((checklist, index) => {
              return (
                <CustomTabPanel value={value} index={index}>
                  <div style={{ padding: "20px" }}>
                    <Grid container>
                      {checklist.chklist_dtls.length > 0 ? (
                        checklist.chklist_dtls?.map((item) => (
                          <Grid item xs={7} sm={7} md={7} lg={7}>
                            <CardItem>
                              <CheckListItemCard
                                name={item.chk_name}
                                description={item.chk_comments}
                              />
                            </CardItem>
                          </Grid>
                        ))
                      ) : (
                        <p className="text_center">No Items</p>
                      )}
                    </Grid>
                  </div>
                </CustomTabPanel>
              );
            })}
          </div>
        </div>
      </div>
      <CustomDialog
        open={openDeleteConfirmation}
        title="Delete"
        message="Are you sure you want to delete this Check List?"
        okText="Delete"
        cancelText="Cancel"
        onOk={() => {
          deleteCheckList();
          // setOpenDeleteConfirmation(false);
          // handleDelete(stage);
        }}
        onCancel={() => setOpenDeleteConfirmation(false)}
      />
      <BasicModal
        isOpen={openAddCheckListModal}
        onClose={() => {
          setOpenAddCheckListModal(false);
          //setReload(!reload)
          reset();
        }}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Add Check List</p>
            <div className="modal_header_button_wrapper">
              <IconButton
                onClick={() => {
                  setOpenAddCheckListModal(false);
                  //setReload(!reload);
                  reset();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modalbodyCheckListWrapper">
            <div className="modal_body">
              <div
                className="organisation"
                style={{ display: "block", padding: "0px" }}
              >
                <div className="">
                  <form noValidate onSubmit={handleSubmit(addCheckList)}>
                    <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">Check List Name</label>
                        <input
                          type="text"
                          name="name"
                          {...register("name", {
                            required: true,
                          })}
                        />
                        <p className="error_message">{errors.name?.message}</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">
                          Check List Description
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          rows={4}
                          {...register("description", {})}
                        />
                        <p className="error_message">
                          {errors.description?.message}
                        </p>
                      </Grid>
                      {fields.map((item, index) => {
                        return (
                          <>
                            <div key={item.id} className="item_title">
                              <div style={{ display: "flex" }}>
                                <div
                                  className="item_title"
                                  style={{
                                    paddingLeft: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginTop: "15px",
                                  }}
                                >
                                  Item {index + 1}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  {" "}
                                  <IconButton
                                    sx={{
                                      backgroundColor: "transparent !important",
                                      //width:'20px',
                                      //height:'10px'
                                    }}
                                    // className={classes.customHoverFocus}

                                    aria-label="delete"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">Item Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  {...register(`items.${index}.name`, {
                                    required: true,
                                  })}
                                />
                                <p className="error_message">
                                  {errors.name?.message}
                                </p>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">
                                  Item Description
                                </label>
                                <textarea
                                  type="text"
                                  name="description"
                                  rows={4}
                                  {...register(
                                    `items.${index}.description`,
                                    {}
                                  )}
                                />
                                <p className="error_message">
                                  {errors.description?.message}
                                </p>
                              </Grid>
                            </div>
                          </>
                        );
                      })}
                      <div
                        className="addIcon"
                        onClick={(e) => {
                          append({
                            // id: 0,
                            // statusid: 117,
                            name: "",
                            displayname: "",
                            // comments: "",
                            // order: 1,
                            // isdefault: 1,
                            // isactive: 1,
                          });
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>
                          {" "}
                          <IconButton
                            aria-label="adding"
                            onClick={(e) => {
                              e.preventDefault();
                              // append({
                              //   name: "",
                              //   description: "",
                              // });
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </div>
                        <div
                          className="flexCenter"
                          style={{ cursor: "pointer" }}
                        >
                          Add item
                        </div>
                      </div>
                      {/* <div className="line" style={{marginTop:"10px"}}></div> */}
                    </Grid>
                    <Button type="submit" className="primary_button marginT10">
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* </form> */}
        </>
      </BasicModal>
      <BasicModal
        isOpen={openEditCheckListModal}
        onClose={() => {
          setOpenEditCheckListModal(false);
          setRemovedItems([]);
          resetEditForm();
        }}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Edit Check List</p>
            <div className="modal_header_button_wrapper">
              <IconButton
                onClick={() => {
                  setOpenEditCheckListModal(false);
                  setRemovedItems([]);
                  resetEditForm();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modalbodyCheckListWrapper">
            <div className="modal_body">
              <div
                className="organisation"
                style={{ display: "block", padding: "0px" }}
              >
                <div className="">
                  <form
                    noValidate
                    onSubmit={handleSubmitEditForm(editCheckList)}
                  >
                    <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">Check List Name</label>
                        <input
                          type="text"
                          name="name"
                          {...registerEditForm("name", {
                            required: true,
                          })}
                        />
                        <p className="error_message">{errors.name?.message}</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label className="text_medium">
                          Check List Description
                        </label>
                        <textarea
                          type="text"
                          name="desc"
                          rows={4}
                          {...registerEditForm("desc", {})}
                        />
                        <p className="error_message">
                          {errors.description?.message}
                        </p>
                        <input
                          type="text"
                          name="statusid"
                          style={{ display: "none" }}
                          {...registerEditForm("statusid")}
                        />
                        <input
                          type="text"
                          name="chklistid"
                          style={{ display: "none" }}
                          {...registerEditForm("chklistid")}
                        />
                        <input
                          type="text"
                          name="isactive"
                          style={{ display: "none" }}
                          {...registerEditForm("isactive")}
                        />
                      </Grid>
                      {fieldsEditForm.map((item, index) => {
                        return (
                          <>
                            <div key={item.id} className="item_title">
                              <div style={{ display: "flex" }}>
                                <div
                                  className="item_title"
                                  style={{
                                    paddingLeft: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginTop: "15px",
                                  }}
                                >
                                  Item {index + 1}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  {" "}
                                  <IconButton
                                    sx={{
                                      backgroundColor: "transparent !important",
                                      //width:'20px',
                                      //height:'10px'
                                    }}
                                    // className={classes.customHoverFocus}

                                    aria-label="delete"
                                    onClick={() => {
                                      let itemRemoved =
                                        checkLists[currentValue].chklist_dtls[
                                          index
                                        ];
                                      if (itemRemoved) {
                                        let temp = {
                                          id: itemRemoved.chk_id,
                                          comments: itemRemoved.chk_comments,
                                          displayname:
                                            itemRemoved.chk_displayname,
                                          isactive: 0,
                                          isdefault: itemRemoved.chk_default,
                                          order: itemRemoved.chk_order,
                                          statusid: itemRemoved.chk_statusid,
                                        };
                                        setRemovedItems([
                                          ...removedItems,
                                          temp,
                                        ]);
                                      }
                                      removeEditForm(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">Item Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  {...registerEditForm(`items.${index}.name`, {
                                    required: true,
                                  })}
                                />
                                <p className="error_message">
                                  {errors.name?.message}
                                </p>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <label className="text_medium">
                                  Item Description
                                </label>
                                <textarea
                                  type="text"
                                  name="comments"
                                  rows={4}
                                  {...registerEditForm(
                                    `items.${index}.comments`,
                                    {}
                                  )}
                                />
                                <input
                                  type="text"
                                  name="id"
                                  style={{ display: "none" }}
                                  {...registerEditForm(`items.${index}.id`)}
                                />
                                <input
                                  type="text"
                                  name="statusid"
                                  style={{ display: "none" }}
                                  {...registerEditForm(
                                    `items.${index}.statusid`
                                  )}
                                />
                                <input
                                  type="text"
                                  name="order"
                                  style={{ display: "none" }}
                                  {...registerEditForm(`items.${index}.order`)}
                                />
                                <input
                                  type="text"
                                  name="isdefault"
                                  style={{ display: "none" }}
                                  {...registerEditForm(
                                    `items.${index}.isdefault`
                                  )}
                                />
                                <input
                                  type="text"
                                  name="isactive"
                                  style={{ display: "none" }}
                                  {...registerEditForm(
                                    `items.${index}.isactive`
                                  )}
                                />
                                <p className="error_message">
                                  {errors.description?.message}
                                </p>
                              </Grid>
                            </div>
                          </>
                        );
                      })}
                      <div
                        className="addIcon"
                        onClick={(e) => {
                          appendEditForm({
                            id: 0,
                            statusid: projectStatus.id,
                            name: "",
                            displayname: "",
                            comments: "",
                            order: "",
                            isdefault: 1,
                            isactive: 1,
                          });
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>
                          {" "}
                          <IconButton
                            aria-label="adding"
                            onClick={(e) => {
                              e.preventDefault();
                              // append({
                              //   name: "",
                              //   description: "",
                              // });
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </div>
                        <div
                          className="flexCenter"
                          style={{ cursor: "pointer" }}
                        >
                          Add item
                        </div>
                      </div>
                      {/* <div className="line" style={{marginTop:"10px"}}></div> */}
                    </Grid>
                    <Button type="submit" className="primary_button marginT10">
                      Save
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* </form> */}
        </>
      </BasicModal>
    </CardItem>
  );
};

export default CheckListCard;
