import React, { useEffect, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import DataTable from "../../../components/table/DataTable";
import Button from "../../../components/buttons/Button";
import ToolBar from "../../../components/toolbar/ToolBar";
import { Tooltip, Typography } from "@mui/material";
import PlusIcon from "../../../assets/images/plus";
import AddRolesModal from "./AddRolesModal";
import { useNavigate, useParams } from "react-router-dom";
import AssignRolesModal from "./AssignRolesModal";
import { getGroupList, getRolesListOrg, getUserListOrg } from "../../../redux/slices/orgSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import { FaUserEdit } from "react-icons/fa";
const RolesList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openAddRolesModal, setOpenAddRolesModal] = useState(false);
  const [showAssignRolesModal, setShowAssignRolesModal] = useState(false);
  const userList = useSelector((state) => state.org.userListOrg);
  const group = useSelector((state) => state.org.group);
  const rolesListOrg=useSelector((state)=>state.org.rolesListOrg);
  console.log('roles',rolesListOrg);
  const [selectedRole, setSelectedRole] = useState(null);
  const createData = useSelector((state) => state.form.createData);
  const roleColumns = [
    {
      field: "rolId",
      headerName: "Role Id",
      width: 80,
    },
    {
      field: "name",
      headerName: "Role Name",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: 300,
      renderCell: (params) => <Typography>{params?.row?.description}</Typography>,
    },
    {
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            {/* <Button
              type="submit"
              className="primary_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "18px",
                padding: "5px 20px",
              }}
              // leftIcon={<PlusIcon color="#fff" />}
              onClick={() => {
                console.log("params", params);
                setSelectedRole(params.row);
                setShowAssignRolesModal(true);
              }}
            >
              Assign Role
            </Button> */}
            {/* <ThreeDotDropdown
              options={[{ name: "Assign Role", value: "Assign Role" }]}
              onSelect={(value) => {
                console.log("params", params);
                setSelectedRole(params.row);
                setShowAssignRolesModal(true);
              }}
            /> */}
            {/* <Tooltip title="Assign Role" placement="top-start">
              <div>
              <FaUserEdit style={{fontSize:'16px',color:'#75787d'}} onClick={()=>{
              console.log("params", params);
              setSelectedRole(params.row);
              setShowAssignRolesModal(true);
            }}/>
              </div>

            </Tooltip> */}
          </div>
        );
      },
    },
  ];


  const loadGroups = () => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${id}`,
        params: {},
      })
    );
  };
  const loadUsers = () => {
    dispatch(
      getUserListOrg({
        url: `/crm/user/organization/${id}/users`,
      })
    );
  };
  const loadRolesList=()=>{
    dispatch(
      getRolesListOrg({
        url:`/crm/org/${id}/roles`
      })
    )
  }
  useEffect(() => {
    loadGroups();
  }, []);
  useEffect(() => {
    loadUsers();
  }, []);
  useEffect(()=>{
    loadRolesList();

  },[createData])
  return (
    <>
      <CardItem height="810px">
        {
          <div className="card_container">
            <div style={{ padding: "20px" }}>
              <ToolBar title={"Roles"}>
                <div className="d_flex align_items_center justify_content_between">
                  <Button
                    type="submit"
                    className="primary_button d_flex align_items_center"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "18px",
                      padding: "5px 20px",
                    }}
                    leftIcon={<PlusIcon color="#fff" />}
                    onClick={() => setOpenAddRolesModal(true)}
                  >
                    Add Role
                  </Button>
                </div>
              </ToolBar>
            </div>

            <div style={{ padding: "20px" }}>
              <DataTable
                rows={[...rolesListOrg.map((item)=>{
                  console.log('item.',item.rolDesc)
                  return {
                    ...item,
                    id:item.rolId,
                    name:item.rolName,
                    description:item.rolDesc
                  }
                })].reverse()}
                columns={roleColumns}
                onRowClick={(row) => {
                  // navigate(`/org/${id}/roles/${row?.id}/assign_roles`,{
                  //   state:{
                  //     role:row.row
                  //   }
                  // })
                  navigate(`/org/${id}/roles/${row?.id}/assign_roles2`,{
                    state:{
                      role:row.row
                    }
                  })
                }}
              />
            </div>
          </div>
        }

        <AddRolesModal
          open={openAddRolesModal}
          close={() => setOpenAddRolesModal(false)}
          orgId={id}
        />
        <AssignRolesModal
          RoleName={selectedRole ? selectedRole.name : "Role"}
          open={showAssignRolesModal}
          close={() => setShowAssignRolesModal(false)}
          orgId={id}
          users={userList}
          groups={group}
        />
      </CardItem>
    </>
  );
};

export default RolesList;
