import { Grid, IconButton } from "@mui/material";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalBody from "../../../modal/ModalBody";
import { useForm } from "react-hook-form";
import TextInput from "../../../../fields/text/TextInput";
import { useEffect } from "react";
import ModalFooter from "../../../modal/ModalFooter";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createAdditionalInfo,
  createHoaInfo,
} from "../../../../redux/slices/additionalInfoSlice";
import { useTranslation } from "react-i18next";

const HoaDetailsForm = ({ onClose, hoaDetails }) => {
  const { t } = useTranslation();
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // dispatch(setHOADetails({ ...data }));

    if (hoaDetails?.id) {
      data.id = hoaDetails?.id;
    }
    const editedData = {
      hoadtl: [data],
    };
    dispatch(
      createHoaInfo({
        url: `/crm/org/${orgId}/proj/${id}/info`,
        data: editedData,
        params: { data: "hoa" },
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (hoaDetails?.contactName) {
      setValue("contactName", hoaDetails?.contactName);
    }
    if (hoaDetails?.phone) {
      setValue("phone", hoaDetails?.phone);
    }
    if (hoaDetails?.email) {
      setValue("email", hoaDetails?.email);
    }
    if (hoaDetails?.hoaName) {
      setValue("hoaName", hoaDetails?.hoaName);
    }
  }, [hoaDetails]);

  return (
    <>
      <ModalHead title={t("HOA Details")}>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label={t("HOA Name")}
                // placeholder="Home Type"
                register={register}
                name="hoaName"
              />
              <p className="error_message">{errors.hoaName?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label={t("Contact Name")}
                // placeholder="Property Type"
                register={register}
                name="contactName"
              />
              <p className="error_message">{errors.contactName?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label={t("Phone")}
                // placeholder="Year Built"
                register={register}
                name="phone"
              />
              <p className="error_message">{errors.phone?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label={t("Email")}
                // placeholder="Area"
                register={register}
                name="email"
              />
              <p className="error_message">{errors.email?.message}</p>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="HOA Name"
                // placeholder="Home Type"
                register={register}
                name="hoaName"
              />
              <p className="error_message">{errors.hoaName?.message}</p>
            </Grid> */}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            {t("Save")}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default HoaDetailsForm;
