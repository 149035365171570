import React, { useEffect, useRef, useState } from "react";
import "./CustomDropDown.scss";

const CustomDropDown = ({ options,onChange,defaultValue }) => {
  console.log('default value',defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue?{value:defaultValue,label:defaultValue}:{value:'Text',label:'Text'});
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    console.log('option',option);
    onChange(option)
    setSelected(option);
    setIsOpen(false);
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="selected" onClick={handleToggle}>
        {selected.label} {/* Default text if no selection */}
        <span className={`arrow ${isOpen ? "open" : ""}`} />
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option, index) => (
            <div
              key={index}
              className="option"
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
