import { Typography } from "@mui/material";
// import LocationIcon from "../../assets/images/location.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import IconText from "../common/IconText";
const ViewTitleBar = ({ firstName, lastName, email, mobile, address }) => {
  return (
    <div className="title_bar_customer_info">
      <div className="d_flex align_items_center">
        <Typography variant="h6" key="2" color="text.primary">
          {firstName + " " + lastName}
        </Typography>
      </div>
      <div className="d_flex align_items_center email_phone_wrapper">
        <IconText
          className="m_0 marginT0 marginR10"
          icon={
            <MailIcon
              style={{ color: "#75787d" }}
              fontSize="small"
              className="marginR10"
            />
          }
          title={email}
          fontSize="small"
          type="email"
        />
        <IconText
          className="m_0 marginT0"
          icon={
            <PhoneIcon
              style={{ color: "#75787d" }}
              fontSize="small"
              className="marginR10"
            />
          }
          title={mobile}
          fontSize="small"
          type="phone"
        />
      </div>
      <IconText
        className="m_0 marginT0 "
        icon={
          <LocationOnIcon
            style={{ color: "#75787d" }}
            fontSize="small"
            className="marginR10"
          />
        }
        title={address}
        fontSize="small"
      />
      {/* </div> */}
    </div>
  );
};

export default ViewTitleBar;
