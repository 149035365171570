import { createSlice } from "@reduxjs/toolkit";

const leadSlice = createSlice({
  name: "lead",
  initialState: {
    leadSource: [],
    leadOffice: [],
    leadList: [],
    leadData: {},
    iframeUrl: null,
    iframeLoading: false,
    loading: false,
    // propertyLoading: true,
  },
  reducers: {
    createNewLead(state) {
      state.loading = true;
      return state;
    },

    createIframeUrl(state) {
      state.iframeLoading = true;
      return state;
    },
    editLead(state) {
      state.loading = true;
      return state;
    },
    getLeadSource(state) {
      state.loading = true;
      return state;
    },
    getLeadOffice(state) {
      state.loading = true;
      return state;
    },
    getLeadList(state) {
      state.loading = true;
      return state;
    },
    getLead(state) {
      state.loading = true;
      return state;
    },
    setLeadSource: (state, action) => {
      state.leadSource = action.payload;
      state.loading = false;
    },
    setIframeUrl: (state, action) => {
      state.iframeUrl = action.payload;
      state.iframeLoading = false;
    },
    setLeadOffice: (state, action) => {
      state.leadOffice = action.payload;
      state.loading = false;
    },

    setLeadList: (state, action) => {
      state.leadList = action.payload;
      state.loading = false;
    },
    setLead: (state, action) => {
      state.leadData = action.payload;
      state.loading = false;
    },
    // setLeadProperty: (state, action) => {
    //   state.propertyItems = action.payload;
    //   state.propertyLoading = false;
    // },
    setleadLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  getLeadSource,
  setLeadSource,
  getLeadOffice,
  setLeadOffice,
  createNewLead,
  createIframeUrl,
  editLead,
  getLeadList,
  setLeadList,
  setIframeUrl,
  getLead,
  setLead,
  setLeadProperty,
  setleadLoadingFalse,
} = leadSlice.actions;

export default leadSlice.reducer;
