import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Typography,
} from "@mui/material";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
} from "../../constants/Constants";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import EscalatorIcon from "@mui/icons-material/Escalator";
import {
  getProjectActivity,
  getProjectProgress,
  getProjectStageList,
  getProjectStatusList,
  // updateProjectStatus,
} from "../../../redux/slices/projectSlice";
import Status from "../../status/Status";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../buttons/Button";
import { pink, red } from "@mui/material/colors";
import MultipleStatus from "./status/MultipleStatus";
import BasicModal from "../../modal/Modal";
import { modalStyle } from "../../constants/ConstData";
import ActivityTimeline from "../../activity/ActivityTimeline";
import CustomDialog from "../../dialog/CustomDialog";
import { useParams } from "react-router-dom";
import ActivityCard from "../../dashboard/ActivityCard";
import Storage from "../../../utils/Storage";
import { setCreateSuccess } from "../../../redux/slices/formSlice";
import ProjectProgress from "./ProjectProgress";
import ProjectMultipleStatus from "./status/ProjectMultipleStatus";
import { updateProjectStatus } from "../../../redux/slices/additionalInfoSlice";

const StatusTimeline = ({
  isViewOpen,
  handleMultiple,
  // value,
  // onChange,
  onClose,
}) => {
  const [statusSelected, setStatusSelected] = useState([]);
  const projectData = useSelector((state) => state.project.projectData);
  const projectStatus = useSelector(
    (state) => state.additionalInfo.projectStatus
  );
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();

  // const page = 1;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveMultipleStatus = () => {
    const statusData = statusSelected.map((item) => {
      return {
        customerInfo: projectData?.customerInfo,
        address: projectData?.address,
        prevStatusId: 0,
        status: {
          id: item.id,
          name: item.name,
        },
        assignedUser:
          item?.users?.length > 0 ? item?.users?.map((user) => user?.id) : [0],
        groupId:
          item?.groups?.length > 0
            ? item?.groups?.map((group) => group?.id)
            : [0],
      };
    });

    dispatch(
      updateProjectStatus({
        url: `crm/org/${orgId}/proj/${id}/status`,
        data: statusData,
        params: { data: "status" },
      })
    );
  };

  const multiStatusChange = (event, item) => {
    if (event.target.checked) {
      setStatusSelected([...statusSelected, item]);
    } else {
      const filteredStatus = statusSelected.filter((fts) => fts.id !== item.id);
      setStatusSelected(filteredStatus);
    }
  };

  const loadProjectStatusList = () => {
    dispatch(
      getProjectStatusList({
        url: `crm/org/${orgId}/proj/status`,
        params: {},
      })
    );
  };

  return (
    <>
      <div className="card_container">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            // textColor="secondary"
            // indicatorColor="secondary"

            TabIndicatorProps={{
              style: {
                backgroundColor: "#189d3d",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <StyledTab
              icon={<EscalatorIcon />}
              iconPosition="start"
              label="Status"
              sx={{
                fontSize: "10px",
              }}
              {...a11yProps(0)}
            />
            <StyledTab
              icon={<LocalActivityIcon />}
              iconPosition="start"
              label="Activity"
              sx={{
                fontSize: "10px",
              }}
              {...a11yProps(1)}
            />
            <StyledTab
              icon={<DonutSmallIcon />}
              iconPosition="start"
              label="Project Progress"
              sx={{
                fontSize: "10px",
              }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <div className="card_content">
          <CustomTabPanel value={value} index={0}>
            <ProjectMultipleStatus
              handleMultiple={handleMultiple}
              loadProjectStatusList={loadProjectStatusList}
              onClose={onClose}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ActivityCard
              showTitle={false}
              // pageValue={page}
              // pageSize={pageSize}
              // loadProjectsActivity={loadProjectsActivity}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ProjectProgress loadProjectStatusList={loadProjectStatusList} />
            {/* {!progressLoading && (
              <ActivityTimeline
                timelineData={projectStatusList}
                progressData={progressData}
                type="project_progress"
              />
            )} */}
          </CustomTabPanel>
        </div>
      </div>
      <BasicModal
        isOpen={isViewOpen}
        onClose={onClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "50%", md: "40%", lg: "30%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "420px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <MultipleStatus
          selected={projectStatus}
          onClose={onClose}
          onChange={(event, item) => multiStatusChange(event, item)}
          onSave={saveMultipleStatus}
        />
      </BasicModal>
    </>
  );
};

export default StatusTimeline;
