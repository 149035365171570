import { Box, IconButton, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/buttons/Button'
import { CustomTabPanel, StyledTab, a11yProps } from '../../../components/constants/Constants'
import Loading from '../../../components/loading/Loading'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlusIcon from '../../../assets/images/plus'
import { getMaterialList } from '../../../redux/slices/materialSlice'
import { useDispatch } from 'react-redux'
import Storage from '../../../utils/Storage'
import { useSelector } from 'react-redux'

const MaterailsManage = () => {
    const navigate=useNavigate();
    const {id}=useParams();
    const [value, setValue] = useState(0);
    const dispatch=useDispatch();
    const loading = useSelector((state) => state.material.loading);
    const materials = useSelector((state) => state.material.materials);
    const userInfo = JSON.parse(sessionStorage.getItem("user"));
    const orgMatTypeList = Storage.read(`org${userInfo?.id}`)?.materialType;
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        dispatch(
          getMaterialList({
            url: `/crm/org/${id}/bom/store/type/${orgMatTypeList[value].matType}`,
            params: {},
          })
        );
      }, [value]);
  return (
   <>
      <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center justify_content_between marginB10">
          <div className="d_flex align_items_center">
            <IconButton onClick={() => navigate("/org")}>
              <ArrowBackIcon />
            </IconButton>
            <h3>Materials</h3>
          </div>
          <Link
            style={{ textDecoration: "none" }}
            to={`/org/${id}/materials/manage/create`}
          >
            <Button
              type="submit"
              className="outline_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                padding: "5px 20px",
              }}
              leftIcon={<PlusIcon color="#189d3d" />}
            >
              New Material
            </Button>
          </Link>
        </div>
        <div className="org_list_wrapper">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "10px",
            }}
          >
            <Tabs
              // textColor="secondary"
              // indicatorColor="secondary"

              TabIndicatorProps={{
                style: {
                  backgroundColor: "#189d3d",
                },
              }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {orgMatTypeList.map((item, index) => (
                <StyledTab
                  // icon={<ReceiptIcon />}
                  iconPosition="start"
                  label={item?.name}
                  sx={{
                    fontSize: "10px",
                  }}
                  {...a11yProps(index)}
                />
              ))}

              {/* <StyledTab
            icon={<PostAddIcon />}
            iconPosition="start"
            label="Adder"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(1)}
          /> */}
            </Tabs>
          </Box>
          {loading && <Loading />}
          {!loading &&
            orgMatTypeList.map((item, index) => (
              <CustomTabPanel value={value} index={index}>
                {materials?.length > 0 ? (
                  materials.map((item) => (
                    <div key={item.id} className="light_bg_border">
                      <div className="d_flex justify_content_between">
                        <div>
                          <div className="d_flex align_items_center">
                            {/* {index === 0 && (
                              <ConstructionIcon
                                style={{ color: "#189d3d" }}
                                className="org_icon"
                              />
                            )} */}
                            <div>
                              <h4 className="my_0">{item.name}</h4>
                            </div>
                          </div>
                          <p className="font_12_dark_gray marginT10">
                            {item.desc}
                          </p>
                          <div className="marginT10">
                            {item?.baseuom && (
                              <p className="font_12_dark_gray">
                                <b>Unit:</b> {item?.baseuom}
                              </p>
                            )}
                            {item?.baseprice && (
                              <p className="font_12_dark_gray">
                                <b>Base Price:</b> {item?.baseprice}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text_center">No Materials found</p>
                )}
              </CustomTabPanel>
            ))}
        </div>
      </div>
    </div>
   </>
  )
}

export default MaterailsManage