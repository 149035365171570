import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupList } from "../../../redux/slices/orgSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import Button from "../../../components/buttons/Button";
import PlusIcon from "../../../assets/images/plus";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "../../../components/loading/Loading";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import Storage from "../../../utils/Storage";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import CloseIcon from "../../../assets/images/close";
import ModalHead from "../../../components/modal/ModalHead";
import ModalBody from "../../../components/modal/ModalBody";
import CreateGroup from "./CreateGroup";
import GroupForm from "./GroupForm";

const options = [
  { name: "Edit Group", value: "edit" },
  // { name: "Manage Materials", value: "materials" },
];

const GroupList = () => {
  const group = useSelector((state) => state.org.group);
  const loading = useSelector((state) => state.org.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [groupValue, setGroupValue] = useState(null);
  let { id } = useParams();

  const handleSelect = (value, group) => {
    switch (value) {
      case "edit":
        // navigate(`/org/${id}/group/${group?.id}/${value}`);
        setGroupValue(group);
        setIsOpen(true);
        break;

      default:
        navigate("/dashboard");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(
      getGroupList({ url: `crm/user/groups/organization/${id}`, params: {} })
    );
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center justify_content_between marginB10">
          <div className="d_flex align_items_center">
            <IconButton onClick={() => navigate("/org")}>
              <ArrowBackIcon />
            </IconButton>
            <h3>Groups</h3>
          </div>
          <Link
            style={{ textDecoration: "none" }}
            to={`/org/${id}/group/create`}
          >
            <Button
              type="submit"
              className="outline_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                padding: "5px 20px",
              }}
              leftIcon={<PlusIcon color="#189d3d" />}
            >
              New Group
            </Button>
          </Link>
        </div>
        <div className="org_list_wrapper">
          {group.length > 0 ? (
            !loading &&
            group?.map((grp) => (
              <div key={grp.id} className="light_bg_border">
                <div className="d_flex justify_content_between">
                  <div>
                    <div className="d_flex align_items_center">
                      <Diversity3Icon
                        style={{ color: "#189d3d" }}
                        className="org_icon"
                      />
                      <div>
                        <h4 className="my_0">{grp.name}</h4>
                      </div>
                    </div>
                    <p>{grp.description}</p>
                  </div>
                  {/* {isOwner && (
                    <div>
                      <ThreeDotDropdown
                        options={options}
                        onSelect={(value) => handleSelect(value, grp)}
                      />
                    </div>
                  )} */}
                </div>
              </div>
            ))
          ) : (
            <p className="text_center">No groups found</p>
          )}
        </div>
      </div>
      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <ModalHead title="Edit Group">
          <div className="modal_header_button_wrapper">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </ModalHead>
        <ModalBody>
          <GroupForm type="modal" groupValue={groupValue} />
        </ModalBody>
      </BasicModal>
    </div>
  );
};

export default GroupList;
