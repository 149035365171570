import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import Checkbox from "../../../../fields/checkbox/Checkbox";
import EmailChip from "../../../../fields/emailChip/EmailChipInput";
import PlacesAutoComplete from "../../../maps/PlacesAutoComplete";
import TextInput from "../../../../fields/text/TextInput";
import TextEditor from "../../../../fields/texteditor/TextEditor";
import CloseIcon from "../../../../assets/images/close";
import CustomDate from "../../../../fields/dates/CustomDate";
import { Box, IconButton } from "@mui/material";
import Button from "../../../buttons/Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import CustomEmailChip from "../../../../fields/emailChip/CustomEmailChip";
import {
  createNewAppointment,
  editAppointment,
} from "../../../../redux/slices/appointmentSlice";
import { useParams } from "react-router-dom";
import { gapi } from "gapi-script";
import { useEffect } from "react";
import Storage from "../../../../utils/Storage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import CustomRadioButton from "../../../../fields/radio/CustomRadioButton";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { setCreateSuccess } from "../../../../redux/slices/formSlice";

const schema = yup.object().shape({
  endTime: yup
    .date()
    .test("is-greater", "end time should be greater", function (value) {
      const { startTime } = this.parent;
      return dayjs(value, "HH:mm").isSameOrAfter(dayjs(startTime, "HH:mm"));
    }),
  status: yup.object().required("Status is required"),
  subject: yup.string().required("Title is required"),
  invitees: yup.array().min(2, "Atleast 1 guest is required").required(),
});

const AppointmentForm = ({ type, onClose, value, view }) => {
  const [startDate, setStartDate] = React.useState(
    value?.startDate ?? new Date()
  );
  const [endDate, setEndDate] = React.useState(value?.endDate ?? new Date());
  const [startTime, setStartTime] = React.useState(
    value?.startTime ?? new Date()
  );
  const [endTime, setEndTime] = React.useState(value?.endTime ?? new Date());
  const [allDay, setAllDay] = React.useState(value?.allDay ?? false);
  const [isOnline, setIsOnline] = React.useState(value?.isOnline ?? false);

  const [googleSync, setGoogleSync] = React.useState(false);
  // const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const userDetails = Storage.read("user");

  const [desc, setDesc] = React.useState(value?.location ?? "");
  const [description, setDescription] = React.useState(
    value?.description ?? null
  );
  const [content, setContent] = React.useState(
    value?.description ? `<p>${value?.description}</p>` : null
  );

  const [inviteeList, setInviteeList] = React.useState([]);
  const [attendeeList, setAttendeeList] = React.useState(
    value?.inviteeList ?? []
  );

  const [openStatus, setOpenStatus] = useState(false);
  const [statusSelected, setStatusSelected] = useState(value?.status ?? null);
  const [appointmentType, setAppointmentType] = useState(
    value?.isTask === 1 ? "Task" : "Appointment"
  );
  const projectData = useSelector((state) => state.project.projectData);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;

  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const createData = useSelector((state) => state.form.createData);
  const [formData, setFormData] = useState();

  const accessToken = localStorage.getItem("access_token");

  var CLIENT_ID =
    "240602337-6vqi21gvcff244s5copse3tpueofpe7i.apps.googleusercontent.com";
  var API_KEY = process.env.REACT_APP_FIREBASE_AUTH_API_KEY;
  var DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  var SCOPES = "https://www.googleapis.com/auth/calendar.events";

  const handleInvitee = (invitee) => {
    setInviteeList(invitee);
    setValue("invitees", invitee);
  };

  const onSubmit = (data) => {
    setFormData(data);

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const newData = {};
    newData.subject = data.subject;
    newData.organizerName = userDetails?.name;
    newData.organizerEmail = userDetails?.email;
    newData.description = description;
    newData.startDate = allDay ? data.startDate : data.startTime;
    newData.endDate = allDay ? data.endDate : data.endTime;
    newData.fullDay = allDay;
    newData.invitees = inviteeList.length > 1 ? inviteeList : [];
    newData.statusId = 9;
    newData.location = isOnline ? "" : desc;
    newData.timeZone = timeZone;
    newData.stStatusid = statusSelected?.statusId;
    newData.isOnline = isOnline;
    newData.isTask = appointmentType === "Task" ? 1 : 0;
    newData.customerName =
      projectData?.customerInfo?.pContact?.firstName +
      " " +
      projectData?.customerInfo?.pContact?.lastName;
    newData.address = projectData?.address;
    newData.statusName = statusSelected?.statusName;

    const testData = { ...newData, ics: newData };

    if (view === "edit") {
      dispatch(
        editAppointment({
          url: `crm/org/${orgId}/${type}/${id}/appointment/${value?.appointmentId}`,
          data: testData,
          params: {},
        })
      );
    } else {
      dispatch(
        createNewAppointment({
          url: `crm/org/${orgId}/${type}/${id ?? 0}/appointment`,
          data: testData,
          params: {},
        })
      );
    }
  };

  const handleAllDay = (e) => {
    setAllDay(e.target.checked);
  };

  const handleOnline = (e) => {
    setIsOnline(e.target.checked);
  };

  const handleSync = (e) => {
    setGoogleSync(e.target.checked);

    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load("calendar", "v3", () => console.log("bam!"));
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then((res) => {
          if (res) {
            if (res.xc.access_token)
              localStorage.setItem("access_token", res.xc.access_token);
          }
        });
    });
  };

  const handleSelect = (desc) => {
    setDesc(desc);
  };

  const handleDescription = (content, delta, source, editor) => {
    setContent(content);
    const descValue = editor.getText(content).replace(/\n/g, " ");

    setDescription(descValue);
  };

  const handleType = (type) => {
    setAppointmentType(type);
  };

  React.useEffect(() => {
    if (!allDay) {
      const newStartDate = new Date(startDate);
      const newEndDate = new Date(newStartDate);

      // Keep the time component of the original end date
      newEndDate.setHours(endDate.getHours());
      newEndDate.setMinutes(endDate.getMinutes());
      newEndDate.setSeconds(endDate.getSeconds());
      newEndDate.setMilliseconds(endDate.getMilliseconds());
      setStartTime(newStartDate);

      setEndTime(
        value?.endTime ? newEndDate : new Date(startDate.getTime() + 15 * 60000)
      );

      // setEndTime(
      //   value?.endTime
      //     ? new Date(startDate.getTime(value?.endTime))
      //     : new Date(startDate.getTime() + 15 * 60000)
      // );
    }
    setValue("startDate", startDate);
  }, [startDate, allDay, value]);
  React.useEffect(() => {
    if (!allDay) {
      setValue("startTime", startTime);
    }
  }, [startTime, allDay]);

  React.useEffect(() => {
    if (allDay) {
      setValue("endDate", endDate);
    }
  }, [endDate, allDay]);

  React.useEffect(() => {
    if (!allDay) {
      setValue("endTime", endTime);
    }
  }, [endTime, allDay]);

  useEffect(() => {
    setAttendeeList(value?.inviteeList);
    setAllDay(value?.allDay);
    setIsOnline(value?.isOnline);

    setDesc(value?.location);
    setDescription(value?.description);

    setValue("subject", value?.subject);
    setValue("status", value?.status);
  }, [value]);

  useEffect(() => {
    if (createData?.createSuccess) {
      if (googleSync) {
        gapi.client.load("calendar", "v3", () => console.log("bamsss!"));
        // gapi.auth2
        //   .getAuthInstance()
        //   .signIn()
        //   .then(() => {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        const event = {
          summary: formData.subject,
          location: isOnline ? "" : formData.location,
          description: description,
          start: {
            dateTime: formData.startTime,
            timeZone: timeZone,
          },
          end: {
            dateTime: formData.endTime,
            timeZone: timeZone,
          },
          // recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
          attendees: inviteeList.length > 1 ? inviteeList : [],
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
        };

        var request = gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
        });

        request.execute((event) => {
          // window.open(event.htmlLink);
          // dispatch(
          //   createNewAppointment({
          //     url: `crm/org/${orgId}/${type}/${id ?? 0}/appointment`,
          //     data: testData,
          //     params: {},
          //   })
          // );
        });

        /*
              Uncomment the following block to get events
          */
        // get events
        // });
      }
      onClose();
    }
  }, [createData]);

  useEffect(() => {
    if (projectData?.status?.length === 1) {
      setStatusSelected(projectData?.status[0]);
      setValue("status", projectData?.status[0]);
    }
  }, [projectData?.status]);

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">New Appointment</p>
        {/* {projectData?.status?.length > 1 ? (
          <></>
        ) : (
          projectData?.status[0]?.statusName
        )} */}

        <div className="modal_header_button_wrapper">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal_body">
        <form
          className="appointment_form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="marginB10">
            <CustomRadioButton
              style={{ width: "100px" }}
              buttonList={[
                { label: "Appointment", icon: <EventAvailableIcon /> },
                { label: "Task", icon: <ChecklistIcon /> },
              ]}
              onChange={handleType}
              feedbackType={appointmentType}
            />
          </div>

          <div>
            <AutoCompleteAsync
              sx={{ width: "50%", marginBottom: "10px" }}
              fieldSize="small"
              name="status"
              label="Status"
              open={openStatus}
              onOpen={() => {
                setOpenStatus(true);
              }}
              onClose={() => {
                setOpenStatus(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option?.statusId === value.statusId
              }
              // loading={leadLoading}
              options={projectData?.status}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setStatusSelected(null);
                } else {
                  setStatusSelected(newValue);
                  setValue("status", newValue);
                }
              }}
              // register={register}
              renderOption={(props, option) => (
                <Box style={{ fontSize: 13 }} {...props}>
                  {option.statusName}
                </Box>
              )}
              value={statusSelected}
              getOptionLabel={(option) => {
                if (option.statusName) {
                  return option?.statusName;
                } else {
                  return "Please Select";
                }
              }}
            />
            <p className="error_message">{errors.status?.message}</p>
          </div>

          <div className="marginB10">
            <TextInput
              inputClass="input_field_style"
              placeholder="Add Title"
              register={register}
              name="subject"
            />
            <p className="error_message">{errors.subject?.message}</p>
          </div>
          <div>
            <CustomDate
              innerRef={register("startDate", { required: true })}
              className="custom_datepicker"
              startDate={startDate}
              // name="startDate"
              // open={openDate}
              // startOpen={openDate}
              // onChange={onDateChange}
              // shouldCloseOnSelect={true}

              dateFormat="EEEE,MMMM dd"
              onChange={(date, e) => {
                setStartDate(date);
                if (allDay) {
                  setEndDate(date);
                }
              }}
              // {...register("startDate")}
            />
            {allDay ? (
              <CustomDate
                className="custom_datepicker"
                startDate={endDate}
                // name="endDate"
                // open={openDate}
                // startOpen={openDate}
                // onChange={onDateChange}
                // shouldCloseOnSelect={true}

                dateFormat="EEEE,MMMM dd"
                onChange={(date, e) => {
                  setEndDate(date);
                }}
                // {...register("endDate")}
                innerRef={register("endDate", {})}
              />
            ) : (
              <>
                <CustomDate
                  className="custom_timepicker"
                  startDate={startTime}
                  // name="startTime"
                  // open={openDate}
                  // startOpen={openDate}
                  // onChange={onDateChange}
                  // shouldCloseOnSelect={true}
                  onChange={(date, e) => {
                    setStartTime(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  // {...register("startTime")}
                  innerRef={register("startTime", {})}
                />
                <CustomDate
                  className="custom_timepicker"
                  startDate={endTime}
                  // name="endTime"
                  // open={openDate}
                  // startOpen={openDate}
                  // onChange={onDateChange}
                  // shouldCloseOnSelect={true}
                  onChange={(date, e) => {
                    setEndTime(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={
                    startTime.getDate() ===
                    new Date(startTime.getTime()).getDate()
                      ? startTime
                      : startTime.setHours(0, 0, 0)
                  }
                  maxTime={new Date().setHours(23, 59, 59)}
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  // {...register("endTime")}
                  innerRef={register("endTime", {})}
                />
                <p className="error_message">{errors.endTime?.message}</p>
              </>
            )}
          </div>
          <div className="marginT10">
            <Checkbox
              type="checkbox"
              name="allDay"
              label="All day"
              onChange={handleAllDay}
              checked={allDay}
            />
          </div>
          <Controller
            name="invitees"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <CustomEmailChip
                {...field}
                name="invitees"
                value={attendeeList}
                onChange={handleInvitee}
              />
            )}
          />
          <p className="error_message">{errors.invitees?.message}</p>
          <div className="marginT10">
            <Checkbox
              type="checkbox"
              name="isOnline"
              label="Is this appointment online?"
              onChange={handleOnline}
              checked={isOnline}
            />
          </div>
          {!isOnline && (
            <div className="marginT10">
              <PlacesAutoComplete
                desc={desc}
                // innerRef={register("address", { required: true })}
                onSelect={handleSelect}
                hideMap={true}
                placeholder="Location"
              />
            </div>
          )}

          <div
            style={{
              marginTop: "32px",
              position: "relative",
              height: "250px",
              marginBottom: "20px",
            }}
          >
            <TextEditor
              name="description"
              label="Description"
              // register={register}
              value={content}
              onChange={handleDescription}
            />
          </div>
          <div className="marginT10">
            <Checkbox
              type="checkbox"
              name="googlesync"
              label="Sync to my google calendar"
              onChange={handleSync}
              checked={googleSync}
            />
          </div>
          <Button type="submit" className="primary_button marginT10">
            Save
          </Button>
        </form>
      </div>
    </>
  );
};

export default AppointmentForm;
