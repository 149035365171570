import { call, fork, put, takeEvery } from "redux-saga/effects";
import { createItem, getItem } from "../../services/service";
import { setDialog } from "../slices/dialogSlice";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import { createModule, getModuleList, setModuleList } from "../slices/moduleSlice";
import { createPermission, getPermissions, setPermissions } from "../slices/permissionSlice";

function* onLoadPermissoinsAsync(payload) {
  try {
    console.log('inside load permissions async');
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    console.log('load response',response);
    if (response.status === 200) {
      console.log("permissions get response",response);
      yield put(setPermissions([...response.data.permissions]));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}
function* onCreatePermissionAsync({ payload }) {
    try {
      const { url, data, params } = payload;
      const response = yield call(createItem, { url, data, params }); 
      console.log('response',response);
      yield put(
        setCreateSuccess({
          message:"Permissoin added",
          createSuccess: response?.status === 201 ? true : false,
          statusCode: response?.status,
        })
      );
    } catch (error) {
      console.log('error',error);
      if (error.message.includes("Firebase ID token has expired.")) {
        yield put(setDialog(true));
      } else {
        yield put(
          setCreateFailed({
            ...error?.errors?.data,
            createSuccess: false,
            statusCode: error.errors.status,
          })
        );
      }
    }
  }

function* getPermissionData() {
  yield takeEvery(getPermissions.type, onLoadPermissoinsAsync);
}

function* createPermissionData(){
    yield takeEvery(createPermission.type,onCreatePermissionAsync)
}


export const permissionSagas = [
    fork(getPermissionData),
    fork(createPermissionData)
];
