import CustomIconButton from "../../../components/buttons/CustomIconButton";
import ToolBar from "../../../components/toolbar/ToolBar";
import WindowIcon from "../../../assets/images/greenwindow.svg";
import MenuIcon from "../../../assets/images/menudark.svg";
import Button from "../../../components/buttons/Button";
import FilterIcon from "../../../assets/images/filter";
import SortIcon from "../../../assets/images/sort";
import PlusIcon from "../../../assets/images/plus";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../components/modal/Modal";
import DataTable from "../../../components/table/DataTable";
import LeadForm from "../create/LeadForm";
import { useNavigate } from "react-router-dom";
import { setTitle } from "../../../redux/slices/titleSlice";
import { useEffect, useState } from "react";
import { getLeadList } from "../../../redux/slices/leadSlice";
import { Typography } from "@mui/material";
import Loading from "../../../components/loading/Loading";
import { modalStyle } from "../../../components/constants/ConstData";
import Storage from "../../../utils/Storage";
import { clearCurrentFile } from "../../../redux/slices/fileUploadSlice";
import "./style.scss";

const columns = [
  {
    field: "id",
    headerName: "Lead ID",
    width: 80,
  },
  {
    field: "customerInfo",
    headerName: "Customer",
    width: 150,
    renderCell: (params) => (
      <Typography>
        {params?.row?.customerInfo?.pContact?.firstName}{" "}
        {params?.row?.customerInfo?.pContact?.lastName}
      </Typography>
    ),
  },
  {
    field: "office",
    headerName: "Office",
    width: 100,
    renderCell: (params) => (
      <Typography>{params?.row?.office?.name}</Typography>
    ),
  },
  {
    field: "source",
    headerName: "Lead Source",
    width: 150,
    renderCell: (params) => (
      <Typography>{params?.row?.source?.name}</Typography>
    ),
  },
  {
    field: "address",
    headerName: "Address",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
    renderCell: (params) => (
      <Typography>{params?.row?.customerInfo?.pContact?.email}</Typography>
    ),
  },
  {
    field: "mobile",
    headerName: "Phone",
    width: 100,
    renderCell: (params) => (
      <Typography>{params?.row?.customerInfo?.pContact?.mobile}</Typography>
    ),
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 100,
    renderCell: (params) => (
      <Typography>{params?.row?.createdBy?.name}</Typography>
    ),
  },
  {
    field: "setter",
    headerName: "Setter",
    width: 100,
    renderCell: (params) => (
      <Typography>{params?.row?.setter?.name}</Typography>
    ),
  },
];

const LeadList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;

  const leadList = useSelector((state) => state.lead.leadList);
  const loading = useSelector((state) => state.lead.loading);
  // const modalOpen = useSelector((state) => state.modal.open);
  const [isOpen, setIsOpen] = useState(false);
  const createData = useSelector((state) => state.form.createData);

  const iconSx = {
    backgroundColor: "rgba(55, 144, 107, 0.08)",
    borderRadius: "4px",
    marginRight: "10px",
  };

  const createNewLead = (e) => {
    e.preventDefault();

    setIsOpen(true);
  };

  const loadLeads = () => {
    dispatch(getLeadList({ url: `crm/org/${orgId}/leads`, params: {} }));
  };

  useEffect(() => {
    if (orgId) {
      dispatch(setTitle("Leads"));
      loadLeads();
    }
  }, [orgId]);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (createData?.createSuccess) {
      // handleClose();
      loadLeads();
      dispatch(clearCurrentFile());
    }
  }, [createData]);

  if (!isOpen && loading) {
    return <Loading />;
  }

  return (
    <div
      // style={{ padding: "25px", height: "100vh", overflowY: "scroll" }}
      className="leads"
    >
      <ToolBar title="Leads">
        <div className="d_flex align_items_center">
          {/* <CustomIconButton icon={WindowIcon} sx={iconSx} />
          <CustomIconButton icon={MenuIcon} sx={iconSx} />
          <Button
            type="submit"
            className="default_button d_flex align_items_center marginR10"
            leftIcon={<FilterIcon color="#75787D" />}
          >
            Filters
          </Button>
          <Button
            type="submit"
            className="default_button d_flex align_items_center marginR10"
            leftIcon={<SortIcon color="#75787D" />}
          >
            Sort by
          </Button> */}
          <Button
            type="submit"
            className="primary_button d_flex align_items_center"
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18px",
              padding: "5px 20px",
            }}
            leftIcon={<PlusIcon color="#fff" />}
            onClick={createNewLead}
          >
            Add Lead
          </Button>
        </div>
      </ToolBar>
      <div className="marginT10">
        <DataTable
          rows={[...leadList].reverse()}
          columns={columns}
          onRowClick={(row) => navigate(`/leads/${row.id}`)}
          loading={loading}
        />
      </div>

      <BasicModal isOpen={isOpen} onClose={handleClose} sx={modalStyle}>
        <LeadForm
          // loadLeads={loadLeads}
          onClose={handleClose}
        />
      </BasicModal>
    </div>
  );
};

export default LeadList;
