import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";
import CloseIcon from "../../../../assets/images/close";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectStatusList } from "../../../../redux/slices/projectSlice";
import Loading from "../../../loading/Loading";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import {
  getGroupList,
  getOrgUserList,
} from "../../../../redux/slices/orgSlice";
import SearchBar from "../../../searchbar/SearchBar";

const MultipleAssignee = ({ onClose, selected, onChange, onSave, type }) => {
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const dispatch = useDispatch();

  const orgUsers = useSelector((state) => state.org.orgUsers);
  const orgUserLoading = useSelector((state) => state.org.orgUserLoading);
  const group = useSelector((state) => state.org.group);
  const groupLoading = useSelector((state) => state.org.groupLoading);

  const searchValue = useSelector((state) => state.search.searchValue);
  const [searchList, setSearchList] = useState([]);

  const loadOrgUserList = () => {
    dispatch(
      getOrgUserList({
        url: `crm/user/organization/${orgId}/users`,
        params: {},
      })
    );
  };

  const loadOrgGroupList = () => {
    dispatch(
      getGroupList({ url: `crm/user/groups/organization/${orgId}`, params: {} })
    );
  };

  useEffect(() => {
    if (type === "user") {
      loadOrgUserList();
    } else {
      loadOrgGroupList();
    }
  }, [type]);

  useEffect(() => {
    if (type === "user") {
      setSearchList(orgUsers);
    } else {
      setSearchList(group);
    }
  }, [orgUsers, group]);

  useEffect(() => {
    if (searchValue !== "") {
      const newUserList = orgUsers?.filter(
        (entry) => entry.name.toLowerCase().includes(searchValue)

        // Object.values(entry).some(
        //   (val) =>
        //     typeof val === "string" &&
        //     val.toLowerCase().includes(searchValue.toLowerCase())
        // )
      );

      const newGroupList = group?.filter(
        (entry) => entry.name.toLowerCase().includes(searchValue)

        // Object.values(entry).some(
        //   (val) =>
        //     typeof val === "string" &&
        //     val.toLowerCase().includes(searchValue.toLowerCase())
        // )
      );
      if (type === "user") {
        setSearchList(newUserList);
      } else {
        setSearchList(newGroupList);
      }
    } else {
      if (type === "user") {
        setSearchList(orgUsers);
      } else {
        setSearchList(group);
      }
    }
  }, [searchValue]);

  if (orgUserLoading || groupLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">Add Assignee</p>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal_body">
        <div className="marginB10">
          <SearchBar />
        </div>
        {searchList?.length > 0 && (
          <FormGroup>
            {searchList?.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selected?.find((st) =>
                      type === "user"
                        ? st.userId === item?.userId
                        : st?.id === item?.id
                    )}
                    onChange={(event) => onChange(event, item)}
                    name="gilad"
                    // disabled={selected?.find((st) => st.id === item.id)}
                  />
                }
                label={item?.name}
              />
            ))}
            <Button
              onClick={onSave}
              type="button"
              className="primary_button marginT10"
            >
              Save
            </Button>
          </FormGroup>
        )}
      </div>
    </>
  );
};

export default MultipleAssignee;
