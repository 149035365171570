import {
  Autocomplete,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import CustomDialog from "../../../components/dialog/CustomDialog";
import DataTable from "../../../components/table/DataTable";
import {
  getAssignedUsersAndGroupsOfRole,
  removeRoleFromUsersAndGroups
} from "../../../redux/slices/orgSlice";
import { CardItem } from "../../../utils/CardItem";
import CustomBreadcrumbOrg from "../view/CustomBreadCrumbOrg";
const AssignedRolesUserList = () => {
  const assignedUsersAndGroupsOfRole = useSelector(
    (state) => state.org.assignedUsersAndGroupsOfRole
  );

  const [searchValue, setSearchValue] = useState(null);
  const [openRemoveUserConfirmation,setOpenRemoveUserConfirmation]=useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const createData = useSelector((state) => state.form.createData);
  const [userToRemove,setUserToRemove]=useState(null);
  const [selectedType, setSelectedType] = useState({
  
    id: 1,
    value: "Users",
    label: "Users",
  });
  const { roleName, roleId } = location.state;
  const breadcrumbs = [
    <Link key="1" to={`/org/${id}/roles`}>
      <b>{roleName}</b>
    </Link>,
    <Typography key="2" color="text.primary">
      Assigned List
    </Typography>,
  ];
  const roleColumns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 80,
    },

    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },
    // {
    //   width: 300,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Tooltip title="Remove user" placement="top-start">
    //           <div>
    //             <IconButton
    //               sx={{
    //                 backgroundColor: "transparent !important",
    //               }}
    //               aria-label="delete"
    //               onClick={() => {
    //                setUserToRemove(params.row);
    //                setOpenRemoveUserConfirmation(true);
    //               }}
    //             >
    //               <DeleteIcon style={{ fontSize: "18px", color: "#75787d" }} />
    //             </IconButton>
    //           </div>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
  ];
  const removeUserFromRole = () => {
    let sendData = [];
    if (selectedType.value === "Users") {
      sendData = [
        {
          ...assignedUsersAndGroupsOfRole[0],
          users: assignedUsersAndGroupsOfRole[0].users.map((item) => {
            return {
              ...item,
              isActive: item.id === userToRemove.id ? 0 : 1,
            };
          }),
        },
      ];
    } else {
      sendData = [
        {
          ...assignedUsersAndGroupsOfRole[0],
          groups: assignedUsersAndGroupsOfRole[0].groups.map((item) => {
            return {
              ...item,
              isActive: item.id === userToRemove.id ? 0 : 1,
            };
          }),
        },
      ];
    }

    dispatch(
      removeRoleFromUsersAndGroups({
        url: `/crm/org/${id}/roles/${roleId}/users`,
        data: sendData,
      })
    );
  };
  const loadAssignedList = () => {
    dispatch(
      getAssignedUsersAndGroupsOfRole({
        url: `/crm/org/${id}/roles/${roleId}/users`,
      })
    );
  };
  useEffect(() => {
    loadAssignedList();
    if (createData?.createSuccess) {
      setOpenRemoveUserConfirmation(false);
    }
  }, [createData]);
  return (
    <>
      <CardItem height="890px">
        <div className="card_container">
          <CustomDialog
            open={openRemoveUserConfirmation}
            title="Remove from Role"
            message={`Are you sure you want to remove this ${selectedType.value==="Users"?"User":"Group"} from this role?`}
            okText="Remove"
            cancelText="cancel"
            onOk={removeUserFromRole}
            onCancel={() => {
             // setUserToSwitch(null);
             // setSwitchUserToModeratorConfirmation(false);
             setUserToRemove(null);
             setOpenRemoveUserConfirmation(false);
            }}
          />
          <div style={{ padding: "20px" }}>
            {/* <ToolBar title={"Roles"}>
              <div className="d_flex align_items_center justify_content_between">

                <CustomSearch
                  placeholder={"Search"}
                  onChange={(e) => {
                    let value = e.target.value.trim();
                    if (value == "") {
                      setSearchValue(null);
                    } else {
                      setSearchValue(e.target.value);
                    }
                  }}
                />
              </div>
            </ToolBar> */}
            <div className="d_flex justify_content_between">
              <div className="d_flex align_items_center">
                <CustomBreadcrumbOrg breadcrumbs={breadcrumbs} />
              </div>
              <div
                className="d_flex justify_content_between"
                style={{ gap: "10px" }}
              >
                <div>
                  <Autocomplete
                    value={selectedType}
                    disablePortal
                    clearIcon={null}
                    id="combo-box-demo"
                    // clearOnEscape
                    options={[
                      {
                        id: 1,
                        value: "Users",
                        label: "Users",
                      },
                      {
                        id: 2,
                        value: "Groups",
                        label: "Groups",
                      },
                    ]}
                    onChange={(e, value) => {
                      console.log("value", value);
                      setSelectedType(value);
                    }}
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        padding: "6px",
                      },
                    }}
                    renderInput={(params) => {
                      return (
                        <>
                          <TextField {...params} label="Type" />
                        </>
                      );
                    }}
                  />
                </div>
                <div>
                  {/* <CustomSearch
                    placeholder={"Search"}
                    onChange={(e) => {
                      let value = e.target.value.trim();
                      if (value == "") {
                        setSearchValue(null);
                      } else {
                        setSearchValue(e.target.value);
                      }
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "20px" }}>
            <DataTable
              rows={
                selectedType?.value === "Users" &&
                assignedUsersAndGroupsOfRole.length === 1 &&
                Array.isArray(assignedUsersAndGroupsOfRole[0].users)
                  ? [
                      ...assignedUsersAndGroupsOfRole[0].users.map(
                        (item, index) => {
                          return {
                            ...item,
                            id: item.id,
                            name: item.username,
                            slno: index + 1,
                          };
                        }
                      ),
                    ]
                  : selectedType?.value === "Groups" &&
                    assignedUsersAndGroupsOfRole.length === 1 &&
                    Array.isArray(assignedUsersAndGroupsOfRole[0].groups)
                  ? [
                      ...assignedUsersAndGroupsOfRole[0]?.groups?.map(
                        (item, index) => {
                          return {
                            ...item,
                            id: item.id,
                            name: item.groupname,
                            slno: index + 1,
                          };
                        }
                      ),
                    ]
                  : []
              }
              columns={roleColumns}
            />
          </div>
        </div>
      </CardItem>
    </>
  );
};

export default AssignedRolesUserList;
