import React from "react";
import currencySymbolMap from "currency-symbol-map";
import Storage from "../../utils/Storage";
import { useSelector } from "react-redux";

const CurrencyView = ({ amount }) => {
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const currencyCode = currentOrg?.currencyCode ?? "USD";

  const currencySymbol = currencySymbolMap(currencyCode);

  return (
    <>
      {currencySymbol}
      {amount}
    </>
  );
};

export default CurrencyView;
