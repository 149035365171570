import { createSlice } from "@reduxjs/toolkit";

const additionalInfoSlice = createSlice({
  name: "additionalInfo",
  initialState: {
    propertyDetails: null,
    systemDetails: null,
    hoaDetails: null,
    financeDetails: null,
    ahjDetails: null,
    folderList: null,
    projectStatus: null,
    loading: false,
    hoaLoading: false,
    financialLoading: false,
    systemLoading: false,
    crmPropertyLoading: false,
    folderLoading: false,
    projectStatusLoading: false,
    ahjLoading: false,
  },
  reducers: {
    getAdditionalInfo(state) {
      state.loading = true;
      return state;
    },
    getHoaInfo(state) {
      state.hoaLoading = true;
      return state;
    },
    getFinancialInfo(state) {
      state.financialLoading = true;
      return state;
    },
    getSystemInfo(state) {
      state.systemLoading = true;
      return state;
    },
    getPropertyInfo(state) {
      state.crmPropertyLoading = true;
      return state;
    },
    getFolderList(state) {
      state.folderLoading = true;
      return state;
    },
    getProjectStatus(state) {
      state.projectStatusLoading = true;
      return state;
    },
    getAHJInfo(state) {
      state.ahjLoading = true;
      return state;
    },

    createAdditionalInfo(state) {
      state.loading = true;
      return state;
    },
    createHoaInfo(state) {
      state.hoaLoading = true;
      return state;
    },
    createFinancialInfo(state) {
      state.financialLoading = true;
      return state;
    },
    createSystemInfo(state) {
      state.systemLoading = true;
      return state;
    },
    createPropertyInfo(state) {
      state.crmPropertyLoading = true;
      return state;
    },
    createFolder(state) {
      state.folderLoading = true;
      return state;
    },

    updateProjectStatus(state) {
      state.projectStatusLoading = true;
      return state;
    },

    createAHJInfo(state) {
      state.ahjLoading = true;
      return state;
    },
    // createFinanceDetails(state) {
    //     state.loading = true;
    //     return state;
    //   },

    //   createHoaDetails(state) {
    //     state.loading = true;
    //     return state;
    //   },
    setAdditionalInfo: (state, action) => {
      state.propertyDetails = action.payload.propertyDetails;
      state.systemDetails = action.payload.systemdtl[0];
      state.hoaDetails = action.payload.hoa[0];
      state.financeDetails = action.payload.financialdtl[0];
      state.ahjDetails =
        action.payload.ahjdtl?.length > 0 ? action.payload.ahjdtl[0] : [];
      state.folderList = action?.payload?.folders;
      state.projectStatus = action?.payload?.status;
      state.loading = false;
    },
    setHoaInfo: (state, action) => {
      state.hoaDetails = action.payload.hoa[0];
      state.hoaLoading = false;
    },
    setFinancialInfo: (state, action) => {
      state.financeDetails = action.payload.financialdtl[0];
      state.financialLoading = false;
    },
    setSystemInfo: (state, action) => {
      state.systemDetails = action.payload.systemdtl[0];
      state.systemLoading = false;
    },
    setPropertyInfo: (state, action) => {
      state.propertyDetails = action.payload.propertyDetails;
      state.crmPropertyLoading = false;
    },
    setFolderList: (state, action) => {
      state.folderList = action.payload.folders;
      state.folderLoading = false;
    },

    setProjectStatus: (state, action) => {
      state.projectStatus = action.payload.status;
      state.projectStatusLoading = false;
    },
    setAHJInfo: (state, action) => {
      state.ahjDetails =
        action.payload.ahjdtl?.length > 0 ? action.payload.ahjdtl[0] : [];
      state.ahjLoading = false;
    },

    setAdditionalInfoLoadingFalse: (state, action) => {
      state.loading = false;
    },
    // setHoaLoadingFalse: (state, action) => {
    //   state.hoaLoading = false;
    // },
  },
});

export const {
  getAdditionalInfo,
  getHoaInfo,
  getFinancialInfo,
  getSystemInfo,
  getPropertyInfo,
  getFolderList,
  getProjectStatus,
  getAHJInfo,
  createAdditionalInfo,
  createHoaInfo,
  createFinancialInfo,
  createSystemInfo,
  createPropertyInfo,
  createFolder,
  updateProjectStatus,
  createAHJInfo,
  setAdditionalInfo,
  setHoaInfo,
  setFinancialInfo,
  setSystemInfo,
  setPropertyInfo,
  setFolderList,
  setProjectStatus,
  setAHJInfo,
  setAdditionalInfoLoadingFalse,
  // setHoaLoadingFalse,
} = additionalInfoSlice.actions;

export default additionalInfoSlice.reducer;
