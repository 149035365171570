import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomCheckBox from "../../../components/customCheckBox/CustomCheckBox";
import DataTable from "../../../components/table/DataTable";
import ToolBar from "../../../components/toolbar/ToolBar";

import { getRolesListOrg } from "../../../redux/slices/orgSlice";
import { CardItem } from "../../../utils/CardItem";
import { PERMISSION_CONSTANTS } from "../../../constants/permissionConstants";
import { getModuleList } from "../../../redux/slices/moduleSlice";
import Button from "../../../components/buttons/Button";
const Permissions = () => {
  const { id } = useParams();
  const rolesListOrg = useSelector((state) => state.org.rolesListOrg);
  const createData = useSelector((state) => state.form.createData);
  const moduleList = useSelector((state) => state.module.moduleList);
  const [modulePermissionData, setModulePermissionData] = useState({});
  let rolePermissoinData = [];
  const [selectedRole, setSelectedRole] = useState(
    rolesListOrg.length > 0 ? rolesListOrg[0].rolName : null
  );
  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 80,
    },
    {
      field: "module",
      headerName: "Module ",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.menuName}</Typography>;
      },
    },
  ];
  const columns2 = [
    ...columns,
    ...PERMISSION_CONSTANTS.map((item) => {
      return {
        field: item,
        headerName: item,
        width: 150,
        renderCell: (params) => {
          return (
            <>
              <div>
                <CustomCheckBox
                  onChange={(e) => {
                    console.log("role permissoins", rolePermissoinData);
                    console.log("current row", params.row.menuName);
                    console.log("current column", params.field);
                    let rowToEdit = null;
                    let indexToEdit = null;
                    rolePermissoinData?.map((item, index) => {
                      if (item.menuId == params.row.menuId) {
                        rowToEdit = item;
                        indexToEdit = index;
                      }
                    });
                    console.log("rowto edit", rowToEdit);
                    if (rowToEdit) {
                      let currentPermissions = rowToEdit.permissions;
                      let editedPermission = {
                        ...currentPermissions,
                        [params.field]: e.target.checked ? 1 : 0,
                      };
                      let updatedRow = {
                        ...rowToEdit,
                        permissions: editedPermission,
                      };
                      rolePermissoinData.splice(indexToEdit, 1, updatedRow);
                    } else {
                      let permissionObj = {
                        menuId: params.row.menuId,
                        permissions: {
                          [params.field]: e.target.checked ? 1 : 0,
                        },
                      };
                      rolePermissoinData.push(permissionObj);
                    }

                    console.log("role permission data", rolePermissoinData);
                  }}
                />
              </div>
            </>
          );
        },
      };
    }),
  ];

  const dispatch = useDispatch();
  const savePermission = () => {};
  const loadRolesList = () => {
    dispatch(
      getRolesListOrg({
        url: `/crm/org/${id}/roles`,
      })
    );
  };
  const loadModuleList = () => {
    dispatch(
      getModuleList({
        url: `/crm/common/module`,
      })
    );
  };

  useEffect(() => {
    loadRolesList();
    loadModuleList();
  }, [createData]);

  return (
    <>
      <CardItem height="810px">
        <div className="card_container">
          <div style={{ padding: "20px" }}>
            <ToolBar title={"Permissions"}>
              <div className="d_flex align_items_center justify_content_between">
                <Autocomplete
                  // disablePortal
                  // clearIcon={null}
                  // id="combo-box-demo"
                  defaultValue={
                    rolesListOrg.length > 0 ? rolesListOrg[0].rolName : ""
                  }
                  // clearOnEscape
                  options={[
                    ...rolesListOrg.map((item, index) => {
                      console.log("item", item);
                      return {
                        ...item,
                        label: item.rolName,
                        id: item.rolId,
                      };
                    }),
                  ]}
                  onChange={(e, value) => {
                    console.log(value);
                    setSelectedRole(value);
                  }}
                  sx={{ width: 250 }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField {...params} label="Role" />
                      </>
                    );
                  }}
                />
              </div>
            </ToolBar>
          </div>

          <div style={{ padding: "20px" }}>
            <DataTable
              rows={[
                ...moduleList.map((item, index) => {
                  return {
                    ...item,
                    slno: index + 1,
                    id: item.menuId,
                  };
                }),
              ]}
              columns={columns2}
              //   checkboxSelection={(params) => {
              //     console.log("params", params);
              //   }}
              //   onRowSelectionModelChange={(row) => {
              //   }}
              //onRowClick={(row) => navigate(`/leads/${row.id}`)}
              //loading={loading}
              //   disableSelectionOnClick={(params) => {
              //     return params.row.disableSelection || false;
              //   }}
              // isRowSelectable={true}
            />
          </div>
          <div style={{ padding: "20px" }}>
            <>
              <Button
                className="primary_button marginT10"
                onClick={savePermission}
              >
                Save Permission
              </Button>
            </>
          </div>
        </div>
      </CardItem>
    </>
  );
};

export default Permissions;
