import { Checkbox } from '@mui/material'
import React from 'react'

const CustomCheckBox = ({onChange,checked}) => {
  return (
    <>
    <Checkbox 
      value={checked}
        onChange={onChange}
        defaultChecked={checked?true:false}
        //defaultChecked={}
        //checked={checked?true:false}
        sx={{
           // color: pink[800],
            '&.Mui-checked': {
              color:'#189d3d',
            },
          }}
    />
    </>
  )
}

export default CustomCheckBox