import { Checkbox } from '@mui/material'
import React from 'react'

const CustomCheckBox = ({onChange}) => {
  return (
    <>
    <Checkbox 
        onChange={onChange}
        
        sx={{
           // color: pink[800],
            '&.Mui-checked': {
              color:'#189d3d',
            },
          }}
    />
    </>
  )
}

export default CustomCheckBox