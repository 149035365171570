import PhoneNumberView from "../PhoneNumber/PhoneNumberView";

const IconText = ({ icon, title, style, className, fontSize, type }) => {
  const Title = () => {
    if (type === "email") {
      return (
        <a href={`mailto:${title}`}>
          {fontSize === "small" ? <small>{title}</small> : <>{title}</>}
        </a>
      );
    } else if (type === "phone") {
      return (
        <a href={`tel:${title}`}>
          {fontSize === "small" ? (
            <small>
              <PhoneNumberView phoneNumber={title} />
            </small>
          ) : (
            <>
              <PhoneNumberView phoneNumber={title} />
            </>
          )}
        </a>
      );
    } else {
      return fontSize === "small" ? <small>{title}</small> : <>{title}</>;
    }
  };
  return (
    <p
      style={style ?? {}}
      className={`mb_0 marginT10 d_flex align_items_center ${className ?? ""}`}
    >
      {icon} {<Title />}
    </p>
  );
};

export default IconText;
