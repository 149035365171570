// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role_tablet_container {
  background-color: #ebebeb;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 180px;
  box-sizing: border-box;
  padding-left: 10px;
}

.role_tablet_heading {
  color: #75787d;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/organisation/view2/RoleTablet.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,kBAAA;AACJ;;AAGA;EACI,cAAA;EACA,gBAAA;AAAJ","sourcesContent":[".role_tablet_container{\n    background-color: #ebebeb;\n    border-radius: 20px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    min-width:180px;\n    box-sizing: border-box;\n    padding-left: 10px;\n    \n}\n\n.role_tablet_heading{\n    color:  #75787d;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
