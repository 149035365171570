// useDialog.js
import { useState, useCallback } from "react";

export function useDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [okLabel, setOkLabel] = useState("OK");
  const [cancelLabel, setCancelLabel] = useState("Cancel");
  const [onOk, setOnOk] = useState(null);
  const [onCancel, setOnCancel] = useState(null);

  const openDialog = useCallback(
    ({
      newTitle = "",
      newContent = "",
      okLabel = "OK",
      cancelLabel = "Cancel",
      onOk = null,
      onCancel = null,
    } = {}) => {
      setTitle(newTitle);
      setContent(newContent);
      setOkLabel(okLabel);
      setCancelLabel(cancelLabel);
      setOnOk(() => onOk);
      setOnCancel(() => onCancel);
      setIsOpen(true);
    },
    []
  );

  const closeDialog = useCallback(() => setIsOpen(false), []);

  const handleOk = useCallback(() => {
    if (onOk) onOk();
    closeDialog();
  }, [onOk, closeDialog]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
    closeDialog();
  }, [onCancel, closeDialog]);

  return {
    isOpen,
    openDialog,
    closeDialog,
    title,
    content,
    okLabel,
    cancelLabel,
    handleOk,
    handleCancel,
  };
}
