import { createSlice } from "@reduxjs/toolkit";

const projectViewSlice=createSlice({
    name:"projectListView",
    initialState:{
        tableView:false
    },
    reducers:{
        // getProjectSliceView(state){
        //     state.loading=true;
        //     return state;
        // },
        setProjectListSliceView(state,action){
            // state.userList=action.payload;
            // state.loading=false;
            console.log("action",action.payload);
            console.log('state====>',state);
            state.tableView=action.payload;
        }
    }
});

export const  {
    getProjectSliceView,
    setProjectListSliceView
}=projectViewSlice.actions;

export default projectViewSlice.reducer;