import CardTitle from "../../../dashboard/CardTitle";
import PdfIcon from "../../../../assets/images/pdf";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";
import DateView from "../../../date/DateView";
import Button from "../../../buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import { modalStyle } from "../../../constants/ConstData";
import { useEffect, useState } from "react";
import BasicModal from "../../../modal/Modal";
import FileUploadForm from "./FileUploadForm";
import { editProject } from "../../../../redux/slices/projectSlice";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";
import EmptyList from "../../../emptylist/EmptyList";
import "./style.scss";
import {
  deleteFiles,
  getFileList,
  setSuccess,
} from "../../../../redux/slices/fileUploadSlice";
import Loading from "../../../loading/Loading";
import CustomDialog from "../../../dialog/CustomDialog";
import FileViewer from "../../../fileviewer/FileViewer";
import ModalBody from "../../../modal/ModalBody";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import { setModal } from "../../../../redux/slices/modalSlice";
import { setCreateSnackbar } from "../../../../redux/slices/formSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

var colors = [
  ["#49b6f51a", "#49B6F5"],
  ["#845adf1a", "#189D3D"],
  ["#e6533c1a", "#E6533C"],
  ["#f5b8491a", "#F5B849"],
  ["#26bf941a", "#26BF94"],

  // etc…
];

function getRandomColorCombination(colors) {
  var random = parseInt(Math.random() * colors.length);
  return colors[random];
}

const FilesCard = ({ type, folderId, title, onMinimize }) => {
  const { id } = useParams();
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.upload.fileList);
  const loading = useSelector((state) => state.upload.fileLoading);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [removedList, setRemovedList] = useState(null);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const successData = useSelector((state) => state.upload.successData);

  const addMoreFiles = () => {
    dispatch(
      setModal({
        open: true,
        uploadDetails: {
          title: title,
          type: type,
          folderId: folderId,
          typeId: id,
          onMinimize: onMinimize,
        },
      })
    );
  };

  const deleteFile = (file) => {
    setDialogOpen(true);
    setRemovedList(file);
  };

  const deleteFileConfirm = () => {
    const data = {
      deleted: [
        {
          name: removedList?.name,
          url: removedList?.url,
          updated: true,
          folderid: folderId,
        },
      ],
    };
    dispatch(
      deleteFiles({
        url: `crm/org/${orgId}/${type}/${id}/files`,
        data: data,
        params: {},
      })
    );
    setDialogOpen(false);
  };

  const cancelDelete = () => {
    setDialogOpen(false);
  };

  const handleNext = () => {
    if (currentFileIndex < fileList.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
    }
  };

  const handleViewer = (name) => {
    const extension = name.split(".").pop().toLowerCase();

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "webp":
      case "heic":
      case "pdf":
        setIsFileOpen(true);
        const viewFile = fileList.filter((item) =>
          item.name?.match(/\.(jpg|jpeg|png|gif|webp|heic|pdf)$/i)
        );
        setFiles(viewFile);
        const index = viewFile.findIndex((obj) => obj.name === name);
        setCurrentFileIndex(index);
        break;

      default:
        setIsFileOpen(false);
    }
  };

  const handleCloseFile = () => {
    setIsFileOpen(false);
  };

  useEffect(() => {
    if (folderId) {
      dispatch(
        getFileList({
          url: `/crm/org/${orgId}/${type}/${id}/file/folder/${folderId}`,
          params: {},
        })
      );
    }
  }, [folderId]);

  useEffect(() => {
    if (folderId && snackbarData?.createSuccess) {
      dispatch(
        getFileList({
          url: `/crm/org/${orgId}/${type}/${id}/file/folder/${folderId}`,
          params: {},
        })
      );
      dispatch(setSuccess(null));
    }
  }, [snackbarData]);

  useEffect(() => {
    if (successData) {
      dispatch(
        setCreateSnackbar({
          ...successData?.data,
          createSuccess: true,
          statusCode: successData?.status,
        })
      );
    }
  }, [successData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ position: "relative" }} className={`card_container`}>
      {/* <CardTitle title={title} type="project_view"></CardTitle> */}

      <div className="card_content file_content_wrapper">
        {/* {fileList && fileList.length > 0 && (
         
        )} */}
        {fileList && fileList.length > 0 ? (
          fileList?.map((item, index) => {
            const randomColor = getRandomColorCombination(colors)[1];

            return (
              <div
                key={index}
                style={{
                  borderBottom: `1px  solid ${randomColor}`,
                  // borderTop: `1px  solid ${randomColor}`,
                }}
                className="file_wrapper"
              >
                <div className="d_flex align_items_center justify_content_between w_100">
                  <div
                    onClick={() => handleViewer(item.name)}
                    className={`d_flex align_items_center ${
                      item.name?.match(
                        /\.(jpg|jpeg|png|gif|webp|heic|pdf)$/i
                      ) && "pointer"
                    }`}
                  >
                    {item.name?.match(/\.(jpg|jpeg|png|gif|webp|heic)$/i) ? (
                      <img
                        width="80px"
                        height="80px"
                        src={
                          item.name?.match(/\.(heic)$/i)
                            ? item.url.replace(/\.heic$/i, ".png")
                            : item?.url
                        }
                        alt={item?.name}
                        loading="lazy"
                      />
                    ) : (
                      <PdfIcon className="pointer" color={randomColor} />
                    )}
                    <div className="marginL10">
                      <p className="font_8_black_dark_600 ">{item.name}</p>
                      <p className="font_8_black_dark_600">
                        <DateView
                          format="MM/DD/YYYY  hh : mm : ss a"
                          date={item.createdAt}
                        />
                      </p>
                      {item.createdBy && (
                        <p className="font_8_black_dark_600 ">
                          Uploaded By: {item.createdBy}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="d_flex alig_items_center marginR10">
                    <a
                      download={item.name}
                      href={item.url.replace(/%/g, "%25")}
                      target="_blank"
                    >
                      <IconButton>
                        <DownloadIcon
                          sx={{ color: randomColor, fontSize: "17px" }}
                        />
                      </IconButton>
                    </a>
                    <IconButton onClick={() => deleteFile(item)}>
                      <DeleteForeverIcon
                        sx={{ color: "red", fontSize: "17px" }}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyList
            icon={<DeveloperBoardOffIcon />}
            message="No files uploaded"
          />
        )}
      </div>
      <Button
        onClick={addMoreFiles}
        className="icon_button d_flex align_items_center primary_bg text_white marginB10 marginR10 "
        style={{ position: "absolute", bottom: "-30px", right: 0 }}
      >
        <AddIcon className="btn_icon" />
      </Button>
      {/* <BasicModal
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        isOpen={isOpen}
        onClose={handleClose}
        sx={modalStyle}
      >
        <FileUploadForm
          onClose={handleClose}
          title={title}
          type={type}
          folderId={folderId}
        />
      </BasicModal> */}
      <BasicModal
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        isOpen={isFileOpen}
        onClose={handleCloseFile}
        sx={{
          ".modal_header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 24px",
            borderBottom: "1px solid #ebebeb",
            backgroundColor: "#373737",
          },
          ".modal_body": {
            height: "100% !important",
          },
        }}
      >
        <ModalHead>
          <div
            // style={{ width: "50%" }}
            className="d_flex align_items_center justify_content_center"
          >
            <IconButton
              className="arrow_button"
              style={{ color: "#fff" }}
              onClick={handlePrev}
              disabled={currentFileIndex === 0}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <IconButton
              className="arrow_button"
              style={{ color: "#fff" }}
              onClick={handleNext}
              disabled={currentFileIndex === files.length - 1}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <IconButton style={{ color: "#fff" }} onClick={handleCloseFile}>
            <CloseIcon stroke="#fff" />
          </IconButton>
        </ModalHead>
        <ModalBody style={{ height: "100 !important" }}>
          <FileViewer
            files={files}
            currentFileIndex={currentFileIndex}
            onNext={handleNext}
            onPrev={handlePrev}
          />
        </ModalBody>
      </BasicModal>
      <CustomDialog
        open={dialogOpen}
        title="Delete"
        message="Are you sure you want to delete this file?"
        okText="Delete"
        cancelText="Cancel"
        onOk={deleteFileConfirm}
        onCancel={cancelDelete}
      />
    </div>
  );
};

export default FilesCard;
