import * as React from "react";
import CardTitle from "../../dashboard/CardTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CustomerContact from "../../customer/CustomerContact";
import Button from "../../buttons/Button";
import { Badge, IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getSunlightLogin } from "../../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getGeocode } from "use-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import { createIframeUrl } from "../../../redux/slices/leadSlice";
import BasicModal from "../../modal/Modal";
import { modalStyle } from "../../constants/ConstData";
import ModalBody from "../../modal/ModalBody";
import Loading from "../../loading/Loading";
import ModalHead from "../../modal/ModalHead";
import CloseIcon from "../../../assets/images/close";
import { useTranslation } from "react-i18next";

const libraries = ["places"];

export default function CustomerDetails({
  pContact,
  sContact,
  onChat,
  unreadCount,
  onSendPortal,
  type,
  data,
}) {
  const {t}=useTranslation();
  const [count, setCount] = React.useState(unreadCount ?? 0);
  const dispatch = useDispatch();
  const [addressDetails, setAddressDetails] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const iframeLoading = useSelector((state) => state.lead.iframeLoading);
  const iframeUrl = useSelector((state) => state.lead.iframeUrl);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  React.useEffect(() => {
    setCount(unreadCount);
  }, [unreadCount]);

  const LoadAddress = (description) => {
    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const addressComponents = results[0].address_components;
      let streetNumber = "";
      let area = "";
      let city = "";
      let county = "";
      let state = "";
      let country = "";
      let postalCode = "";

      addressComponents.forEach((component) => {
        if (component.types.includes("street_number")) {
          streetNumber = component.long_name;
        }
        if (component.types.includes("route")) {
          area = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_2")) {
          county = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        }
      });

      const addressValue = {
        description: description,
        streetNumber: streetNumber,
        area: area,
        city: city,
        county: county,
        state: state,
        country: country,
        postalCode: postalCode,
      };
      setAddressDetails(addressValue);
      // return addressValue;
    });
    // .catch((error) => {});
  };

  React.useEffect(() => {
    if (isLoaded && data?.address) {
      LoadAddress(data?.address);
    }
  }, [data, isLoaded]);

  const handleClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    console.log("iframeUrl=------------>", iframeUrl);
  }, [iframeUrl]);

  const onPreQualify = () => {
    console.log("addressDetails=----------->", addressDetails);

    setIsOpen(true);

    const newData = {
      projects: [
        {
          applicants: [
            {
              firstName: pContact?.firstName,
              lastName: pContact?.lastName,
              email: pContact?.email,
              isPrimary: true,
              phone: pContact?.mobile,
            },
          ],
          installCity: addressDetails?.city,
          installStateName: addressDetails?.state,
          installStreet:
            addressDetails?.streetNumber + " " + addressDetails?.area,
          installZipCode: addressDetails?.postalCode,
        },
      ],
    };
    console.log("newData=-------->", newData);
    dispatch(
      createIframeUrl({
        url: `/crm/connections/pre-qualification`,
        data: newData,
        params: {},
      })
    );
  };

  return (
    <div className="card_container">
      <CardTitle title={t("Customer Details")} type="project_view">
        {/* <Button className="icon_button marginL10 d_flex align_items_center primary_bg text_white">
          <EditIcon className="btn_icon" /> Edit
        </Button> */}
      </CardTitle>
      <div className="card_content">
        {pContact && (
          <CustomerContact
            title={t("Primary Contact")}
            name={pContact?.firstName + " " + pContact?.lastName}
            email={pContact?.email}
            phone={pContact?.mobile}
          />
        )}

        {sContact?.firstName && (
          <CustomerContact
            title={t("Secondary Contact")}
            name={sContact?.firstName + " " + sContact?.lastName}
            email={sContact?.email}
            phone={sContact?.mobile}
          />
        )}
        {type === "lead" && (
          <Button
            onClick={onPreQualify}
            className="outline_button_small p_5 w_100 d_flex align_items_center"
            leftIcon={<CheckCircleOutlineIcon />}
          >
            {t("Pre Qualification")}
          </Button>
        )}
        <div className="d_flex align_items_center">
          <Badge
            badgeContent={count}
            color="secondary"
            invisible={count > 0 ? false : true}
          >
            <Button
              onClick={onChat}
              className="icon_button marginL10 d_flex align_items_center bg_black text_white"
            >
              <ChatBubbleOutlineIcon className="btn_icon" />
              {t("Chat")}
              {/* {unreadCount > 0 && <small className="mb_0">{unreadCount}</small>} */}
            </Button>
          </Badge>

          <Button
            onClick={onSendPortal}
            className="icon_button marginL10 d_flex align_items_center primary_bg text_white"
          >
            <SendIcon className="btn_icon" /> {t("Send Portal")}
          </Button>
        </div>
      </div>
      <BasicModal isOpen={isOpen} onClose={handleClose} sx={modalStyle}>
        <ModalHead title={t("Pre Qualification")}>
          <div className="modal_header_button_wrapper">
            <IconButton
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </ModalHead>
        <ModalBody>
          {iframeLoading ? (
            <Loading />
          ) : (
            <iframe
              width="100%"
              height="100%"
              src={iframeUrl}
              title=""
            ></iframe>
          )}
        </ModalBody>
      </BasicModal>
    </div>
  );
}
