import React, { useEffect, useState } from 'react';
import PlusIcon from "../../../assets/images/plus";
import Button from '../../../components/buttons/Button';
import DataTable from '../../../components/table/DataTable';
import ToolBar from '../../../components/toolbar/ToolBar';
import { getMaterialList } from '../../../redux/slices/materialSlice';
import { CardItem } from '../../../utils/CardItem';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import AddMaterialsModal from './AddMaterialsModal';
const materialColumns = [
  {
    field: "slno",
    headerName: "Sl no",
    width: 80,
  },
  {
    field: "name",
    headerName: "Material Name",
    width: 250,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },

  {
    field: "description",
    headerName: "Description",
    width: 300,
    renderCell: (params) => <Typography>{params?.row?.desc}</Typography>,
  },
  {
    field: "unit",
    headerName: "Unit",
    width: 250,
    renderCell: (params) => {
      return <Typography>{params?.row?.baseuom}</Typography>;
    },
  },
  {
    field: "base price",
    headerName: "Base Price",
    width: 250,
    renderCell: (params) => {
      return <Typography>{params?.row?.baseprice}</Typography>;
    },
  },

];
const MaterailsList = () => {
  const {id}=useParams();
  const dispatch=useDispatch();
  const loading = useSelector((state) => state.material.loading);
  const materials = useSelector((state) => state.material.materials);
  const [showAdddModal,setShowAddModal]=useState(false);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  useEffect(() => {
      dispatch(
        getMaterialList({
          url: `/crm/org/${id}/bom/store/type/3`,//3 for materials type.3 is the id
          params: {},
        })
      );
  }, [snackbarData]);
  return (
    <>
          <CardItem height="890px">
        {
          <div className="card_container">
          <div style={{ padding: "20px" }}>
            <ToolBar title={"Materials"}>
              <div className="d_flex align_items_center justify_content_between">
                <Button
                  type="submit"
                  className="primary_button d_flex align_items_center"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    padding: "5px 20px",
                  }}
                  leftIcon={<PlusIcon color="#fff" />}
                  onClick={() => {
                    setShowAddModal(true);
                  }}
                >
                  Add Material
                </Button>
              </div>
            </ToolBar>
          </div>

          <div style={{ padding: "20px" }}>
            <DataTable
              rows={[...materials.map((item,index)=>{
                return {
                  ...item,
                  slno:index+1
                }
              })]}
              columns={materialColumns}
             
            />
          </div>
        </div>
        }

      <AddMaterialsModal
        open={showAdddModal}
        close={()=>{
          setShowAddModal(false);
        }}
        title={"Add Material"}
        materialId={3}
      />
      </CardItem>
    </>
  )
}

export default MaterailsList