import { useState } from "react";
import Button from "../../components/buttons/Button";

const CustomRadioButton = ({ buttonList, onChange, feedbackType, style }) => {
  const [value, setValue] = useState(feedbackType ?? "");
  const handleSelect = (label) => {
    setValue(label);
    onChange(label);
  };
  return buttonList.map((item, index) => (
    <Button
      key={index}
      type="button"
      style={style}
      className={`marginR10  ${
        value === item.label ? "primary_button" : "outline_button"
      }`}
      onClick={() => handleSelect(item.label)}
    >
      {item.label}
      <p>{item.icon}</p>
    </Button>
  ));
};

export default CustomRadioButton;
