import { Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "../../../../assets/images/close";
import { Controller, useForm, useFormState } from "react-hook-form";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewBom,
  editBom,
  getMaterialList,
} from "../../../../redux/slices/materialSlice";
import Storage from "../../../../utils/Storage";
import TextInput from "../../../../fields/text/TextInput";
import Button from "../../../buttons/Button";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BasicModal from "../../../modal/Modal";
import MaterialForm from "../../../../pages/organisation/materials/MaterialForm";
import { modalStyle } from "../../../constants/ConstData";
import { AddCircle } from "@mui/icons-material";

const schema = yup.object().shape({
  material: yup.object().shape({
    name: yup.string().required("Material is required"),
    id: yup.number().min(0, "Material is required"),
  }),
  itemuom: yup.string().required("Item UOM is required"),
  itemprice: yup.number().min(1, "Item price must be a positive number"),
  itemqty: yup.number().min(1, "Item quantity must be greater than 0"),
});

const BOMFormItem = ({ onClose, editItem, bomId, existingMaterials, type }) => {
  const [openMaterial, setOpenMaterial] = useState(false);
  const [materialSelected, setMaterialSelected] = useState({
    name: editItem?.name ?? editItem?.itemName,
    id: editItem?.materialid,
  });
  const materialLoading = useSelector((state) => state.material.loading);
  const materialList = useSelector((state) => state.material.materials);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const [isOpen, setIsOpen] = useState(false);

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const dispatch = useDispatch();
  const { id } = useParams();
  const orgMatTypeList = Storage.read(`org${userInfo?.id}`)?.materialType;

  const [inputValue, setInputValue] = useState("");
  const [newList, setNewList] = useState(materialList);
  const [oldData, setOldData] = useState(editItem);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   material: {
    //     name: editItem?.name,
    //     id: editItem?.materialid,
    //   },
    //   itemprice: editItem?.price,
    //   itemqty: editItem?.qty,
    //   itemuom: editItem?.uom,
    // },
  });
  const { dirtyFields } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    data.itemid = data.material.id;
    data.itemName = data.material.name;

    delete data.material;
    let newData;
    if (oldData?.id !== data.id) {
      data.id = 0;
      newData = {
        itemdetails: [oldData, data],
      };
    } else {
      newData = {
        itemdetails: [data],
      };
    }

    const baseUrl = `/crm/org/${orgId}/proj/${id}/bom`;
    const url =
      type === "adder" ? `${baseUrl}/adder/${bomId}` : `${baseUrl}/${bomId}`;

    dispatch(
      editBom({
        url: url,
        data: newData,
        params: {},
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseMaterialForm = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (openMaterial) {
      const materialItem = orgMatTypeList.filter(
        (item) => item.name === "Material"
      );

      const matId = materialItem[0]?.matType;
      dispatch(
        getMaterialList({
          url: `/crm/org/${orgId}/bom/store/type/${matId}`,
          params: {},
        })
      );
    }
  }, [openMaterial]);

  useEffect(() => {
    if (editItem) {
      setValue("itemprice", editItem?.price);
      setValue("itemuom", editItem?.uom);
      setValue("itemqty", editItem?.qty);
      setValue("material", {
        name: editItem?.name,
        id: editItem?.materialid,
      });
      setMaterialSelected({
        name: editItem?.name ?? editItem?.itemName,
        id: editItem?.materialid,
      });
    }
  }, [editItem]);

  useEffect(() => {
    setNewList(materialList);
  }, [materialList]);

  useEffect(() => {
    setOldData((prevState) => {
      return {
        ...prevState,

        isactive: 0, // or whatever value you need
      };
    });
  }, [editItem]);

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">
          {editItem?.id
            ? type === "adder"
              ? "Edit Adder"
              : "Edit BOM"
            : type === "adder"
            ? "New Adder"
            : "New BOM"}
        </p>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <form className="bom_form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="modal_body">
          <Grid sx={{ height: "100%" }} container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {type === "adder" ? (
                <Controller
                  name="material"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <AutoCompleteAsync
                      {...field}
                      className="input_field_style"
                      name="material"
                      label="Material"
                      open={openMaterial}
                      onOpen={() => {
                        setOpenMaterial(true);
                      }}
                      onClose={() => {
                        setOpenMaterial(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.name === value?.name
                      }
                      loading={materialLoading}
                      options={newList}
                      onChange={(event, newValue, reason) => {
                        if (reason === "clear") {
                          setMaterialSelected(null);
                          setValue("material", null);
                          clearErrors(`material`);
                        } else {
                          if (newValue.id !== editItem.materialid) {
                            const containsId = existingMaterials?.some(
                              (item) => item.materialid === newValue.id
                            );

                            if (containsId) {
                              setError(`material`, {
                                type: "manual",
                                message: "Material already exists",
                              });
                            } else {
                              clearErrors(`material`);
                              setMaterialSelected(newValue);
                              setValue("material", newValue);
                              setValue("itemprice", newValue?.baseprice);
                              setValue("itemuom", newValue?.baseuom);
                            }
                          } else {
                            setValue("material", {
                              name: editItem?.name ?? editItem?.itemName,
                              id: editItem?.materialid,
                            });
                            setMaterialSelected({
                              name: editItem?.name ?? editItem?.itemName,
                              id: editItem?.materialid,
                            });
                          }
                        }
                      }}
                      renderOption={(props, option, { index }) => {
                        const isLastOption = index === newList.length - 1;

                        return (
                          <>
                            {option?.name !== "" ? (
                              <Box style={{ fontSize: 13 }} {...props}>
                                {option.name}
                              </Box>
                            ) : (
                              <Box>{inputValue}</Box>
                            )}
                            {isLastOption && (
                              <Button
                                className="w_100 p_0  d_flex align_items_center outline_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsOpen(true);
                                }}
                                leftIcon={<AddCircle />}
                              >
                                Add New Material
                              </Button>
                            )}
                          </>
                        );
                      }}
                      value={materialSelected}
                      getOptionLabel={(option) => {
                        if (option?.name) {
                          return option?.name;
                        } else {
                          return "Please Select";
                        }
                      }}
                      fieldSize="small"
                      getOptionDisabled={(option) =>
                        materialSelected.includes(option.id)
                      }
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        if (newInputValue !== "") {
                          setNewList((prevArray) => {
                            if (prevArray.length === 0) {
                              // If the array is empty, just add the first object
                              return [{ id: 0, name: newInputValue }];
                            } else {
                              // Otherwise, replace the value of the last object
                              const updatedArray = [...prevArray];
                              updatedArray[updatedArray.length - 1] = {
                                ...updatedArray[updatedArray.length - 1],
                                id: 0,
                                name: newInputValue,
                                baseuom: "",
                              };
                              return updatedArray;
                            }
                          });
                        } else {
                          setNewList(materialList);
                        }
                      }}
                    />
                  )}
                />
              ) : (
                <Controller
                  name="material"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <AutoCompleteAsync
                      {...field}
                      // sx={{ width: "100%", margin: "8px 0" }}
                      className="input_field_style"
                      name="material"
                      label="Material"
                      open={openMaterial}
                      onOpen={() => {
                        setOpenMaterial(true);
                      }}
                      onClose={() => {
                        setOpenMaterial(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value.id
                      }
                      loading={materialLoading}
                      options={materialList}
                      onChange={(event, newValue, reason) => {
                        if (reason === "clear") {
                          setMaterialSelected(null);
                          setValue("material", null);
                          clearErrors(`material`);
                        } else {
                          if (newValue.id !== editItem.materialid) {
                            const containsId = existingMaterials?.some(
                              (item) => item.materialid === newValue.id
                            );

                            if (containsId) {
                              setError(`material`, {
                                type: "manual",
                                message: "Material already exists",
                              });
                            } else {
                              clearErrors(`material`);
                              setMaterialSelected(newValue);
                              setValue("material", newValue);
                              setValue("itemprice", newValue?.baseprice);
                              setValue("itemuom", newValue?.baseuom);
                            }
                          } else {
                            setValue("material", {
                              name: editItem?.name ?? editItem?.itemName,
                              id: editItem?.materialid,
                            });
                            setMaterialSelected({
                              name: editItem?.name ?? editItem?.itemName,
                              id: editItem?.materialid,
                            });
                          }
                        }
                      }}
                      // register={register}
                      renderOption={(props, option, { index }) => {
                        const isLastOption = index === materialList.length - 1;
                        return (
                          <>
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option.name}
                            </Box>
                            {isLastOption && (
                              <Button
                                className="w_100 p_0  d_flex align_items_center outline_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // navigate(`/org/${orgId}/group/create`);
                                  setIsOpen(true);
                                }}
                                leftIcon={<AddCircle />}
                              >
                                Add New Material
                              </Button>
                            )}
                          </>
                        );
                      }}
                      value={materialSelected}
                      getOptionLabel={(option) => {
                        if (option.name) {
                          return option?.name;
                        } else {
                          return "Please Select";
                        }
                      }}
                      fieldSize="small"
                    />
                  )}
                />
              )}

              <p className="error_message">{errors.material?.message}</p>
            </Grid>
            {materialSelected && (
              <>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Controller
                    name="itemprice"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        variant="mui"
                        name="itemprice"
                        label="Price"
                        inputClass="input_field_style"
                        fieldSize="small"
                      />
                    )}
                  />
                  <p className="error_message">{errors.itemprice?.message}</p>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Controller
                    name="itemuom"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        variant="mui"
                        name="itemuom"
                        label="Unit"
                        inputClass="input_field_style"
                        // placeholder="Unit"
                        // register={{
                        //   ...register(`itemdetails.${index}.itemuom`, {
                        //     required: true,
                        //   }),
                        // }}
                        fieldSize="small"
                      />
                    )}
                  />
                  <p className="error_message">{errors.itemuom?.message}</p>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Controller
                name="itemqty"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="itemqty"
                    label="Quantity"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.itemqty?.message}</p>
            </Grid>
          </Grid>
        </div>
        <div className="modal_footer">
          <Button type="submit" className="primary_button">
            Save
          </Button>
        </div>
      </form>
      <BasicModal
        isOpen={isOpen}
        onClose={handleCloseMaterialForm}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <MaterialForm
          modalView={true}
          onClose={handleCloseMaterialForm}
          orgId={orgId}
        />
      </BasicModal>
    </>
  );
};

export default BOMFormItem;
