// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.selected {
  padding: 10px;
  border: 1px solid #cdd2e1;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  color: black;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #cdd2e1;
  border-radius: 4px;
  background: white;
  z-index: 10;
  font-weight: 300;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
}

.option {
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.option:hover {
  background: #f1f1f1;
}

.arrow {
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #8c9097; /* Change color as needed */
  transition: transform 0.2s ease;
}

.arrow.open {
  transform: rotate(180deg); /* Rotate the arrow when the dropdown is open */
}`, "",{"version":3,"sources":["webpack://./src/components/customDropDown/CustomDropDown.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAGE;EACE,aAAA;EACA,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,mCAAA;EACA,YAAA;AAAJ;;AAKE;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,mCAAA;AAFJ;;AAKE;EACE,aAAA;EACA,gCAAA;AAFJ;;AAKE;EACE,mBAAA;AAFJ;;AAKE;EACE,iBAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,mCAAA;EACA,6BAAA,EAAA,2BAAA;EACA,+BAAA;AAFJ;;AAKE;EACE,yBAAA,EAAA,+CAAA;AAFJ","sourcesContent":[".custom-dropdown {\n    position: relative;\n    width: 100%;\n    cursor: pointer;\n    //outline-color: #189d3d;\n  }\n  \n  .selected {\n    padding: 10px;\n    border: 1px solid #cdd2e1;\n    background: white;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-radius: 4px;\n    font-weight: 400;\n    font-size: 14px;\n    font-family: Montserrat, sans-serif;\n    color: black;\n  }\n\n  \n  \n  .options {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    border: 1px solid #cdd2e1;\n    border-radius: 4px;\n    background: white;\n    z-index: 10;\n    font-weight: 300;\n    font-size: 14px;\n    font-family: Montserrat, sans-serif;\n  }\n  \n  .option {\n    padding: 10px;\n    border-bottom: 1px solid #f1f1f1;\n  }\n  \n  .option:hover {\n    background: #f1f1f1;\n  }\n  \n  .arrow {\n    margin-left: 10px;\n    width: 0;\n    height: 0;\n    border-left: 5px solid transparent;\n    border-right: 5px solid transparent;\n    border-top: 5px solid #8c9097; /* Change color as needed */\n    transition: transform 0.2s ease; \n  }\n\n  .arrow.open {\n    transform: rotate(180deg); /* Rotate the arrow when the dropdown is open */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
