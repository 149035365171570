import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Loading from "../loading/Loading";

function PublicRoute() {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const loading = useSelector((state) => state.auth.loading);
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const orgLoading = useSelector((state) => state.org.loading);

  let location = useLocation();
  let { from } = location?.state ?? {};

  if (loading) {
    return <Loading />;
  }

  if (userDetails) {
    return from && from?.pathname !== "" ? (
      <Navigate to={`${from?.pathname}${from?.search}`} />
    ) : (
      <Navigate to="/" />
    );
  }

  return <Outlet />;
}

export default PublicRoute;
