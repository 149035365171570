import React from 'react'
import './RoleTablet.scss'
import { IconButton } from '@mui/material'
import CloseIcon from "../../../assets/images/close";
const RoleTablet = ({title}) => {
  return (
    <div className='role_tablet_container'>
        <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
            <div style={{display:'flex',alignItems:'center'}} className='role_tablet_heading'>{title}</div>
            <div>
            <IconButton sx={{fontWeight:"700"}} onClick={()=>{}}>
                <CloseIcon />
              </IconButton>
            </div>
        </div>
    </div>
  )
}

export default RoleTablet