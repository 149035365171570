import parsePhoneNumber from "libphonenumber-js";
import { useEffect, useState } from "react";

const PhoneNumberView = ({ phoneNumber }) => {
  const [newNumber, setNewNumber] = useState(phoneNumber);
  const [newParsed, setNewParsed] = useState(null);

  useEffect(() => {
    if (phoneNumber && phoneNumber !== "" && phoneNumber?.includes("+")) {
      const parsedNumber = parsePhoneNumber(phoneNumber);
      setNewParsed(parsedNumber);
    } else {
      setNewNumber(phoneNumber);
    }
  }, [phoneNumber]);

  return newParsed ? (
    <>
      {newParsed?.countryCallingCode && (
        <sapn>+{newParsed?.countryCallingCode}-</sapn>
      )}
      {newParsed?.nationalNumber && <span>{newParsed.nationalNumber}</span>}
    </>
  ) : (
    <>{newNumber}</>
  );
};

export default PhoneNumberView;
