import React, { useEffect } from "react";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import { Grid, IconButton } from "@mui/material";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
import CloseIcon from "../../../assets/images/close";
import { useForm } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import { useDispatch } from "react-redux";
import { createProjectStatusOrg } from "../../../redux/slices/orgSlice";
import { useSelector } from "react-redux";
const AddOrganisationStatusModal = ({ open, close, orgId, totalStatuses }) => {
  console.log("totalStatuses", totalStatuses);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const createdData = useSelector((state) => state.form.createData);
  const addStatus = (data) => {
    console.log("data", data);
    let status = [
      {
        name: data.name,
        displayName: data.displayName,
        isactive: true,
        order: totalStatuses+1, //last entry,
        stages: [
          // {
          //   name: "stage 1",
          //   order: 0,
          //   isactive: true,
          // },
        ],
      },
    ];
    dispatch(createProjectStatusOrg({
      url:`/crm/user/organization/${orgId}/status`,
      params:{
        orgId
      },
      data:{
        status:status
      }
    }))
  };
  useEffect(() => {
    if (createdData?.createSuccess) {
      reset();
      close();
    }
  }, [createdData]);
  return (
    <>
      <BasicModal
        isOpen={open}
        onClose={close}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
          '@media (max-width: 768px)': { // Adjust the max-width as needed
            width: "80%", // Width for mobile devices
          },
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Add Status</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(addStatus)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        {...register("name", {
                          required: true,
                          //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <label>Display</label>
                      <input
                        type="text"
                        name="displayName"
                        placeholder="Display Name"
                        {...register("displayName", {
                          required: true,
                          //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    Add Status
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </>
  );
};

export default AddOrganisationStatusModal;
