import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Talk from "talkjs";
import CustomChat from "../../../components/chat/CustomChat";
import { modalStyle } from "../../../components/constants/ConstData";
import Loading from "../../../components/loading/Loading";
import BasicModal from "../../../components/modal/Modal";
import AhjInfo from "../../../components/project/view/AhjInfo";
import AssignedToCard from "../../../components/project/view/AssignedToCard";
import CustomerDetails from "../../../components/project/view/CustomerDetails";
import ProjectDeals from "../../../components/project/view/ProjectDeals";
import ProjectDetailsTab from "../../../components/project/view/ProjectDetailsTab";
import ProjectInfo from "../../../components/project/view/ProjectInfo";
import StatusTimeline from "../../../components/project/view/StatusTimeline";
import ProjectAppointments from "../../../components/project/view/appointment/ProjectAppointments";
import BomAdderCard from "../../../components/project/view/bom/BomAdderCard";
import FileFolders from "../../../components/project/view/filefolders/FileFolders";
import FinancialDetails from "../../../components/project/view/financialinfo/FinancialDetails";
import HoaDetails from "../../../components/project/view/hoa/HoaDetails";
import { getAdditionalInfo } from "../../../redux/slices/additionalInfoSlice";
import { clearCurrentFile } from "../../../redux/slices/fileUploadSlice";
import { setCreateSuccess } from "../../../redux/slices/formSlice";
import { getOrgUserList } from "../../../redux/slices/orgSlice";
import {
  getProject,
  setProjectProperty,
} from "../../../redux/slices/projectSlice";
import { CardItem } from "../../../utils/CardItem";
import Storage from "../../../utils/Storage";
import ProjectForm from "../create/ProjectForm";
import "./style.scss";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import IconText from "../../../components/common/IconText";
import DescriptionCard from "../../../components/project/view/DescriptionCard";
import OfficeSourceCard from "../../../components/project/view/OfficeSourceCard";
import SystemPropertyTab from "../../../components/project/view/SystemPropertyTab";
import TitleBar from "../../../components/project/view/TitleBar";
import useWindowSize from "../../../utils/useWindowSize";
import { useTranslation } from "react-i18next";
import PermittingInfo from "../../../components/project/view/permitting/PermittingInfo";

const options = [
  { name: "Edit", value: "edit" },
  { name: "Delete", value: "delete" },
];

const ProjectView = () => {
  const loading = useSelector((state) => state.project.loading);
  const projectData = useSelector((state) => state.project.projectData);
  const ahjDetails = useSelector((state) => state.additionalInfo.ahjDetails);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;

  const { id } = useParams();
  const [conversationValue, setConversationValue] = useState();
  const [chatboxValue, setChatboxValue] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [portalCreated, setPortalCreated] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const createData = useSelector((state) => state.form.createData);
  const propertyData = useSelector((state) => state.form.propertyData);
  const [tabValue, setTabValue] = useState(0);
  const [assignee, setAssignee] = useState(projectData?.assignedto?.name ?? "");
  const orgUserLoading = useSelector((state) => state.org.orgUserLoading);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const orgUsers = useSelector((state) => state.org.orgUsers);
  const [expanded, setExpanded] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowSize();

  const breadcrumbs = [
    <Link key="1" to="/projects">
      Projects
    </Link>,
    <Typography key="2" color="text.primary">
      Project detail
    </Typography>,
  ];

  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const chatboxRef = useRef(null);

  const loadPortal = async () => {
    await Talk.ready
      .then(() => {
        /* Create the two users that will participate in the conversation */

        const me = new Talk.User({
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-7.jpg",
          // welcomeMessage: welcomeMessage ?? null,
          // welcomeMessage: welcomeMessage,
          role: "default",

          custom: {
            type: "projects",
            typeId: id,
          },
        });
        const other = new Talk.User({
          id: projectData?.customerInfo?.pContact?.id,
          name:
            projectData?.customerInfo?.pContact?.firstName +
            " " +
            projectData?.customerInfo?.pContact?.lastName,
          email: projectData?.customerInfo?.pContact?.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-8.jpg",
          // welcomeMessage: "Hey, how can I help?",

          role: "customer",
          // custom: {
          //   typeId: projectData?.id,
          //   type: "projects",
          // },
        });

        /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: process.env.REACT_APP_CHAT_APP_ID,
            me: me,
          });
        }

        // /* Get a conversation ID or create one */

        const conversation = window.talkSession.getOrCreateConversation(
          projectData?.chatId
        );

        /* Set participants of the conversations */
        conversation.setParticipant(me);
        conversation.setParticipant(other);

        const chatbox = window.talkSession.createChatbox(conversationValue);
        chatboxRef.current = chatbox;
        // Mount the chatbox to the container
        if (containerRef.current && chatbox) {
          chatbox.mount(containerRef.current).then(() => {
            setPortalCreated(true);
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const loadTalkApp = () => {
    Talk.ready
      .then(() => {
        /* Create the two users that will participate in the conversation */
        const me = new Talk.User({
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-7.jpg",

          role: "default",

          custom: {
            type: "projects",
            typeId: id,
          },
        });
        const other = new Talk.User({
          id: projectData?.customerInfo?.pContact?.id,
          name:
            projectData?.customerInfo?.pContact?.firstName +
            " " +
            projectData?.customerInfo?.pContact?.lastName,
          email: projectData?.customerInfo?.pContact?.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-8.jpg",
          // welcomeMessage: "Hey, how can I help?",

          role: "customer",
          // custom: {
          //   typeId: projectData?.id,
          //   type: "projects",
          // },
        });

        /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: process.env.REACT_APP_CHAT_APP_ID,
            me: me,
          });
        }

        // /* Get a conversation ID or create one */
        const conversation = window.talkSession.getOrCreateConversation(
          projectData?.chatId
        );

        /* Set participants of the conversations */
        conversation.setParticipant(me);
        conversation.setParticipant(other);
        setConversationValue(conversation);
        // containerRef.current.style.display = "none";
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (anchorElUser) {
      dispatch(
        getOrgUserList({
          url: `crm/user/organization/${orgId}/users`,
          params: {},
        })
      );
    }
  }, [anchorElUser]);

  useEffect(() => {
    /* Retrieve the two users that will participate in the conversation */

    /* Session initialization code */
    if (!loading && userInfo && projectData && projectData.chatId) {
      loadTalkApp();
    }

    if (projectData?.assignedto?.name) {
      setAssignee(projectData?.assignedto?.name);
    }
  }, [loading, projectData]);

  const handleClick = () => {
    if (conversationValue) {
      // setChatVisible(true);
      /* Create and mount chatbox in container */

      const chatbox = window.talkSession.createChatbox(conversationValue);
      chatboxRef.current = chatbox;
      setChatboxValue(chatbox);
      // Mount the chatbox to the container
      if (containerRef.current && chatbox) {
        chatbox.mount(containerRef.current);
        if (containerRef.current.style.display === "none") {
          containerRef.current.style.display = "block";
        }
      }
    }
  };

  const handleSendPortal = () => {
    loadPortal();
  };

  const sentPortalMessage = async () => {
    const chatData = [
      {
        text: "Hi!, Welcome to sunscape",
        sender: userInfo.id.toString(),
        type: "UserMessage",
      },
    ];

    try {
      const response = await fetch(
        `https://api.talkjs.com/v1/${process.env.REACT_APP_CHAT_APP_ID}/conversations/${projectData?.chatId}/messages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CHAT_APP_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(chatData),
        }
      );

      dispatch(
        setCreateSuccess({
          message: "Email notification sent successfully",
          createSuccess: true,
          statusCode: response?.status,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to send notification");
      }
    } catch (error) {
      console.error("Error sending email notification:", error.message);
    }
  };

  useEffect(() => {
    if (portalCreated) {
      sentPortalMessage();
    }

    return () => setPortalCreated(false);
  }, [portalCreated]);

  useEffect(() => {
    if (window.talkSession) {
      window.talkSession.unreads.on("change", function (unreadConversations) {
        setUnreadCount(unreadConversations[0]?.unreadMessageCount);
      });
    }
  }, [conversationValue]);

  useEffect(() => {
    if (chatboxValue) {
      chatboxValue.onCustomConversationAction("close-chat", (event) => {
        // Send the message to your backend here, so a moderator can review it.
        containerRef.current.style.display = "none";
        chatboxValue.destroy();
      });
    }
  }, [chatboxValue]);

  // const handleDocumentClick = (event) => {
  //   if (containerRef.current && !containerRef.current.contains(event.target)) {
  //     chatboxValue?.destroy();
  //   }
  // };

  const loadProjects = () => {
    dispatch(setProjectProperty(null));
    dispatch(
      getProject({
        url: `crm/org/${orgId}/proj/${id}`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (orgId) {
      loadProjects();
    }
  }, [id, orgId]);

  const loadAdditionalInfo = () => {
    dispatch(
      getAdditionalInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=financialdtl&data=hoa&data=systemdtl&data=ahjdtl&data=utldtl&data=propertyDetails&data=folders&data=status&&data=permitdtl`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (orgId && projectData?.id) {
      loadAdditionalInfo();
    }
  }, [projectData?.id]);

  const handleClose = () => {
    setIsOpen(false);
    setIsViewOpen(false);
  };

  const handleMultiple = () => {
    setIsViewOpen(true);
  };

  // const loadAppointmentList = () => {
  //   dispatch(
  //     getAppointmentList({
  //       url: `crm/org/${orgId}/proj/${id}/appointment/status/${tabValue}`,
  //       params: {},
  //     })
  //   );
  // };

  const appointmentTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (createData?.createSuccess || propertyData?.createSuccess) {
      console.log(
        "createData?.createSuccess=--------->",
        createData?.createSuccess
      );
      console.log(
        "propertyData?.createSuccess=--------->",
        propertyData?.createSuccess
      );

      loadProjects();
      dispatch(clearCurrentFile());

      // if (!loading && value === 1) {
      //   loadProjectsProgess();
      // }
      loadAdditionalInfo();
      setIsViewOpen(false);
      // dispatch(setCreateSuccess(null));
      // if (!loading) {
      //   loadAppointmentList();
      // }
    }
  }, [createData, propertyData]);

  const handleAssign = (event) => {
    event.preventDefault();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElUser(null);
  };

  const handleItem = (value) => {
    switch (value) {
      case "edit":
        setIsOpen(true);
        break;
      case "delete":
        console.log("value=------>", value);
        break;
    }
  };

  if (!isOpen && loading) {
    return <Loading />;
  }
  return (
    <div className="project_view">
      {width < 600 ? (
        <Accordion
          expanded={expanded}
          onChange={(e, isExpanded) => {
            console.log("isExpanded=------>", isExpanded);
            setExpanded(isExpanded);
          }}
          className="title_bar_accordion"
        >
          {!expanded && (
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div className="">
                <Typography variant="h6" key="2" color="text.primary">
                  {projectData?.customerInfo?.pContact?.firstName +
                    " " +
                    projectData?.customerInfo?.pContact?.lastName}
                </Typography>
                <IconText
                  className="m_0 marginT0 "
                  icon={
                    <LocationOnIcon
                      style={{ color: "#75787d" }}
                      fontSize="small"
                      className="marginR10"
                    />
                  }
                  title={projectData?.address}
                  fontSize="small"
                />
              </div>
            </AccordionSummary>
          )}
          <AccordionDetails onClick={() => setExpanded(!expanded)}>
            <TitleBar handleItem={handleItem} />
            <div className="d_flex justify_content_end">
              <ArrowDropUpIcon />
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <TitleBar handleItem={handleItem} />
      )}

      <div
        className="view_page_content"
        style={{ paddingLeft: "25px", paddingRight: "25px" }}
      >
        <Grid sx={{ marginTop: "16px" }} container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <CardItem height={isExpanded ? "auto" : "100px"}>
              <DescriptionCard
                data={projectData}
                onToggle={() => setIsExpanded(!isExpanded)}
                isExpanded={isExpanded}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="100px">
              <OfficeSourceCard type="project" data={projectData} />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <SystemPropertyTab />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <StatusTimeline
                // value={value}
                isViewOpen={isViewOpen}
                handleMultiple={handleMultiple}
                // onChange={handleChange}
                onClose={handleClose}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <ProjectDetailsTab projectData={projectData} type="proj" />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <ProjectInfo viewType="project" data={projectData} />
            </CardItem>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <AhjInfo />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <BomAdderCard
                folderList={projectData?.folders}
                projectData={projectData}
              />
            </CardItem>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <HoaDetails />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <PermittingInfo />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <FinancialDetails />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <FileFolders
                type="proj"
                // folderList={projectData?.folders}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <ProjectAppointments
                type="proj"
                // loadAppointmentList={loadAppointmentList}
                // onChange={appointmentTabChange}
                // tabValue={tabValue}
              />
            </CardItem>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <CustomerDetails
                pContact={projectData?.customerInfo?.pContact}
                sContact={projectData?.customerInfo?.sContact}
                onChat={handleClick}
                unreadCount={unreadCount}
                onSendPortal={handleSendPortal}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <AssignedToCard
                assignedto={projectData?.assignedto}
                assigneelist={projectData?.assigneelist}
              />
            </CardItem>
          </Grid>

          {projectData?.leadId && (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <CardItem height="360px">
                <ProjectDeals leadId={projectData?.leadId} />
              </CardItem>
            </Grid>
          )}
        </Grid>
        <CustomChat innerRef={containerRef} />
        <BasicModal isOpen={isOpen} onClose={handleClose} sx={modalStyle}>
          <ProjectForm
            id={id}
            projectData={projectData}
            onClose={handleClose}
          />
        </BasicModal>
      </div>
    </div>
  );
};

export default ProjectView;
