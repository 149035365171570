import { useEffect, useState } from "react";
import ActivityTimeline from "../activity/ActivityTimeline";
import SelectMenu from "../select/Select";
import CardTitle from "./CardTitle";
import { useDispatch, useSelector } from "react-redux";
import Button from "../buttons/Button";
import Loading from "../loading/Loading";
import Storage from "../../utils/Storage";
import { useParams } from "react-router-dom";
import { getProjectActivity } from "../../redux/slices/projectSlice";
import { useTranslation } from "react-i18next";

const timelineData = [
  {
    header: "Update of calendar events &",
    subHeader: " Added new events in next week.",
  },
  {
    header: "New theme for Spruko Website completed",
    subHeader: " Lorem ipsum, dolor sit amet.",
  },
  {
    header: "Created a New Task today",
    subHeader: " Added new events in next week.",
  },
  {
    header: "New member ",
    subHeader: "added today to AI Summit.",
    member: "@andreas gurrero",
  },
  {
    header: "32 New people joined summit. ",
  },
  {
    header: "Neon Tarly added Robert Bright to AI summit project. ",
  },
  {
    header: "Replied to new support request ",
  },
  {
    header: "Completed documentation of  ",
  },
];

const ActivityCard = ({ showTitle }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("all");
  const activityData = useSelector((state) => state.project.activityData);

  // const [activityList, setActivityList] = useState(activityData?.trackjson);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const activityLoading = useSelector((state) => state.project.activityLoading);
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const loadProjectsActivity = ({ page, pageSize }) => {
    dispatch(
      getProjectActivity({
        url: `crm/org/${orgId}/proj/${id}/activities`,
        params: { page: page, pageSize: pageSize },
      })
    );
  };

  useEffect(() => {
    if (!showTitle) {
      loadProjectsActivity({ page: page, pageSize: pageSize });
    }
  }, []);

  // useEffect(() => {
  //   setNewPage(page);
  // }, [page]);

  useEffect(() => {
    if (page > 1) loadProjectsActivity({ page: page, pageSize: pageSize });
  }, [page]);

  // useEffect(() => {
  //   if (page !== newpage)
  //     setActivityList([...activityList, ...activityData?.trackjson]);
  // }, [newpage, activityData]);

  if (activityLoading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <div className={showTitle ? "dash_card_content" : ""}>
        {showTitle && (
          <CardTitle title="Recent Activity">
            <SelectMenu
              className="dashboard_filter"
              options={[
                { title: "View All", value: "all" },
                { title: "Today", value: "today" },
                { title: "Yesterday", value: "yesterday" },
                { title: "This month", value: "this_month" },
              ]}
              border={false}
              onChange={handleChange}
              selected={selected}
            />
          </CardTitle>
        )}

        <div className="">
          <ActivityTimeline
            timelineData={activityData?.activityList}
            randomColors={true}
            type="dashboard_activity"
          />

          {activityData.totalItems > 10 && activityData.totalPages > page && (
            <Button
              className="default_button link_blue"
              onClick={() => setPage(page + 1)}
            >
              {t("cargar más")}...
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
