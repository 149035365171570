import { InputBase, styled } from "@mui/material";
import SearchIcon from "../../assets/images/search.svg";
import { setSearchValue } from "../../redux/slices/searchSlice";
import { useDispatch } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: " 50px",
  border: "1px solid #E0E0E0",
  backgroundColor: "#FFF",
  height: "34px",
  width: "300px",
  marginRight: theme.spacing(2),
  marginLeft: 0,

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "300px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

const SearchBar = () => {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(setSearchValue(e.target.value));
  };
  return (
    <Search>
      <SearchIconWrapper>
        <img src={SearchIcon} alt="" />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Type text..."
        inputProps={{ "aria-label": "search" }}
        className="search_input"
        onChange={handleChange}
      />
    </Search>
  );
};

export default SearchBar;
