import TextField from "@mui/material/TextField";

const TextInput = ({
  id,
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  inputClass,
  onBlur,
  register,
  variant,
  fieldSize,
  marginBottom,
  marginLeft,
  fieldStyle,
  autoFocus = true,
  width,
  multiline,
  rows,
  inputProps,
  error,
  helperText,
  required,
}) => {
  return (
    <>
      {variant === "mui" ? (
        <TextField
          id={id ?? "outlined-basic"}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                border: "2px solid #189d3d", // Change this to your desired color
              },
            },
            "& label.Mui-focused": {
              color: "#189d3d", // Label color when focused
            },
            marginBottom: marginBottom ?? "10px",
            marginTop: "10px",
            marginLeft: marginLeft ?? "0px",
            width: width ?? width,
          }}
          required={required}
          label={label}
          variant={fieldStyle}
          inputProps={
            inputProps
              ? { inputProps, style: { fontSize: 13 } }
              : { style: { fontSize: 13 } }
          }
          autoFocus={autoFocus}
          className="input_field_style"
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          size={fieldSize}
          multiline={multiline}
          rows={rows}
          error={error}
          helperText={helperText}
          // {...register(name)}
        />
      ) : (
        <>
          <label className="text_medium">{label}</label>
          <input
            autoFocus={autoFocus}
            className={inputClass ?? ""}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            {...register(name)}
          />
        </>
      )}
    </>
  );
};

export default TextInput;
