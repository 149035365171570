import { Box, Tabs } from "@mui/material";
import {
  a11yProps,
  CustomTabPanel,
  StyledTab,
} from "../../../constants/Constants";
import BOMCard from "./BOMCard";
import { useState } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useTranslation } from "react-i18next";

const BomAdderCard = ({folderList,projectData}) => {
  const [value, setValue] = useState(0);
  const {t}=useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="card_container">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          // textColor="secondary"
          // indicatorColor="secondary"

          TabIndicatorProps={{
            style: {
              backgroundColor: "#189d3d",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab
            icon={<ReceiptIcon />}
            iconPosition="start"
            label="BOM"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(0)}
          />
          <StyledTab
            icon={<PostAddIcon />}
            iconPosition="start"
            label="Adder"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <div className="card_content ">
        <CustomTabPanel value={value} index={0}>
          <BOMCard title={t("BOM")} type="bom" folderList={folderList} projectData={projectData} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <BOMCard title={t("Adder")} type="adder" folderList={folderList} />
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default BomAdderCard;
