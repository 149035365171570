import { takeLatest, put, fork, call } from "redux-saga/effects";
import { createItem, editItem, getItem } from "../../services/service";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import { setDialog } from "../slices/dialogSlice";

import {
  createChecklist,
  getChecklist,
  setChecklist,
  setChecklistLoadingFalse,
  updateChecklist,
} from "../slices/checklistSlice";

function* onLoadChecklistAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setChecklist({ ...response.data[0] }));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setChecklistLoadingFalse());
  }
}

function* onLoadChecklist() {
  yield takeLatest(getChecklist.type, onLoadChecklistAsync);
}

function* onCreateChecklistAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setChecklistLoadingFalse());
  }
}

function* onCreateChecklist() {
  yield takeLatest(createChecklist.type, onCreateChecklistAsync);
}

export const checklistSagas = [fork(onLoadChecklist), fork(onCreateChecklist)];
