import React, { useRef, useState } from "react";
import "./style.scss";
import { useDrag, useDrop } from "react-dnd";
import { Edit } from "@mui/icons-material";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import CustomDialog from "../../../components/dialog/CustomDialog";
const options = [
  { name: "Edit", value: "Edit" },
  { name: "Delete", value: "Delete" },
  { name: "View Stages", value: "View" },
];

const StatusCard = ({
  stage,
  moveListItem,
  index,
  handleDrop,
  handleEdit,
  handleDelete,
  handleViewStageClick,
  key,
  moveCardHandler,
}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item) => {
      handleDrop(item.index);
    },
  });
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));
  const handleSelect = (value) => {
    if (value === "Edit") {
      handleEdit(index);
    } else if (value === "Delete") {
      setOpenDeleteConfirmation(true);
    } else if (value === "View") {
      handleViewStageClick(index);
    }
  };

  return (
    <>
      <div
        className="stage_card"
        ref={dragDropRef}
        style={{ opacity: isDragging ? 0.25 : 1 }}
        key={key}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <div
            style={{
              color: "black",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "bolder",
              display: "flex",
              alignItems: "center",
            }}
          >
            {stage.name}
          </div>
          <div>
            <ThreeDotDropdown
              options={options}
              onSelect={(value) => handleSelect(value)}
            />
          </div>
        </div>
      </div>
      <CustomDialog
        open={openDeleteConfirmation}
        title="Delete"
        message="Are you sure you want to delete this Status?"
        okText="Delete"
        cancelText="Cancel"
        onOk={() => {
          setOpenDeleteConfirmation(false);
          handleDelete(index);
        }}
        onCancel={() => setOpenDeleteConfirmation(false)}
      />
    </>
  );
};

export default StatusCard;
