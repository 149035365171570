import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CardTitle from "../../../dashboard/CardTitle";
import LabelValue from "../LabelValue";
import HoaDetailsForm from "./HoaDetailsForm";
import { useEffect, useState } from "react";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import { useSelector } from "react-redux";
import Loading from "../../../loading/Loading";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Storage from "../../../../utils/Storage";
import {
  getAdditionalInfo,
  getHoaInfo,
} from "../../../../redux/slices/additionalInfoSlice";
import { useTranslation } from "react-i18next";

const HoaDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const hoaDetails = useSelector((state) => state.additionalInfo.hoaDetails);
  const loading = useSelector((state) => state.additionalInfo.loading);
  const hoaLoading = useSelector((state) => state.additionalInfo.hoaLoading);

  const snackbarData = useSelector((state) => state.form.snackbarData);
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;

  const { id } = useParams();
  const dispatch = useDispatch();

  const editHoaDetails = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const loadAdditionalInfo = () => {
    dispatch(
      getHoaInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=hoa`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (snackbarData?.createSuccess && snackbarData?.params?.data === "hoa") {
      console.log("params=----------->", snackbarData?.params);
      handleClose();
      loadAdditionalInfo();
    }
  }, [snackbarData]);

  if (loading || hoaLoading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <CardTitle title={t("HOA Details")} type="project_view">
        <IconButton className="marginL10" size="small" onClick={editHoaDetails}>
          <Edit />
        </IconButton>
      </CardTitle>
      <div className="card_content">
        <div>
          <LabelValue
            label={t("HOA Name")}
            value={hoaDetails?.hoaName}
            buttonLabel="Add HOA Name"
            onClick={editHoaDetails}
          />
          <LabelValue
            className="m_0"
            label={t("Contact Name")}
            value={hoaDetails?.contactName}
            buttonLabel="Add Contact Name"
            onClick={editHoaDetails}
          />
          <LabelValue
            label={t("Phone")}
            value={hoaDetails?.phone}
            buttonLabel="Add Phone"
            onClick={editHoaDetails}
          />
          <LabelValue
            label={t("Email")}
            value={hoaDetails?.email}
            buttonLabel="Add Email"
            onClick={editHoaDetails}
          />
        </div>
        <BasicModal
          isOpen={isOpen}
          onClose={handleClose}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            maxHeight: "500px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <HoaDetailsForm
            // id={id}
            // projectData={projectData}
            hoaDetails={hoaDetails}
            onClose={handleClose}
          />
        </BasicModal>
      </div>
    </div>
  );
};

export default HoaDetails;
