import { Delete, Edit } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import CardTitle from "../../../dashboard/CardTitle";
import LabelValue from "../LabelValue";
import { useEffect, useState } from "react";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import FinancialDetailsForm from "./FinancialDetailsForm";
import { useSelector } from "react-redux";
import Loading from "../../../loading/Loading";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Storage from "../../../../utils/Storage";
import {
  createFinancialInfo,
  getFinancialInfo,
} from "../../../../redux/slices/additionalInfoSlice";
import Button from "../../../buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmptyList from "../../../emptylist/EmptyList";
import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";
import Grid2 from "@mui/material/Unstable_Grid2";
import FinancialDetailsEditForm from "./FinancialDetailsEditForm";
import { useTranslation } from "react-i18next";
import CurrencyView from "../../../currency/CurrencyView";
import { red } from "@mui/material/colors";

const FinancialDetails = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const financeDetails = useSelector(
    (state) => state.additionalInfo.financeDetails
  );
  const loading = useSelector((state) => state.additionalInfo.loading);
  const financialLoading = useSelector(
    (state) => state.additionalInfo.financialLoading
  );

  const snackbarData = useSelector((state) => state.form.snackbarData);
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;

  const { id } = useParams();
  const dispatch = useDispatch();

  const editFinaceDetails = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setEditItem(null);
    setIsOpen(false);
    setIsOpenEdit(false);
  };

  const loadAdditionalInfo = () => {
    dispatch(
      getFinancialInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=financialdtl`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (
      snackbarData?.createSuccess &&
      snackbarData?.params?.data === "financialdtl"
    ) {
      console.log("params=----------->", snackbarData?.params);
      handleClose();
      loadAdditionalInfo();
    }
  }, [snackbarData]);

  if (loading || financialLoading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <CardTitle title="Finance" type="project_view">
        {/* <IconButton
          className="marginL10"
          size="small"
          onClick={editFinaceDetails}
        >
          <Edit />
        </IconButton> */}
        <Button
          onClick={editFinaceDetails}
          className="icon_button d_flex align_items_center primary_bg text_white"
        >
          <AddIcon className="btn_icon" />
          Add New
        </Button>
      </CardTitle>
      <div className="card_content">
        {financeDetails?.length > 0 ? (
          financeDetails?.map((item, index) => (
            <Accordion
              key={index}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                background: "rgba(55, 144, 107, 0.08)",
                border: "none",
                boxShadow: "none",
                // marginBottom: "10px",
                ":before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                sx={{ borderBottom: "1px dotted #189d3d" }}
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#189d3d",
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="w_100 d_flex align_items_center justify_content_between">
                  <Typography
                    sx={{
                      color: "#189d3d",
                    }}
                    className="font_12_green_600"
                  >
                    {item?.financeType}
                  </Typography>

                  {/* <CurrencyView amount={item?.price * item?.qty} /> */}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid2 container spacing={2}>
                  <Grid2 item xs={12} sm={12} md={6} lg={6}>
                    <div>
                      <Typography className="font_12">
                        <b>Amount:</b>
                      </Typography>
                      <Typography className="font_12">
                        <CurrencyView amount={item?.loanAmount} />
                      </Typography>
                    </div>
                    <div className="marginT10">
                      <Typography className="font_12">
                        <b>Base PPW:</b>
                      </Typography>
                      <Typography className="font_12">
                        <CurrencyView amount={item?.baseppw} />
                      </Typography>
                    </div>
                  </Grid2>
                  <Grid2 item xs={12} sm={12} md={6} lg={6}>
                    <div>
                      <Typography className="font_12">
                        <b>Dealer Fee:</b>{" "}
                      </Typography>
                      <Typography className="font_12">
                        <CurrencyView amount={item?.dealerFee} />
                      </Typography>
                    </div>
                    <div className="marginT10">
                      <Typography className="font_12">
                        <b>Total PPW:</b>
                      </Typography>
                      <Typography className="font_12">
                        <CurrencyView amount={item?.totalppw} />
                      </Typography>
                    </div>
                  </Grid2>
                </Grid2>
                <div className="d_flex align_items_center ">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditItem(item);

                      setIsOpenEdit(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      const val = {};
                      val.id = item?.id;
                      val.isActive = 0;
                      val.type = item?.financeType;

                      const editedData = {
                        financialdtl: [val],
                      };

                      dispatch(
                        createFinancialInfo({
                          url: `/crm/org/${orgId}/proj/${id}/info`,
                          data: editedData,
                          params: { data: "financialdtl" },
                        })
                      );
                    }}
                  >
                    <Delete
                      sx={{
                        color: red[500],
                      }}
                    />
                  </IconButton>
                </div>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <EmptyList
            icon={<DeveloperBoardOffIcon />}
            message={`No finance details added`}
          />
        )}
      </div>

      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "600px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <FinancialDetailsForm
          // id={id}
          // projectData={projectData}
          financeDetails={financeDetails}
          onClose={handleClose}
        />
      </BasicModal>
      <BasicModal
        isOpen={isOpenEdit}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <FinancialDetailsEditForm onClose={handleClose} editItem={editItem} />
      </BasicModal>
    </div>
  );
};

export default FinancialDetails;
