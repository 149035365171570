import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloseIcon from "../../../assets/images/close";
import Button from "../../../components/buttons/Button";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";
import { createNewMaterials } from "../../../redux/slices/materialSlice";

const AddMaterialsModal = ({ open, close, title,materialId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      materials: [
        {
          name: "",
          base_price: 0,
          description: "",
          baseuom: "",
          //   addSource: newData?.addSource ?? "",
        },
      ],
    },
  });

  //   -----------------------------
  const { fields, append, prepend, remove } = useFieldArray({
    name: "materials",
    control,
  });

  const addMaterial = (data) => {
    const newData = {
      materials: data.materials.map((item) => ({ ...item, isactive: 1 })),
    };

    dispatch(
      createNewMaterials({
        url: `/crm/org/${id}/bom/store/type/${materialId}`,
        data: newData,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (snackbarData?.createSuccess) {
      reset();
      close();
    }
  }, [snackbarData]);
  return (
    <>
      <BasicModal
        isOpen={open}
        onClose={close}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "600px",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          maxHeight: "100vh", // Limits height to the viewport
          overflow: "auto",
          "@media (max-width: 768px)": {
            // Adjust the max-width as needed
            width: "80%", // Width for mobile devices
          },
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">{title}</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(addMaterial)}>
                  {fields.map((field, index) => {
                    return (
                      <>
                        <React.Fragment key={field.id}>
                          <Grid
                            sx={{ marginTop: "16px" }}
                            container
                            spacing={2}
                          >
                            {index !== 0 && (
                              <>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        backgroundColor:
                                          "transparent !important",
                                      }}
                                      aria-label="delete"
                                      onClick={() => {
                                        // let temp = [...itemsArr];
                                        // temp.pop();
                                        // setItemsArr(temp);
                                        remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <label className="text_medium">
                                Material Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                {...register(`materials.${index}.name`, {
                                  required: true,
                                })}
                              />
                              <p className="error_message">
                                {errors.name?.message}
                              </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <label className="text_medium">Base Price</label>
                              <input
                                type="text"
                                name="base_price"
                                {...register(`materials.${index}.base_price`, {
                                  required: true,
                                })}
                              />
                              <p className="error_message">
                                {errors.name?.message}
                              </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <label className="text_medium">Unit</label>
                              <input
                                type="text"
                                name="baseuom"
                                {...register(`materials.${index}.baseuom`, {
                                  required: true,
                                })}
                              />
                              <p className="error_message">
                                {errors.name?.message}
                              </p>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <label className="text_medium">Description</label>
                              <textarea
                                type="text"
                                name="description"
                                rows={1}
                                {...register(`materials.${index}.description`, {
                                  //   required: true,
                                })}
                              />
                              <p className="error_message">
                                {errors.description?.message}
                              </p>
                            </Grid>
                          </Grid>
                          <div style={{ marginTop: "10px" }}>
                            <hr
                              style={{
                                color: "#cdd2e1",
                                backgroundColor: "#cdd2e1",
                                height: "1px",
                                border: "none",
                              }}
                            />
                          </div>
                        </React.Fragment>
                      </>
                    );
                  })}

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="addIcon"
                    onClick={(e) => {
                      append({
                        name: "",
                        description: "",
                      });
                    }}
                  >
                    <div style={{ cursor: "pointer" }}>
                      {" "}
                      <IconButton
                        aria-label="adding"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </div>
                  </div>
                  <Button type="submit" className="primary_button marginT10">
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </>
  );
};

export default AddMaterialsModal;
