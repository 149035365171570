import React, { useEffect } from "react";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import { Grid, IconButton } from "@mui/material";
import Button from "../../../components/buttons/Button";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/images/close";
import { useDispatch } from "react-redux";
import { createRoleOrg } from "../../../redux/slices/orgSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const AddRolesModal = ({ open, close,orgId }) => {
  const {id}=useParams();
  const createData = useSelector((state) => state.form.createData);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const dispatch=useDispatch();
  const addRole = (data) => {
    dispatch(createRoleOrg({
      url:`/crm/org/${id}/roles`,
      data:{
        roleData:[
          {
            rolId:0,
            rolName:data.name,
            rolDesc:data.description,
            rolDefrole:0,
            rolActive:1
          }
        ]
      }
    }));
  };
  useEffect(()=>{
    if(createData?.createSuccess){
      reset();
      close()
    }
  },[createData])
  return (
    <>
      <BasicModal
        isOpen={open}
        onClose={close}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "600px",
          display: "flex",
          flexDirection: "column",
          "@media (max-width: 768px)": {
            // Adjust the max-width as needed
            width: "80%", // Width for mobile devices
          },
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Add Role</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(addRole)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label className="text_medium">Role Name</label>
                      <input
                        type="text"
                        name="name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      <p className="error_message">{errors.name?.message}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label className="text_medium">Description</label>
                      <textarea
                        type="text"
                        name="description"
                        rows={8}
                        {...register("description", {})}
                      />
                      <p className="error_message">
                        {errors.description?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </>
  );
};

export default AddRolesModal;
