import * as React from "react";
import CardTitle from "../../dashboard/CardTitle";
import Button from "../../buttons/Button";
import FeedIcon from "@mui/icons-material/Feed";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { useDispatch, useSelector } from "react-redux";
import { getLead } from "../../../redux/slices/leadSlice";
import DateView from "../../date/DateView";
import { useNavigate } from "react-router-dom";
import Storage from "../../../utils/Storage";
import { useTranslation } from "react-i18next";

export default function ProjectDeals({ leadId }) {
  const { t } = useTranslation();
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const dispatch = useDispatch();
  const leadData = useSelector((state) => state.lead.leadData);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (leadId) {
      dispatch(
        getLead({
          url: `crm/org/${orgId}/leads/${leadId}`,
          params: {},
        })
      );
    }
  }, [leadId]);

  return (
    <div className="card_container">
      <CardTitle title={t("Lead")} type="project_view">
        {/* <Button className="icon_button d_flex align_items_center primary_bg text_white">
          <AddIcon className="btn_icon" />
          Associate Lead
        </Button> */}
      </CardTitle>
      <div className="light_bg_border deal_item">
        <div className="d_flex align_items_center justify_content_between">
          <h4 className="my_0">{leadData?.address}</h4>
          <div className="d_flex align_items_center">
            {/* <Button className="icon_button marginL10 d_flex align_items_center">
              <FeedIcon className="btn_icon" />
              lead Feed
            </Button> */}
            <Button
              onClick={() => navigate(`/leads/${leadId}`)}
              className="icon_button marginL10 d_flex align_items_center primary_bg text_white"
            >
              <OpenInNewIcon className="btn_icon" />
              {t("View")}
            </Button>
          </div>
        </div>
        <div className="d_flex align_items_center justify_content_between">
          <div>
            <p className="mb_0">
              {t("Created")}: <DateView date={leadData?.createdAt} />{" "}
            </p>
            <p className="my_0">
              {t("Created By")}: {leadData?.createdBy?.name}{" "}
            </p>

            {/* <p className="my_0">
              <small>survey id: 1381359</small>
            </p> */}
          </div>
          {/* <Button className="icon_button marginL10 d_flex align_items_center bg_black text_white">
            <LowPriorityIcon className="btn_icon" /> Change Order
          </Button> */}
        </div>
      </div>
    </div>
  );
}
