import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import DateView from "../../date/DateView";
import ViewTitleBar from "../../viewtitle/ViewTitleBar";
import { Edit } from "@mui/icons-material";
import Status from "../../status/Status";
import { useSelector } from "react-redux";
import CreateDateIcon from "../../../assets/images/createdate.svg";
import Storage from "../../../utils/Storage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editProject,
  getProjectMainStatusList,
} from "../../../redux/slices/projectSlice";
import Button from "../../buttons/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const TitleBar = ({ handleItem }) => {
  const projectData = useSelector((state) => state.project.projectData);
  const [anchorElMainStatus, setAnchorElMainStatus] = useState(null);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const projectMainStatusList = useSelector(
    (state) => state.project.projectMainStatusList
  );
  const mainStatusLoading = useSelector(
    (state) => state.project.mainStatusLoading
  );
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const dispatch = useDispatch();
  const isOwner =
    Storage.read(`org${userInfo?.id}`)?.isOwner === "true" ? true : false;
  const isModerator =
    Storage.read(`org${userInfo?.id}`)?.isModerator === "true" ? true : false;

  const { id } = useParams();
  const handleMainStatus = (event) => {
    setAnchorElMainStatus(event.currentTarget);
  };

  const handleCloseMainStatusMenu = () => {
    setAnchorElMainStatus(null);
  };

  const loadProjectMainStatusList = () => {
    dispatch(
      getProjectMainStatusList({
        url: `crm/org/${orgId}/proj/mainStatus`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (anchorElMainStatus) {
      loadProjectMainStatusList();
    }
  }, [anchorElMainStatus]);

  return (
    <div className={"title_bar"}>
      <div className="d_flex align_items_center">
        <ViewTitleBar
          firstName={projectData?.customerInfo?.pContact?.firstName}
          lastName={projectData?.customerInfo?.pContact?.lastName}
          email={projectData?.customerInfo?.pContact?.email}
          mobile={projectData?.customerInfo?.pContact?.mobile}
          address={projectData?.address}
        />
      </div>
      <div className="d_flex created_details">
        {projectData?.CreatedAt && (
          <div className="">
            <p
              className="m_0 font_12_dark_gray"
              style={{
                textAlign: "center",
              }}
            >
              <small>Created</small>
            </p>
            <div className="d_flex align_items_center">
              <img src={CreateDateIcon} alt="Assign to" />
              <p className="font_14_dark m_0 marginL10">
                <DateView date={projectData?.CreatedAt} />
              </p>
            </div>
          </div>
        )}

        {projectData?.dueDate && (
          <div className="marginL20">
            <p
              className="m_0 font_12_dark_gray"
              style={{
                textAlign: "center",
              }}
            >
              <small>Due Date</small>
            </p>
            <div className="d_flex align_items_center">
              <img src={CreateDateIcon} alt="Assign to" />
              <p className="font_14_dark m_0 marginL10">
                <DateView date={projectData?.dueDate} />
              </p>
            </div>
          </div>
        )}
        {projectData?.createdBy?.name && (
          <div className="marginL20">
            <p
              className="m_0 font_12_dark_gray"
              style={{
                textAlign: "center",
              }}
            >
              <small>Created By</small>
            </p>
            <div className="d_flex align_items_center justify_content_center">
              <p className="font_14_dark m_0 marginL10 ">
                {projectData?.createdBy?.name}
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="d_flex align_items_center status_edit_wrapper">
        {/* <ThreeDotDropdown
          options={options}
          onSelect={(value) => handleItem(value)}
        /> */}
        {projectData?.finalStatus?.name && (
          <div className="marginT10">
            <Button
              style={{
                cursor: "pointer",
                border: "1px dashed rgb(24, 157, 61)",
                borderRadius: "20px",
                padding: "2px 5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                backgroundColor: "#37906b14",
                color: "#189D3D",
              }}
              onClick={handleMainStatus}
              // disabled={true}
              disabled={isOwner | isModerator ? false : true}
            >
              <p style={{ fontSize: "12px", margin: 0 }}>
                {projectData?.finalStatus?.name}
              </p>
              <ArrowDropDownIcon />
            </Button>
            {/* <div
              style={{
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleMainStatus}
            >
              <Status
                status={projectData?.finalStatus?.name}
                statusType="success"
                type="dropdown"
              />
            </div> */}
            {!mainStatusLoading && (
              <Menu
                sx={{ mt: "30px" }}
                style={{ height: "400px" }}
                id="menu-appbar"
                anchorEl={anchorElMainStatus}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElMainStatus)}
                onClose={handleCloseMainStatusMenu}
              >
                {projectMainStatusList?.map((stage, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      // setStatus(stage);
                      // setValue("statusId", status?.id);
                      const editedData = {
                        isfinalst: stage.id,
                      };

                      dispatch(
                        editProject({
                          url: `crm/org/${orgId}/proj/${id}`,
                          data: editedData,
                          params: {},
                        })
                      );
                      handleCloseMainStatusMenu();
                    }}
                  >
                    <Typography textAlign="center">{stage?.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </div>
        )}

        <IconButton
          className="marginL10"
          size="small"
          onClick={() => handleItem("edit")}
        >
          <Edit />
        </IconButton>
      </div>
    </div>
  );
};

export default TitleBar;
