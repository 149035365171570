import React, { useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import Viewer from "react-viewer";
import "./style.scss";
import DateView from "../date/DateView";
import AWS from "aws-sdk";
import Loading from "../loading/Loading";

const FileViewer = ({ files, currentFileIndex, onNext, onPrev }) => {
  const file = files[currentFileIndex];
  const extension = file.name.split(".").pop().toLowerCase();
  const [visible, setVisible] = useState(false);
  const [signedUrl, setSignedUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpenPdf = async (fileUrl) => {
    // Configure AWS with your credentials and region
    setLoading(true);
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const baseURL = "https://uatsunscape.s3.us-east-1.amazonaws.com/";

    const newUrl = fileUrl.replace(baseURL, "");
    const signUrl = newUrl.replace(/\.heic$/i, ".png");

    const s3 = new AWS.S3();
    const bucketName = "uatsunscape";
    const objectKey = signUrl; // S3 object key
    const expiresIn = 60 * 5; // URL expiration time in seconds (5 minutes)

    const params = {
      Bucket: bucketName,
      Key: objectKey,
      Expires: expiresIn,
    };

    try {
      const newSignedUrl = await s3.getSignedUrlPromise("getObject", params);

      setSignedUrl(newSignedUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error generating signed URL:", error);
    }
  };

  useEffect(() => {
    handleOpenPdf(file.url);
  }, [file]);

  const images = React.useMemo(() => {
    return (
      files?.map((item) => {
        const fileExtension = item.name.split(".").pop().toLowerCase();

        return {
          src:
            fileExtension === "heic"
              ? item.url.replace(/\.heic$/i, ".png")
              : item.url,
          downloadUrl: item.url,
        };
      }) ?? []
    );
  }, [files]);

  const Mousetrap = require("mousetrap");
  Mousetrap.bind("right", () => {
    onNext();
  });
  Mousetrap.bind("left", () => {
    onPrev();
  });

  // Function to render file based on type
  const renderFile = () => {
    if (loading) {
      return (
        <div style={{ height: "100%" }}>
          <Loading />
        </div>
      );
    } else {
      switch (extension) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
        case "webp":
        case "heic":
          return (
            <div>
              {/* <div className="d_flex marginB10 ">
              <div
                style={{ width: "50%" }}
                className="d_flex align_items_center justify_content_end marginT10"
              >
                <IconButton
                  style={{ backgroundColor: "#189d3d", color: "#fff" }}
                >
                  <FindReplaceIcon />
                </IconButton>
                <IconButton
                  onClick={() => setVisible(true)}
                  className="marginL10"
                  style={{ color: "#fff" }}
                >
                  <FullscreenIcon />
                </IconButton>
              </div>
            </div> */}
              <div className="d_flex ">
                <div style={{ width: "50%" }}>
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: file?.name,
                        // isFluidWidth: true,
                        src:
                          extension === "heic"
                            ? signedUrl.replace(/\.heic$/i, ".png")
                            : signedUrl,
                        // width: "100%",
                        width: 700,
                        height: 500,
                      },
                      largeImage: {
                        src:
                          extension === "heic"
                            ? signedUrl.replace(/\.heic$/i, ".png")
                            : signedUrl,
                        width: 3000,
                        height: 2000,
                      },
                      enlargedImageContainerStyle: {
                        zIndex: "1500",
                      },
                      enlargedImageContainerDimensions: {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <h3 className="text_white">{file.name}</h3>
                  <p className="text_white">
                    <small>Uploaded By:</small> <b>{file.createdBy}</b>
                  </p>
                  <p className="text_white">
                    <small>
                      Uploaded On:{" "}
                      <b>
                        <DateView date={file.createdAt} />
                      </b>
                    </small>
                  </p>
                </div>
              </div>
              <div className="full_view">
                <Viewer
                  visible={visible}
                  onClose={() => setVisible(false)}
                  downloadable
                  downloadInNewWindow
                  activeIndex={currentFileIndex}
                  images={images}
                />
              </div>
            </div>
          );
        case "mp4":
        case "webm":
        case "ogg":
          return (
            <video controls>
              <source src={signedUrl} type={file.type} />
            </video>
          );
        case "audio":
          return (
            <audio controls>
              <source src={signedUrl} type={file.type} />
            </audio>
          );
        case "pdf":
          // Handle application types like PDF, MS Office documents, etc.

          return (
            <iframe
              src={signedUrl}
              title={file?.name}
              width="100%"
              height="100%"
            />
          );

        // Add additional cases for other document types here
        // Example: MS Office documents, text files, etc.
        // Adjust the MIME types or file extensions as needed
        // else if (
        //   file.type === "application/msword" ||
        //   file.type ===
        //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        // ) {
        //   return (
        //     <iframe
        //       src={URL.createObjectURL(file)}
        //       title={file.name}
        //       width="100%"
        //       height="600px"
        //     />
        //   );
        // }
        // Add more cases as needed for other document types

        default:
          return <p>File type not supported or recognized</p>;
      }
    }
  };

  return <div className="file-viewer">{renderFile()}</div>;
};

export default FileViewer;
