import React, { useEffect, useState } from "react";
import { createProjectStatusGroup, createStatusGroupUser, getProjectStatusList } from "../../../redux/slices/projectSlice";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AutoCompleteGroupNew from "../../../fields/autoComplete/AutoCompleteGroupNew";
import MovingIcon from "@mui/icons-material/Moving";
import Loading from "../../../components/loading/Loading";
const DefaultAssigne = () => {
    const { id } = useParams();
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );

  const [groupSelected, setGroupSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const createData = useSelector((state) => state.form.createData);

  const statusLoading = useSelector((state) => state.project.statusLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadStatusList = () => {
    dispatch(
      getProjectStatusList({ url: `crm/org/${id}/proj/status`, params: {} })
    );
  };
  useEffect(() => {
    loadStatusList();
  }, []);

  useEffect(() => {
    if (createData?.createSuccess) {
      loadStatusList();
    }
  }, [createData]);
  return (
    <div className="org_list_wrapper">
      {projectStatusList.length > 0 ? (
        !statusLoading &&
        projectStatusList?.map((status) => (
          <div key={status.id} className="light_bg_border">
            <div className="d_flex justify_content_between w_100">
              <div className="w_100">
                <div className="d_flex align_items_center">
                  <MovingIcon
                    style={{ color: "#189d3d" }}
                    className="org_icon"
                  />
                  <div>
                    <h4 className="my_0">{status?.displayName?status.displayName:status.name}</h4>
                  </div>
                </div>
                <div className="d_flex align_items_center  w_100">
                  <AutoCompleteGroupNew
                    view="status"
                    name="group"
                    type="group"
                    // label="Group"
                    userLabel="Add Default Assignee"
                    onGroupChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setGroupSelected(null);
                      }
                      const groupData = [
                        {
                          status: {
                            id: status?.id,
                            name: status?.name,
                          },
                          groups: {
                            id: newValue?.id,
                            name: newValue?.name,
                          },
                        },
                      ];

                      dispatch(
                        createProjectStatusGroup({
                          url: `/crm/org/${id}/groups/map/status`,
                          data: groupData,
                          params: {},
                        })
                      );
                    }}
                    onUserChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setUserSelected(null);
                      }
                      //   setUserSelected(newValue);
                      const userData = [
                        {
                          group: {
                            id: status?.groupId,
                            name: status?.groupName,
                          },
                          user: {
                            id: newValue?.id,
                            name: newValue?.name,
                          },
                        },
                      ];
                      dispatch(
                        createStatusGroupUser({
                          url: `/crm/org/${id}/groups/map/user`,
                          data: userData,
                          params: {},
                        })
                      );
                    }}
                    // onChange={(value, type) =>
                    //   handleGroup(value, type, status)
                    // }
                    value={
                      status?.groupId && !status?.userId
                        ? {
                            userId: null,
                            name: null,
                            // uid: userSelected?.uid,
                            groupId: status?.groupId ?? null,
                            groupName: status?.groupName ?? null,
                          }
                        : {
                            userId: status?.userId ?? null,
                            name: status?.userName ?? null,
                            // uid: userSelected?.uid,
                            groupId: status?.groupId ?? null,
                            groupName: status?.groupName ?? null,
                          }
                    }
                    groupWidth="40%"
                    userWidth="40%"
                  />
                </div>
                {/* <p>{grp.description}</p> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        // <p className="text_center">No groups found</p>
        <Loading/>
      )}
    </div>
  );
};

export default DefaultAssigne;
