import { createSlice } from "@reduxjs/toolkit";

const checklistSlice = createSlice({
  name: "checklist",
  initialState: {
    checklistItem: [],
    loading: false,
  },
  reducers: {
    getChecklist(state) {
      state.loading = true;
      return state;
    },

    createChecklist(state) {
      state.loading = true;
      return state;
    },

    setChecklist: (state, action) => {
      state.checklistItem = action.payload;
      state.loading = false;
    },
    setChecklistLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  getChecklist,
  setChecklist,
  setChecklistLoadingFalse,
  createChecklist,
} = checklistSlice.actions;

export default checklistSlice.reducer;
