import * as React from "react";
import Button from "../../buttons/Button";
import CardTitle from "../../dashboard/CardTitle";
import UserDetails from "../../userdetails/UserDetails";
import AvatarIcon from "../../../assets/images/dealer.jpeg";
import CallIcon from "../../../assets/images/callgreen.svg";
import MailIcon from "../../../assets/images/mailgreen.svg";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tabs,
  Typography,
} from "@mui/material";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
} from "../../constants/Constants";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { stringAvatar } from "../../../utils/util";
import BasicModal from "../../modal/Modal";
import MultipleAssignee from "./multipleassignee/MultipleAssignee";
import { modalStyle } from "../../constants/ConstData";
import SearchBar from "../../searchbar/SearchBar";
import CloseIcon from "../../../assets/images/close";
import Storage from "../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getProjectStatus,
  updateProjectStatus,
} from "../../../redux/slices/additionalInfoSlice";
import Loading from "../../loading/Loading";
// import { updateProjectStatus } from "../../../redux/slices/projectSlice";

const AssignedToCard = ({ assigneelist, assignedto }) => {
  const [value, setValue] = React.useState(0);
  const projectData = useSelector((state) => state.project.projectData);
  const searchValue = useSelector((state) => state.search.searchValue);
  const projectStatusLoading = useSelector(
    (state) => state.additionalInfo.projectStatusLoading
  );

  const projectStatus = useSelector(
    (state) => state.additionalInfo.projectStatus
  );

  const snackbarData = useSelector((state) => state.form.snackbarData);
  const addInfoloading = useSelector((state) => state.additionalInfo.loading);
  const [anchorElUsers, setAnchorElUsers] = React.useState(null);
  const [anchorElGroups, setAnchorElGroups] = React.useState(null);
  const [usersList, setUsersList] = React.useState([]);
  const [groupList, setGroupList] = React.useState([]);
  const [isViewOpen, setIsViewOpen] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);

  const [searchUserList, setSearchUserList] = React.useState([]);
  const [searchGroupList, setSearchGroupList] = React.useState([]);
  const [type, setType] = React.useState("");

  const [statusData, setStatusData] = React.useState({});
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  // const open = Boolean(anchorElUser);
  // const id = open ? "simple-popover" : undefined;

  // const groupOpen = Boolean(anchorElGroup);
  // const groupId = groupOpen ? "simple-popover" : undefined;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUsersPopoverOpen = (event, users) => {
    setUsersList(users);
    setSearchUserList(users);
    setAnchorElUsers(event.currentTarget);
  };

  const handleGroupsPopoverOpen = (event, groups) => {
    setGroupList(groups);
    setSearchGroupList(groups);
    setAnchorElGroups(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElUsers(null);
    setAnchorElGroups(null);
    setUsersList([]);
    setSearchUserList([]);

    setGroupList([]);
    setSearchGroupList([]);
  };

  const addAssignee = (item, typevalue) => {
    setStatusData({
      prevStatusId: item?.statusId,
      stage: { id: item?.stageId, name: item?.stageName },
      status: { id: item?.statusId, name: item?.statusName },
      exList:
        typevalue === "user"
          ? item?.groupList.length > 0
            ? item?.groupList?.map((grp) => grp?.id)
            : [0]
          : item?.userList.length > 0
          ? item?.userList?.map((usr) => usr?.userId)
          : [0],
    });

    setType(typevalue);
    if (typevalue === "user") {
      setSelectedItems(item?.userList);
      setIsViewOpen(true);
    } else {
      setSelectedItems(item?.groupList);
      setIsViewOpen(true);
    }
  };

  const handleClose = () => {
    setIsViewOpen(false);
  };

  const multiAssigneeChange = (event, item) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const filteredStatus = selectedItems?.filter((fts) =>
        type === "user" ? fts?.userId !== item?.userId : fts.id !== item?.id
      );
      setSelectedItems(filteredStatus);
    }

    // setState({
    //   ...state,
    //   [event.target.name]: event.target.checked,
    // });
  };

  const saveMultipleStatus = () => {
    let data;
    if (type === "user") {
      data = [
        {
          customerInfo: projectData?.customerInfo,
          address: projectData?.address,
          prevStatusId: 0,
          stage: statusData?.stage,
          status: statusData?.status,
          assignedUser: selectedItems?.map((item) => item?.userId),
          groupId: statusData?.exList,
        },
      ];
    } else {
      data = [
        {
          customerInfo: projectData?.customerInfo,
          address: projectData?.address,
          prevStatusId: 0,
          stage: statusData?.stage,
          status: statusData?.status,
          groupId: selectedItems?.map((item) => item?.id),
          assignedUser: statusData?.exList,
        },
      ];
    }
    dispatch(
      updateProjectStatus({
        url: `crm/org/${orgId}/proj/${id}/status`,
        data: data,
        params: { data: "status" },
      })
    );
  };

  React.useEffect(() => {
    if (searchValue !== "") {
      const newUserList = usersList?.filter(
        (entry) => entry.name.toLowerCase().includes(searchValue)

        // Object.values(entry).some(
        //   (val) =>
        //     typeof val === "string" &&
        //     val.toLowerCase().includes(searchValue.toLowerCase())
        // )
      );
      setSearchUserList(newUserList);
      const newGroupList = groupList?.filter(
        (entry) => entry.name.toLowerCase().includes(searchValue)

        // Object.values(entry).some(
        //   (val) =>
        //     typeof val === "string" &&
        //     val.toLowerCase().includes(searchValue.toLowerCase())
        // )
      );
      setSearchGroupList(newGroupList);
    } else {
      setSearchUserList(usersList);
      setSearchGroupList(groupList);
    }
  }, [searchValue]);

  const openUsers = Boolean(anchorElUsers);
  const openGroups = Boolean(anchorElGroups);
  const popoverIdUsers = openUsers ? "users-popover" : undefined;
  const popoverIdGroups = openGroups ? "groups-popover" : undefined;

  const loadAdditionalInfo = () => {
    dispatch(
      getProjectStatus({
        url: `/crm/org/${orgId}/proj/${id}/info?data=status`,
        params: {},
      })
    );
  };

  React.useEffect(() => {
    if (
      snackbarData?.createSuccess &&
      snackbarData?.params?.data === "status"
    ) {
      console.log("params=----------->", snackbarData?.params);
      handleClose();
      loadAdditionalInfo();
      // onClose();
    }
  }, [snackbarData]);

  if (projectStatusLoading || addInfoloading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      {/* <CardTitle title="Assigned To" type="project_view">
        <Button className="dot_button">
          <div className="dot_kebab"></div>
        </Button>
      </CardTitle> */}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          // textColor="secondary"
          // indicatorColor="secondary"

          TabIndicatorProps={{
            style: {
              backgroundColor: "#189d3d",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab
            icon={<AssignmentTurnedInIcon />}
            iconPosition="start"
            label="Assigned To"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(0)}
          />
          <StyledTab
            icon={<AssignmentReturnIcon />}
            iconPosition="start"
            label="Previously Assigned To"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <div className="card_content">
        <CustomTabPanel value={value} index={0}>
          <div style={{ position: "relative" }}>
            {projectStatus?.map((item, index) => (
              <div className="marginB10" key={index}>
                <div className="d_flex align_items_center justify_content_between">
                  <h4 className="m_0">{item?.statusName}</h4>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <h5 className="mb_0">Users</h5>
                    <div
                      // onClick={(e) => showAllAssignee(e, item?.users)}
                      // onMouseOut={hideAllAssignee}
                      className="d_flex align_items_center pointer"
                      style={{ position: "relative" }}
                    >
                      {item?.userList?.slice(0, 3).map((user, id) => (
                        <div key={id}>
                          <IconButton sx={{ p: 0, marginRight: "" }}>
                            <Avatar
                              style={{
                                width: "20px",
                                height: "20px",
                                fontSize: "10px",
                              }}
                              onClick={(event) =>
                                handleUsersPopoverOpen(event, item?.userList)
                              }
                              // onMouseLeave={handlePopoverClose}
                              {...stringAvatar(user?.name)}
                            />
                          </IconButton>
                          {/* <Typography textAlign="center">{item?.name}</Typography> */}
                        </div>
                      ))}
                      {item?.userList?.length > 3 && (
                        <p
                          onClick={(event) =>
                            handleUsersPopoverOpen(event, item?.userList)
                          }
                          // onMouseLeave={handlePopoverClose}
                          style={{ margin: 0, marginLeft: "5px" }}
                        >
                          <small>
                            <b>+{item?.users?.length - 3} more</b>{" "}
                          </small>
                        </p>
                      )}
                    </div>

                    <Button
                      onClick={() => addAssignee(item, "user")}
                      className="icon_button d_flex align_items_center primary_bg text_white marginT10"
                    >
                      {/* <AddIcon className="btn_icon" /> */}
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <h5 className="mb_0">Groups</h5>
                    <div
                      // onMouseOver={(e) => showAllGroups(e, item?.groups)}
                      // onMouseOut={hideAllGroups}
                      className="d_flex align_items_center pointer"
                      style={{ position: "relative" }}
                    >
                      {item?.groupList?.slice(0, 3).map((group, index) => (
                        <div key={index}>
                          <IconButton sx={{ p: 0, marginRight: "" }}>
                            <Avatar
                              style={{
                                width: "20px",
                                height: "20px",
                                fontSize: "10px",
                              }}
                              onClick={(event) =>
                                handleGroupsPopoverOpen(event, item?.groupList)
                              }
                              // onMouseLeave={handlePopoverClose}
                              {...stringAvatar(group?.name)}
                            />
                          </IconButton>
                          {/* <Typography textAlign="center">{item?.name}</Typography> */}
                        </div>
                      ))}
                      {item?.groups?.length > 3 && (
                        <p
                          onClick={(event) =>
                            handleGroupsPopoverOpen(event, item?.groupList)
                          }
                          // onMouseLeave={handlePopoverClose}
                          style={{ marginLeft: "5px" }}
                        >
                          <small>
                            <b>+{item?.groupList?.length - 3} more</b>{" "}
                          </small>
                        </p>
                      )}
                    </div>
                    <Button
                      onClick={() => addAssignee(item, "group")}
                      className="icon_button d_flex align_items_center primary_bg text_white marginT10"
                    >
                      {/* <AddIcon className="btn_icon" /> */}
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ))}

            <Popover
              id={popoverIdUsers}
              open={openUsers}
              anchorEl={anchorElUsers}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ height: "300px" }}
            >
              <div className="d_flex align_items_center justify_content_between marginT10">
                <SearchBar />
                <IconButton onClick={handlePopoverClose}>
                  <CloseIcon />
                </IconButton>
              </div>

              {searchUserList?.map((usr, i) => (
                <div className="d_flex align_items_center p_10" key={i}>
                  <IconButton sx={{ p: 0, marginRight: "8px" }}>
                    <Avatar
                      style={{ width: "30px", height: "30px" }}
                      {...stringAvatar(usr?.name)}
                    />
                  </IconButton>
                  <Typography textAlign="center">{usr?.name}</Typography>
                </div>
              ))}
            </Popover>
            <Popover
              id={popoverIdGroups}
              open={openGroups}
              anchorEl={anchorElGroups}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ height: "300px" }}
            >
              <div className="d_flex align_items_center justify_content_between marginT10">
                <SearchBar />
                <IconButton onClick={handlePopoverClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              {searchGroupList?.map((grp, index) => (
                <div className="d_flex align_items_center p_10" key={index}>
                  <IconButton sx={{ p: 0, marginRight: "8px" }}>
                    <Avatar
                      style={{ width: "30px", height: "30px" }}
                      {...stringAvatar(grp?.name)}
                    />
                  </IconButton>
                  <Typography textAlign="center">{grp?.name}</Typography>
                </div>
              ))}
            </Popover>
            {/* {assignedto && (
            <div className="d_flex align_items_center justify_content_between w_100 marginT10">
              <UserDetails
                image={AvatarIcon}
                name={assignedto.name}
                email={assignedto.email}
              />
            </div>
          )} */}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {assigneelist && assigneelist?.length > 0 ? (
            assigneelist?.map((item, index) => (
              <div
                key={index}
                className="d_flex align_items_center justify_content_between w_100 marginT10"
              >
                <UserDetails
                  image={AvatarIcon}
                  name={item.name}
                  email={item.email}
                />
                {/* <div>
                  <IconButton>
                    <img src={CallIcon} alt="Call" />
                  </IconButton>
                  <IconButton>
                    <img src={MailIcon} alt="Mail" />
                  </IconButton>
                </div> */}
              </div>
            ))
          ) : (
            <p>Nobody assigned previously</p>
          )}
        </CustomTabPanel>
      </div>
      <BasicModal
        isOpen={isViewOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "50%", md: "40%", lg: "30%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "420px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <MultipleAssignee
          type={type}
          selected={selectedItems}
          onClose={handleClose}
          onChange={multiAssigneeChange}
          onSave={saveMultipleStatus}
        />
      </BasicModal>
    </div>
  );
};

export default AssignedToCard;
