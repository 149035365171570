import * as React from "react";
import CardTitle from "../../dashboard/CardTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CustomerContact from "../../customer/CustomerContact";
import Button from "../../buttons/Button";
import { Badge } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getSunlightLogin } from "../../../redux/slices/authSlice";
import { useDispatch } from "react-redux";

export default function CustomerDetails({
  pContact,
  sContact,
  onChat,
  unreadCount,
  onSendPortal,
}) {
  const [count, setCount] = React.useState(unreadCount ?? 0);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setCount(unreadCount);
  }, [unreadCount]);

  // const onPreQualify = () => {
  //   // const requestOptions = {
  //   //   method: "POST",
  //   //   headers: { "Content-Type": "application/json" },
  //   //   body: JSON.stringify({
  //   //     username: "energyscaperenewables@sunlightfinancial-WSHA3Z.N29SCE",
  //   //     password: "Test123!",
  //   //     // apiKey: "8dd76219-78b9-471d-926d-ad546571786c",
  //   //     // projects: [
  //   //     //   {
  //   //     //     // apr: 3.99,
  //   //     //     // term: 120,
  //   //     //     // isACH: true,
  //   //     //     productType: "Solar",
  //   //     //     installStreet: "South 9th Street",
  //   //     //     installCity: "Philadelphia",
  //   //     //     installStateName: "Pennsylvania",
  //   //     //     installZipCode: "19147",
  //   //     //     applicants: [
  //   //     //       {
  //   //     //         isPrimary: true,
  //   //     //         firstName: "Siraj",
  //   //     //         // middleInitial: "E",
  //   //     //         lastName: "KP",
  //   //     //         phone: "9946860250",
  //   //     //         // otherPhone: "8013131313",
  //   //     //         email: "ksiraj@energyscaperenewables.com",
  //   //     //         // mailingStreet: "4384 Murnin Way",
  //   //     //         // mailingCity: "Park City",
  //   //     //         // mailingStateName: "Utah",
  //   //     //         // mailingZipCode: "84098",
  //   //     //         // residenceStreet: "532 Westwood Road",
  //   //     //         // residenceCity: "Park City",
  //   //     //         // residenceStateName: "Utah",
  //   //     //         // residenceZipCode: "84098",
  //   //     //         // dateOfBirth: "1950-01-01",
  //   //     //         // ssn: "999999990",
  //   //     //         // annualIncome: 10001,
  //   //     //         // employerName: "Test",
  //   //     //         // jobTitle: "Test",
  //   //     //         // employmentYears: "5",
  //   //     //         // employmentMonths: "4",
  //   //     //       },
  //   //     //     ],
  //   //     //   },
  //   //     // ],
  //   //   }),
  //   // };
  //   // fetch(
  //   //   "https://test.connect.boomi.com/ws/rest/v1/pt/gettoken/accesstoken",
  //   //   requestOptions
  //   // )
  //   //   .then((response) => response.json())
  //   //   .then((data) => console.log("Data---------------->", data));
  //   const data = {
  //     apiKey: "8dd76219-78b9-471d-926d-ad546571786c",
  //   };
  //   dispatch(
  //     getSunlightLogin({
  //       url: `https://test.connect.boomi.com/ws/rest/v1/pt/gettoken/accesstoken`,
  //       data: data,
  //       params: {},
  //     })
  //   );
  // };

  return (
    <div className="card_container">
      <CardTitle title="Customer Details" type="project_view">
        {/* <Button className="icon_button marginL10 d_flex align_items_center primary_bg text_white">
          <EditIcon className="btn_icon" /> Edit
        </Button> */}
      </CardTitle>
      <div className="card_content">
        {pContact && (
          <CustomerContact
            title="Primary Contact"
            name={pContact?.firstName + " " + pContact?.lastName}
            email={pContact?.email}
            phone={pContact?.mobile}
          />
        )}

        {sContact?.firstName && (
          <CustomerContact
            title="Secondary Contact"
            name={sContact?.firstName + " " + sContact?.lastName}
            email={sContact?.email}
            phone={sContact?.mobile}
          />
        )}

        <div className="d_flex align_items_center">
          <Badge
            badgeContent={count}
            color="secondary"
            invisible={count > 0 ? false : true}
          >
            <Button
              onClick={onChat}
              className="icon_button marginL10 d_flex align_items_center bg_black text_white"
            >
              <ChatBubbleOutlineIcon className="btn_icon" />
              Chat
              {/* {unreadCount > 0 && <small className="mb_0">{unreadCount}</small>} */}
            </Button>
          </Badge>

          <Button
            onClick={onSendPortal}
            className="icon_button marginL10 d_flex align_items_center primary_bg text_white"
          >
            <SendIcon className="btn_icon" /> Send Portal
          </Button>
        </div>
        {/* <Button
          onClick={onPreQualify}
          className="outline_button_small p_5 w_100 d_flex align_items_center"
          leftIcon={<CheckCircleOutlineIcon />}
        >
          Pre Qualification
        </Button> */}
      </div>
    </div>
  );
}
