import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomBreadcrumb from "../../components/breadcrumb/CustomBreadcrumb";
import { Link, Outlet } from "react-router-dom";
import AdminSidbar from "./AdminSidbar";
import AdminSidebarMobile from "./AdminSidebarMobile";
const breadcrumbs = [
  <Link key="1" to="/org">
    Organization
  </Link>,
  <Typography key="2" color="text.primary">
    Organization View
  </Typography>,
];
const AdminPanelLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(true);
  };

  const closeNav = () => {
    setIsOpen(false);
  };
  return (
    <div className="organisation_view">
      <div style={{ display: "flex", gap: "10px" }}>
      <div>
        <span className="menu_icon" onClick={openNav}>&#9776;</span>
      </div>
      <div>
      <CustomBreadcrumb breadcrumbs={breadcrumbs} />{" "}

      </div>
      <AdminSidebarMobile open={isOpen} close={closeNav}/>
        
      </div>

      <Grid sx={{ marginTop: "16px" }} container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <div className="admin_sidebar_wrapper">
            <AdminSidbar />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminPanelLayout;
