import ModalBody from "../../../modal/ModalBody";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalFooter from "../../../modal/ModalFooter";
import TextInput from "../../../../fields/text/TextInput";
import PhoneInput from "react-phone-input-2";
import Grid2 from "@mui/material/Unstable_Grid2";
import { getOrgUserList } from "../../../../redux/slices/orgSlice";
import { useDispatch, useSelector } from "react-redux";
import Storage from "../../../../utils/Storage";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../buttons/Button";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import {
  createPermitDetails,
  getPermitStatus,
} from "../../../../redux/slices/additionalInfoSlice";
import { displayName } from "react-quill";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PermittingForm = ({ permittingDetails, onClose }) => {
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const { t } = useTranslation();

  const { id } = useParams();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    console.log("data=--------->", data);
    const editedData = {
      permitdtl: [data],
    };

    dispatch(
      createPermitDetails({
        url: `/crm/org/${orgId}/proj/${id}/info`,
        data: editedData,
        params: { data: "permitdtl" },
      })
    );
  };

  useEffect(() => {
    if (permittingDetails?.permittingNotes) {
      setValue("permittingNotes", permittingDetails?.permittingNotes);
    }

    if (permittingDetails?.buildingPermit) {
      setValue("buildingPermit", permittingDetails?.buildingPermit);
    }
    if (permittingDetails?.electricalPermit) {
      setValue("electricalPermit", permittingDetails?.electricalPermit);
    }
    if (permittingDetails?.zoningPermit) {
      setValue("zoningPermit", permittingDetails?.zoningPermit);
    }
    if (permittingDetails?.otherPermit) {
      setValue("otherPermit", permittingDetails?.otherPermit);
    }
    if (permittingDetails?.buildingPermitCost) {
      setValue("buildingPermitCost", permittingDetails?.buildingPermitCost);
    }
    if (permittingDetails?.electricalPermitCost) {
      setValue("electricalPermitCost", permittingDetails?.electricalPermitCost);
    }
    if (permittingDetails?.zoningPermitCost) {
      setValue("zoningPermitCost", permittingDetails?.zoningPermitCost);
    }
    if (permittingDetails?.otherPermitCost) {
      setValue("otherPermitCost", permittingDetails?.otherPermitCost);
    }
  }, [permittingDetails]);

  return (
    <>
      <ModalHead title={t("Permitting")}>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          <Grid2 container spacing={1}>
            <Grid2 item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name={`permittingNotes`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    id="outlined-multiline-static"
                    variant="mui"
                    name="permittingNotes"
                    label={t("Permitting Notes")}
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                    multiline={true}
                    rows={4}
                  />
                )}
              />
            </Grid2>
          </Grid2>

          <label className="text_medium_bold">Permit Numbers</label>
          <Grid2 container spacing={1}>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`buildingPermit`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="buildingPermit"
                    label="Building Permit Number"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`electricalPermit`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="electricalPermit"
                    label="Electrical Permit Number"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`zoningPermit`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="zoningPermit"
                    label="Zoning Permit Number"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`otherPermit`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="otherPermit"
                    label="Other Permit Number"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
          </Grid2>
          <label className="text_medium_bold">Permit Costs</label>
          <Grid2 container spacing={1}>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`buildingPermitCost`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="buildingPermitCost"
                    label={t("Building Permit Cost")}
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`electricalPermitCost`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="electricalPermitCost"
                    label={t("Electrical Permit Cost")}
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`zoningPermitCost`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="zoningPermitCost"
                    label={t("Zoning Permit Cost")}
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
            <Grid2 item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`otherPermitCost`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    variant="mui"
                    name="otherPermitCost"
                    label={t("Other Permit Cost")}
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            {t("Save")}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default PermittingForm;
