import Button from "../buttons/Button";
import AvatarIcon from "../../assets/images/dealer.jpeg";
import CardTitle from "./CardTitle";
import UserDetails from "../userdetails/UserDetails";

const dealersList = [
  {
    name: "Michael Jordan",
    email: "michael.jordan@example.com",
    amount: "_ _ _",
    image: AvatarIcon,
  },
  {
    name: "Emigo Kiaren",
    email: "emigo.kiaren@gmail.com",
    amount: "_ _ _",
    image: AvatarIcon,
  },
  {
    name: "Randy Origoan",
    email: "randy.origoan@gmail.com",
    amount: "_ _ _",
    image: AvatarIcon,
  },
  {
    name: "George Pieterson",
    email: "george.pieterson@gmail.com",
    amount: "_ _ _",
    image: AvatarIcon,
  },
  {
    name: "Kiara Advain",
    email: "kiaraadvain214@gmail.com",
    amount: "_ _ _",
    image: AvatarIcon,
  },
];

const DealersCard = () => {
  return (
    <div className="card_container">
      <CardTitle title="Top Dealers">
        <Button className="dot_button">
          <div className="dot_kebab"></div>
        </Button>
      </CardTitle>

      <div className="card_content">
        {dealersList.map((item, index) => (
          <div
            key={index}
            className="d_flex align_items_center justify_content_between w_100"
          >
            <UserDetails
              image={item.image}
              name={item.name}
              email={item.email}
            />
            <p className="dealer_amount">{item.amount}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealersCard;
