import React, { useState } from "react";
import "./AdminSidebarMobile.scss";
import { IconButton } from "@mui/material";
import CloseIcon from "../../assets/images/close";
import { useLocation, useParams } from "react-router-dom";
const AdminSidebarMobile = ({ open, close }) => {
  const [subMenuOpen, setSubMenuOpen] = useState('users'); // State for managing submenus
  const [activeLink, setActiveLink] = useState(null); 
  const {id}=useParams();
  const location = useLocation();
  const handleLinkClick = (link) => {
    console.log('link ',link);

    // If the clicked link is the currently open submenu, close it; otherwise, open the new one
    setSubMenuOpen((prev) => (prev === link ? null : link));
    setActiveLink(link);
  };

  return (
    <div>
      <div id="mySidenav" className={`sidenav ${open ? "open" : ""}`}>
        {/* <a href="javascript:void(0)" className="closebtn" onClick={()=>{
        close();
      }}>&times;</a> */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              close();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        
        <a  href={ `/org/${id}/users`} className={ location.pathname.split("/")[3] === "users"||location.pathname.split("/")[3] === "moderators"||location.pathname.split("/")[3] === "invites"  ?'sidenav_active':''} onClick={() => handleLinkClick('users')}>Users</a>
        { (location.pathname.split("/")[3] === "users"||location.pathname.split("/")[3] === "moderators"||location.pathname.split("/")[3] === "invites" ||location.pathname.split("/")[3] === "roles") && (
          <div className="submenu">
            <a href={ `/org/${id}/users`}className={ location.pathname.split("/")[3] === "users" ?'submenu_active':''}>users</a>
            <a href={ `/org/${id}/moderators`} className={ location.pathname.split("/")[3] === "moderators" ?'submenu_active':''}>Moderators</a>
            <a href={ `/org/${id}/invites`}  className={ location.pathname.split("/")[3] === "invites" ?'submenu_active':''}>Invites</a>
            <a href={ `/org/${id}/roles`}  className={ location.pathname.split("/")[3] === "roles" ?'submenu_active':''}>Roles</a>
          </div>
        )}
        <a href={ `/org/${id}/groups`} className={ location.pathname.split("/")[3] === "groups" ?'sidenav_active':''} onClick={() => handleLinkClick('groups')}>Groups</a>
        <a href={ `/org/${id}/projects`} className={ location.pathname.split("/")[3] === "projects" ?'sidenav_active':''} onClick={() => handleLinkClick('projects')}>Projects</a>
        <a href={ `/org/${id}/leads`} className={ location.pathname.split("/")[3] === "leads" ?'sidenav_active':''}  onClick={() => handleLinkClick('leads')}>Leads</a>
        <a href={ `/org/${id}/workflow`} className={ location.pathname.split("/")[3] === "workflow" ?'sidenav_active':''} >Work Flow</a>
        {
          location.pathname.split('/')[3]==='workflow' && (
            <div className="submenu">
            <a href={ `/org/${id}/workflow/multiple_assigne`}className={ location.pathname.split("/")[4] === "multiple_assigne" ?'submenu_active':''}>Multiple Assignee</a>
              
            </div>
          )
        }
        <a href={ `/org/${id}/settings`} className={ location.pathname.split("/")[3] === "settings" ?'sidenav_active':''}>Settings</a>
        {
          location.pathname.split('/')[3]==='settings' && (
            <div className="submenu">
            <a href={ `/org/${id}/settings`}className={ location.pathname.split("/")[3] === "settings" ?'submenu_active':''}>Edit Organisation</a>
              
            </div>
          )
        }
      </div>
    </div>
  );
};

export default AdminSidebarMobile;
