import React from "react";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import { Grid, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/images/close";
import Button from "../../../components/buttons/Button";
import { createNewMaterials } from "../../../redux/slices/materialSlice";
import { useDispatch } from "react-redux";

const AddMaterialsModal = ({ open, close, orgId }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
    // defaultValues: {
    //   materials: [
    //     {
    //       name: "",
    //       base_price: 0,
    //       description: "",
    //       baseuom: "",
    //       //   addSource: newData?.addSource ?? "",
    //     },
    //   ],
    // },
  });
  const dispatch = useDispatch();
  const addMaterail = (data) => {
    const newData = {
      materials: [{ ...data, isactive: 1 }],
    };

    dispatch(
      createNewMaterials({
        url: `/crm/org/${orgId}/bom/store`,
        data: newData,
        params: {},
      })
    );
    close();
  };
  return (
    <>
      <BasicModal
        isOpen={open}
        onClose={close}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        {/* <LeadForm
          // loadLeads={loadLeads}
          onClose={() => setOpenInviteUserModal(false)}
        /> */}
        <>
          <div className="modal_header">
            <p className="modal_title ">Add Materials</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {/* <form
            className="project_form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          > */}
          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(addMaterail)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {/* <label>Team Member</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        {...register("email", {
                          required: true,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      /> */}
                      <label className="text_medium">Material Name</label>

                      <input
                        type="text"
                        name="name"
                        {...register(`name`, {
                          required: true,
                        })}
                      />
                      <p className="error_message">{errors.name?.message}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {/* <label>Group</label>
                      <CustomAutoComplete
                        name="groupId"
                        className="user_access_level"
                        open={openDropDown}
                        onOpen={() => {
                          setOpenDropDown(true);
                        }}
                        onClose={() => {
                          setOpenDropDown(false);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value.id
                        }
                        // loading={loading}
                        options={group}
                        onChange={(event, newValue) => {
                          setGroupSelected(newValue.id);
                        }}
                        register={register}
                        renderOption={(props, option) => (
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option.name}
                          </Box>
                        )}
                        value={groupSelected}
                        getOptionLabel={(option) => {
                          if (option.name) {
                            return option?.name;
                          } else {
                            return "Please Select";
                          }
                        }}
                        noOptionsText={
                          <div className="d_flex flex_column align_items_center justify_content_center">
                            <p>No Data Found</p>
                            <Button
                              className="primary_button_small"
                              onClick={(e) => {
                                e.preventDefault();
                                // navigate(`/org/${orgId}/group/create`);
                              }}
                            >
                              Create New
                            </Button>
                          </div>
                        }
                        // label="Access level"
                        // {...register("access_level", {
                        //   required: true,
                        // })}
                      /> */}
                      <label className="text_medium">Base Price</label>

                      <input
                        type="text"
                        name="base_price"
                        {...register(`base_price`, {
                          required: true,
                        })}
                      />
                      <p className="error_message">
                        {errors.base_price?.message}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <label className="text_medium">Unit</label>

                      <input
                        type="text"
                        name="baseuom"
                        {...register(`baseuom`, {
                          required: true,
                        })}
                      />
                      <p className="error_message">{errors.baseuom?.message}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <label className="text_medium">Description</label>
                      <textarea
                        type="text"
                        name="description"
                        rows={1}
                        {...register(`description`, {
                          //   required: true,
                        })}
                      />
                      <p className="error_message">
                        {errors.description?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    Add Material
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </>
  );
};

export default AddMaterialsModal;
