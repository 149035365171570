import { Grid, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";
import DataTable from "../../../components/table/DataTable";
import ToolBar from "../../../components/toolbar/ToolBar";
import PlusIcon from "../../../assets/images/plus";
import CloseIcon from "../../../assets/images/close";
import { useDispatch, useSelector } from "react-redux";
import Storage from "../../../utils/Storage";
import { createNewGroup, getGroupList } from "../../../redux/slices/orgSlice";
import { useNavigate } from "react-router-dom";
const columns = [
  {
    field: "id",
    headerName: "Group Id",
    width: 80,
  },
  {
    field: "mame",
    headerName: "Group Name",
    width: 250,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },

  {
    field: "description",
    headerName: "Description",
    width: 300,
    renderCell: (params) => <Typography>{params?.row?.desc}</Typography>,
  },
];
const GroupListRoot = () => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const loading = useSelector((state) => state.lead.loading);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currentOrganization = Storage.read(`org${userInfo?.id}`);
  const group = useSelector((state) => state.org.group);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    data.orgId = currentOrganization.id;
    dispatch(
      createNewGroup({ url: "crm/user/groups", data: data, params: {} })
    );
  };

  useEffect(() => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${currentOrganization.id}`,
        params: {},
      })
    );
  }, []);
  return (
    <div style={{ padding: "25px" }} className="leads">
      <ToolBar title="Groups">
        <div className="d_flex align_items_center">
          <Button
            type="submit"
            className="primary_button d_flex align_items_center"
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18px",
              padding: "5px 20px",
            }}
            leftIcon={<PlusIcon color="#fff" />}
            onClick={() => setOpenAddGroupModal(true)}
          >
            Add Group
          </Button>
        </div>
      </ToolBar>
      <div className="marginT10">
        <DataTable
          rows={[...group].reverse()}
          columns={columns}
          onRowClick={(row) => {
            navigate(`/groups/${row.id}`, {
              state: {
                name: row?.row?.name,
                desc: row?.row?.desc,
              },
            });
          }}
          loading={loading}
        />
      </div>

      <BasicModal
        isOpen={openAddGroupModal}
        onClose={() => setOpenAddGroupModal(false)}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "600px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Add Group</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={() => setOpenAddGroupModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label className="text_medium">Group Name</label>
                      <input
                        type="text"
                        name="name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      <p className="error_message">{errors.name?.message}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label className="text_medium">Description</label>
                      <textarea
                        type="text"
                        name="description"
                        rows={8}
                        {...register("description", {})}
                      />
                      <p className="error_message">
                        {errors.description?.message}
                      </p>

                      {/* <CustomAutoComplete
                          name="groupId"
                          className="user_access_level"
                          open={open}
                          onOpen={() => {
                             setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value.id
                          }
                          loading={loading}
                          options={group}
                          onChange={(event, newValue) => {
                            setGroupSelected(newValue.id);
                          }}
                          register={register}
                          renderOption={(props, option) => (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option.name}
                            </Box>
                          )}
                          value={groupSelected}
                          getOptionLabel={(option) => {
                            if (option.name) {
                              return option?.name;
                            } else {
                              return "Please Select";
                            }
                          }}
                          noOptionsText={
                            <div className="d_flex flex_column align_items_center justify_content_center">
                              <p>No Data Found</p>
                              <Button
                                className="primary_button_small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // navigate(`/org/${orgId}/group/create`);
                                }}
                              >
                                Create New
                              </Button>
                            </div>
                          }
          
                        /> */}
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </div>
  );
};

export default GroupListRoot;
