import { Edit, Height } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CardTitle from "../../../dashboard/CardTitle";
import LabelValue from "../LabelValue";
import { useEffect, useState } from "react";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import SystemDetailsForm from "./SystemDetailsForm";
import { useSelector } from "react-redux";
import Loading from "../../../loading/Loading";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Storage from "../../../../utils/Storage";
import { getSystemInfo } from "../../../../redux/slices/additionalInfoSlice";
import { useTranslation } from "react-i18next";

const SystemDetails = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const systemDetails = useSelector(
    (state) => state.additionalInfo.systemDetails
  );
  const loading = useSelector((state) => state.additionalInfo.loading);
  const systemLoading = useSelector(
    (state) => state.additionalInfo.systemLoading
  );
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  const editSystemDetails = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const loadAdditionalInfo = () => {
    dispatch(
      getSystemInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=systemdtl`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (
      snackbarData?.createSuccess &&
      snackbarData?.params?.data === "systemdtl"
    ) {
      console.log("params=----------->", snackbarData?.params);
      handleClose();
      loadAdditionalInfo();
    }
  }, [snackbarData]);

  if (loading || systemLoading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <CardTitle title={t("System Details")} type="project_view">
        <IconButton
          className="marginL10"
          size="small"
          onClick={editSystemDetails}
        >
          <Edit />
        </IconButton>
      </CardTitle>
      <div className="card_content">
        <div>
          <LabelValue
            className="m_0"
            label="System Size"
            value={
              systemDetails?.systemSize && systemDetails?.systemSize + " kW"
            }
            buttonLabel="Add System Size"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Annual Production kWh"
            value={systemDetails?.productionVolume}
            buttonLabel="Add Production Volume"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Offset"
            value={systemDetails?.offset}
            buttonLabel="Add Offset"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="System Cost"
            value={systemDetails?.systemCost}
            buttonLabel="Add System Cost"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Modules"
            value={systemDetails?.modules}
            buttonLabel="Add Modules"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Inverters"
            value={systemDetails?.inverters}
            buttonLabel="Add Inverters"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Panel Count"
            value={systemDetails?.panelCount}
            buttonLabel="Add Panel Count"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Racking Rails"
            value={systemDetails?.rackingRails}
            buttonLabel="Add Racking Rails"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Racking Attachments"
            value={systemDetails?.rackingAttachments}
            buttonLabel="Add Racking Attachments"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Storage"
            value={systemDetails?.storage}
            buttonLabel="Add Storage"
            onClick={editSystemDetails}
          />
          <LabelValue
            label="Storage Type"
            value={systemDetails?.storageType}
            buttonLabel="Add Storage Type"
            onClick={editSystemDetails}
          />
        </div>
        <BasicModal
          isOpen={isOpen}
          onClose={handleClose}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "500px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <SystemDetailsForm
            // id={id}
            // projectData={projectData}
            systemDetails={systemDetails}
            onClose={handleClose}
          />
        </BasicModal>
      </div>
    </div>
  );
};

export default SystemDetails;
