import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/buttons/Button";
import PlusIcon from "../../assets/images/plus";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { Avatar, IconButton, InputAdornment, TextField } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ThreeDotDropdown from "../../components/dropdown/ThreeDotDropdown";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { getOrgList } from "../../redux/slices/orgSlice";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { setCreateSuccess } from "../../redux/slices/formSlice";
import Storage from "../../utils/Storage";
import { setTitle } from "../../redux/slices/titleSlice";
import { stringAvatar } from "../../utils/util";

const options = [
  { name: "View", value: "view" },
  { name: "Groups", value: "group" },
  { name: "Invite People", value: "invite" },
  { name: "Edit Organization", value: "edit" },
  { name: "Manage Status", value: "status" },
  { name: "Manage Materials", value: "materials" },
  { name: "Transfer Ownership", value: "transfer" },
  { name: "Delete Organization", value: "delete" },
];

const OrganisationList = () => {
  useEffect(() => {
    dispatch(setTitle("Organization"));
  }, []);
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.org.loading);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const loggedUser = useSelector((state) => state.auth.loggedUser);

  const inputRef = useRef(null);
  const tokenString = sessionStorage.getItem("token");
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const isOwner =
    Storage.read(`org${userInfo?.id}`)?.isOwner === "true" ? true : false;

  let userToken;
  if (tokenString) {
    userToken = JSON.parse(tokenString);
  }

  const handleSelect = (value, id, orgName, email) => {
    switch (value) {
      case "view":
        navigate(`/org/${id}/users`, {
          state: {
            orgName,
            email,
          },
        });
        break;
      case "invite":
        navigate(`/org/${id}/user/${value}`);
        break;
      case "edit":
        navigate(`/org/${id}/${value}`);
        break;
      case "status":
        navigate(`/org/${id}/${value}/manage`);
        break;
      case "materials":
        navigate(`/org/${id}/${value}/manage`);
        break;
      case "group":
        navigate(`/org/${id}/${value}`);
        break;
      case "transfer":
        navigate(`/org/${id}/${value}/ownership`);
        break;
      case "delete":
        navigate(`/org/${id}/transfer/ownership`);
        break;
      default:
        navigate("/dashboard");
    }
  };

  const copyToken = () => {
    if (inputRef && inputRef.current) {
      // inputRef.current.select();
      navigator.clipboard.writeText(inputRef.current.value);
      dispatch(
        setCreateSuccess({
          message: "copied to clipboard",
          createSuccess: true,
          statusCode: 201,
        })
      );
      // openSnackbar(i18n.t('key_has_copied_successfull_message'));
    }
  };

  // useEffect(() => {
  //   if (orgInfo?.length === 0) {
  //     dispatch(getOrgList({ url: "crm/user/organization", params: {} }));
  //   }
  // }, [orgInfo]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center justify_content_between marginB10">
          {/* <IconButton
            sx={{
              borderRadius: 0,
              border: "1px solid",
              borderColor: "primary.main",
              borderRadius: "8px",
            }}
            onClick={() => navigate("/projects")}
          >
            <HomeIcon />
            <p className="font_12_dark_gray">Home </p>
          </IconButton> */}
          {/* {isOwner && ( */}
          <Link style={{ textDecoration: "none" }} to="/org/create">
            <Button
              type="submit"
              className="outline_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                padding: "5px 20px",
              }}
              leftIcon={<PlusIcon color="#189d3d" />}
            >
              New Organization
            </Button>
          </Link>
          {/* )} */}
        </div>
        <div className="org_list_wrapper ">
          <div className="org_owner">
            <p className="font_12_dark_gray_600">Account Owner</p>
            <h2 className="font_24_dark">{userDetails.displayName}</h2>
          </div>

          {!loading &&
            orgInfo?.map((org) => (
              <div key={org.id} className="light_bg_border marginT10">
                <div className="d_flex justify_content_between">
                  <div>
                    <div className="d_flex align_items_center">
                      <div
                        className="orgprofile_image_container"
                        // onMouseEnter={() => setIsHovered(true)}
                        // onMouseLeave={() => setIsHovered(false)}
                      >
                        {org?.logodtls ? (
                          <img
                            src={org?.logodtls}
                            alt="Organization"
                            className="orgprofile_image"
                            loading="lazy"
                          />
                        ) : (
                          <Avatar
                            {...stringAvatar(org?.name)}
                            sx={{ bgcolor: "#388e3c" }}
                            className="orgprofile_image"
                          />
                        )}
                      </div>
                      <div className="marginL10">
                        <h4
                          className="my_0"
                          style={{
                            cursor:
                              org?.isOwner === "true" ? "pointer" : "default",
                          }}
                          onClick={() => {
                            if (org?.isOwner === "true")
                              handleSelect("view", org.id, org.name, org.email);
                            // navigate(`/org/${org.id}`)
                          }}
                        >
                          {org.name}
                        </h4>
                        <p className="my_0">
                          <small>{org.email}</small>
                        </p>
                      </div>
                    </div>
                    <p>{org.description}</p>
                    {org?.isOwner === "true" && (
                      <div onClick={copyToken} className="input_icon_wrapper">
                        <input
                          ref={inputRef}
                          className="token_input"
                          type="text"
                          name="name"
                          value={userToken}
                          disabled
                        />
                        <ContentCopyIcon className="copy_icon" />
                      </div>
                    )}
                  </div>
                  {(org?.isOwner === "true" || org?.isModerator === "true") && (
                    <div>
                      <ThreeDotDropdown
                        options={options.filter((item) => {
                          if (
                            org?.isOwner === "true" &&
                            org?.isModerator === "true"
                          ) {
                            return item;
                          } else if (org?.isModerator === "true") {
                            if (item.value === "invite") {
                              return item;
                            }
                          }
                        })}
                        onSelect={(value) =>
                          handleSelect(value, org.id, org.name, org.email)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default OrganisationList;
