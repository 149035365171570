import React, { useEffect, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CurrencyView from "../../../components/currency/CurrencyView";
import { Edit } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPrjectstatusCheckListOrg, getProjectStatusListOrg } from "../../../redux/slices/orgSlice";
import { useSelector } from "react-redux";
import CustomSearch from "../../../components/searchbar/CustomSearch";
import { CHECKLIST_TYPE } from "../../../constants/checkListTypeConstants";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import TextInput from "../../../fields/text/TextInput";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
const CheckListViewNew = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const projectStatusList = useSelector(
    (state) => state.org.projectStatusListOrg
  );
  const checkLists = useSelector(
    (state) => state.org.projectStatusCheckListOrg
  );
  const projectStatusCheckListLoading = useSelector(
    (state) => state.org.projectStatusCheckListLoading
  );
  console.log('checkLists',checkLists);
  const checkListSamples = [
    {
      type: CHECKLIST_TYPE.TEXT,
      label: "Enter the value",
      isMandatory: true,
      description: "Sample description",
    },
    {
      type: CHECKLIST_TYPE.DROPDOWN,
      label: "Select Options",
      isMandatory: false,
      description: "Sample description",
      options: ["option 1", "option 2", "option 3"],
    },
  ];
  const loadStatuses = () => {
    dispatch(
      getProjectStatusListOrg({
        url: `/crm/org/${id}/proj/status`,
        params: {
          orgId: id,
        },
      })
    );
  };
  useEffect(() => {
    loadStatuses();
  }, []);
  const loadCheckList = (status_id) => {
    dispatch(
      getPrjectstatusCheckListOrg({
        url: `/crm/org/${id}/checkList/0/status/${status_id}`,
        params: {
          orgId: id,
          id: 0,
          statusId: status_id,
        },
      })
    );
  };
  return (
    <>
      <CardItem height="810px">
        <div className="card_container">
          {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
          <div style={{ padding: "20px" }}>
            <ToolBar title={"Check List"}>
              <div className="d_flex align_items_center justify_content_between">
                <CustomSearch
                  placeholder={"Search"}
                  onChange={(e) => {
                    let value = e.target.value.trim();
                    if (value == "") {
                      setSearchValue(null);
                    } else {
                      setSearchValue(e.target.value);
                    }
                  }}
                />
              </div>
            </ToolBar>
          </div>
          <div
            style={{
              // padding: "20px",
              overflowY: "scroll",
              maxHeight: "640px",
              padding: "20px",
            }}
          >
            {searchValue ? (
              <>
                {projectStatusList
                  .filter((status) => {
                    return status?.name
                      ?.toLowerCase()
                      ?.includes(searchValue?.toLowerCase());
                  })
                  .map((item, index) => {
                    return (
                      <>
                        <div style={{ marginTop: "10px" }}>
                          <Accordion
                            key={index}
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "4px",
                              background: "rgba(55, 144, 107, 0.08)",
                              border: "none",
                              boxShadow: "none",
                              marginBottom: "10px",
                              ":before": {
                                display: "none",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{
                                    color: "#189d3d",
                                  }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div className="w_100 d_flex align_items_center justify_content_between">
                                <Typography
                                  sx={{
                                    color: "#189d3d",
                                  }}
                                  className="font_12_green_600"
                                >
                                  {item.name}
                                </Typography>
                                

                                {/* <CurrencyView amount={100} /> */}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container></Grid>
                              <div>
                              {checkListSamples.map(
                                (listItem, listItemIndex) => {
                                  return (
                                    <>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={2}
                                          lg={2}
                                          xl={2}
                                        >
                                          <div>
                                            <div>
                                              <h4 style={{ color: "#192f1e" }}>
                                                {listItemIndex + 1}.
                                                {listItem.type}
                                              </h4>
                                            </div>
                                            <div
                                              style={{ paddingLeft: "20px" }}
                                            >
                                              <div
                                                style={{
                                                  color: "#75787d",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                <span>Label :</span>
                                                <span
                                                  style={{
                                                    color: "#75787d",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {listItem.label}
                                                </span>
                                              </div>
                                              {listItem?.options && (
                                                <>
                                                  <div
                                                    style={{
                                                      color: "#75787d",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    <span>Options </span>
                                                    <span>
                                                      <div
                                                        style={{
                                                          paddingLeft: "20px",
                                                        }}
                                                      >
                                                        {listItem?.options.map(
                                                          (option, i) => {
                                                            return (
                                                              <>
                                                                <div
                                                                  key={i}
                                                                  style={{
                                                                    color:
                                                                      "#75787d",
                                                                    fontWeight:
                                                                      "500",
                                                                    marginTop:
                                                                      "2px",
                                                                  }}
                                                                >
                                                                  {option}
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    </span>
                                                  </div>
                                                </>
                                              )}

                                              <div style={{ marginTop: "5px" }}>
                                                {listItem.isMandatory ? (
                                                  <>
                                                    <div>
                                                      <span
                                                        style={{
                                                          color: "#ff6347",
                                                        }}
                                                      >
                                                        Mandatory
                                                      </span>

                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        <FaCheckCircle
                                                          style={{
                                                            color: "#ff6347",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      style={{
                                                        color: "#75787d",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      <span>Not Mandatory</span>
                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        <IoCloseCircleSharp />
                                                      </span>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  color: "#75787d",
                                                  fontWeight: "500",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                <span>
                                                  {listItem.description}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={10}
                                          lg={10}
                                          xl={10}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              height: "100%",
                                            }}
                                          >
                                            {listItem.type ==
                                            CHECKLIST_TYPE.TEXT ? (
                                              <>
                                                <TextInput
                                                  width={"226px"}
                                                  variant="mui"
                                                  // name={item?.name}
                                                  label={listItem.label}
                                                  inputClass="input_field_style"
                                                  fieldSize="small"
                                                  marginBottom="0px"
                                                  // fieldStyle="standard"
                                                  onChange={(e) => {
                                                    console.log(
                                                      "e.target.value=---------->",
                                                      e.target.value
                                                    );
                                                  }}
                                                  // value={item?.value}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <AutoCompleteAsync
                                                  sx={{
                                                    width: "224px",
                                                    backgroundColor: "white",
                                                  }}
                                                  multiple={false}
                                                  // name={ite}
                                                  label={listItem.label}
                                                  open={openDropdown}
                                                  onOpen={() => {
                                                    setOpenDropdown(true);
                                                  }}
                                                  onClose={() => {
                                                    setOpenDropdown(false);
                                                  }}
                                                  // isOptionEqualToValue={(
                                                  //   option,
                                                  //   value
                                                  // ) => option === value}
                                                  options={listItem?.options}
                                                  onChange={(
                                                    event,
                                                    newValue,
                                                    reason
                                                  ) => {
                                                    console.log(
                                                      "newVAlue=---------->",
                                                      newValue
                                                    );
                                                  }}
                                                  renderOption={(
                                                    props,
                                                    option
                                                  ) => (
                                                    <Box
                                                      style={{ fontSize: 13 }}
                                                      {...props}
                                                    >
                                                      {option}
                                                    </Box>
                                                  )}
                                                  getOptionLabel={(option) => {
                                                    if (option) {
                                                      return option;
                                                    } else {
                                                      return "Please Select";
                                                    }
                                                  }}
                                                  value={listItem.options[0]}
                                                  // variant={variant}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </>
                                  );
                                }
                              )}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                {projectStatusList.map((item, index) => {
                  return (
                    <>
                      <div style={{ marginTop: "10px" }}>
                        <Accordion
                          key={index}
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "4px",
                            background: "rgba(55, 144, 107, 0.08)",
                            border: "none",
                            boxShadow: "none",
                            marginBottom: "10px",
                            ":before": {
                              display: "none",
                            },
                          }}
                          onChange={(event, isExpanded) => {
                            console.log(`Accordion ${isExpanded ? "expanded" : "collapsed"}`);
                            if(isExpanded){
                              console.log('status item',item);
                              loadCheckList(item.id)
                            }
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: "#189d3d",
                                }}
                                
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
            
                          >
                            <div className="w_100 d_flex align_items_center justify_content_between">
                              <Typography
                                sx={{
                                  color: "#189d3d",
                                }}
                                className="font_12_green_600"
                              >
                                {item.name}
                              </Typography>
                                
                              {/* <CurrencyView amount={100} /> */}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>
                              {checkListSamples.map(
                                (listItem, listItemIndex) => {
                                  return (
                                    <>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={2}
                                          lg={2}
                                          xl={2}
                                        >
                                          <div>
                                            <div>
                                              <h4 style={{ color: "#192f1e" }}>
                                                {listItemIndex + 1}.
                                                {listItem.type}
                                              </h4>
                                            </div>
                                            <div
                                              style={{ paddingLeft: "20px" }}
                                            >
                                              <div
                                                style={{
                                                  color: "#75787d",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                <span>Label :</span>
                                                <span
                                                  style={{
                                                    color: "#75787d",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {listItem.label}
                                                </span>
                                              </div>
                                              {listItem?.options && (
                                                <>
                                                  <div
                                                    style={{
                                                      color: "#75787d",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    <span>Options </span>
                                                    <span>
                                                      <div
                                                        style={{
                                                          paddingLeft: "20px",
                                                        }}
                                                      >
                                                        {listItem?.options.map(
                                                          (option, i) => {
                                                            return (
                                                              <>
                                                                <div
                                                                  key={i}
                                                                  style={{
                                                                    color:
                                                                      "#75787d",
                                                                    fontWeight:
                                                                      "500",
                                                                    marginTop:
                                                                      "2px",
                                                                  }}
                                                                >
                                                                  {option}
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    </span>
                                                  </div>
                                                </>
                                              )}

                                              <div style={{ marginTop: "5px" }}>
                                                {listItem.isMandatory ? (
                                                  <>
                                                    <div>
                                                      <span
                                                        style={{
                                                          color: "#ff6347",
                                                        }}
                                                      >
                                                        Mandatory
                                                      </span>

                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        <FaCheckCircle
                                                          style={{
                                                            color: "#ff6347",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      style={{
                                                        color: "#75787d",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      <span>Not Mandatory</span>
                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        <IoCloseCircleSharp />
                                                      </span>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  color: "#75787d",
                                                  fontWeight: "500",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                <span>
                                                  {listItem.description}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={10}
                                          lg={10}
                                          xl={10}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              height: "100%",
                                            }}
                                          >
                                            {listItem.type ==
                                            CHECKLIST_TYPE.TEXT ? (
                                              <>
                                                <TextInput
                                                  width={"226px"}
                                                  variant="mui"
                                                  // name={item?.name}
                                                  label={listItem.label}
                                                  inputClass="input_field_style"
                                                  fieldSize="small"
                                                  marginBottom="0px"
                                                  // fieldStyle="standard"
                                                  onChange={(e) => {
                                                    console.log(
                                                      "e.target.value=---------->",
                                                      e.target.value
                                                    );
                                                  }}
                                                  // value={item?.value}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <AutoCompleteAsync
                                                  sx={{
                                                    width: "224px",
                                                    backgroundColor: "white",
                                                  }}
                                                  multiple={false}
                                                  // name={ite}
                                                  label={listItem.label}
                                                  open={openDropdown}
                                                  onOpen={() => {
                                                    setOpenDropdown(true);
                                                  }}
                                                  onClose={() => {
                                                    setOpenDropdown(false);
                                                  }}
                                                  // isOptionEqualToValue={(
                                                  //   option,
                                                  //   value
                                                  // ) => option === value}
                                                  options={listItem?.options}
                                                  onChange={(
                                                    event,
                                                    newValue,
                                                    reason
                                                  ) => {
                                                    console.log(
                                                      "newVAlue=---------->",
                                                      newValue
                                                    );
                                                  }}
                                                  renderOption={(
                                                    props,
                                                    option
                                                  ) => (
                                                    <Box
                                                      style={{ fontSize: 13 }}
                                                      {...props}
                                                    >
                                                      {option}
                                                    </Box>
                                                  )}
                                                  getOptionLabel={(option) => {
                                                    if (option) {
                                                      return option;
                                                    } else {
                                                      return "Please Select";
                                                    }
                                                  }}
                                                  value={listItem.options[0]}
                                                  // variant={variant}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </CardItem>
    </>
  );
};

export default CheckListViewNew;
