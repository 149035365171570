import { Box, Tabs } from "@mui/material";
import {
  a11yProps,
  CustomTabPanel,
  StyledTab,
} from "../../constants/Constants";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import PropertyDetailsCard from "./propertydetails/PropertyDetailsCard";
import SystemDetails from "./systemdetails/SystemDetails";
import { useState } from "react";

const SystemPropertyTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="card_container">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          // textColor="secondary"
          // indicatorColor="secondary"

          TabIndicatorProps={{
            style: {
              backgroundColor: "#189d3d",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab
            icon={<HomeWorkIcon />}
            iconPosition="start"
            label="Property Details"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(0)}
          />
          <StyledTab
            icon={<SolarPowerIcon />}
            iconPosition="start"
            label="System Details"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <div className="card_content">
        <CustomTabPanel value={value} index={0}>
          <PropertyDetailsCard
            type="project"
            // cardData={projectData}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SystemDetails />
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default SystemPropertyTab;
