import { Box, Grid, IconButton } from "@mui/material";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalBody from "../../../modal/ModalBody";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../../fields/text/TextInput";
import { useEffect, useState } from "react";
import ModalFooter from "../../../modal/ModalFooter";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createAdditionalInfo,
  createSystemInfo,
} from "../../../../redux/slices/additionalInfoSlice";
import {
  getInverterList,
  getMaterialList,
  getModuleList,
} from "../../../../redux/slices/materialSlice";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import { useSelector } from "react-redux";
import { AddCircle } from "@mui/icons-material";
import { modalStyle } from "../../../constants/ConstData";
import MaterialForm from "../../../../pages/organisation/materials/MaterialForm";
import BasicModal from "../../../modal/Modal";

const SystemDetailsForm = ({ onClose, systemDetails }) => {
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;
  const orgMatTypeList = currentOrg?.materialType;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openModule, setOpenModule] = useState(false);
  const [openInverter, setOpenInverter] = useState(false);

  const materialLoading = useSelector((state) => state.material.loading);
  const moduleList = useSelector((state) => state.material.moduleList);
  const inverterList = useSelector((state) => state.material.inverterList);

  //   const [moduleList, setModuleList] = useState([]);
  const [moduleSelected, setModuleSelected] = useState({
    name: systemDetails?.modules,
  });
  const [inverterSelected, setInverterSelected] = useState({
    name: systemDetails?.inverters,
  });

  const [isOpen, setIsOpen] = useState({ value: false, name: "" });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (systemDetails?.id) {
      data.id = systemDetails?.id;
    }
    const editedData = {
      systemdtl: [data],
    };
    dispatch(
      createSystemInfo({
        url: `/crm/org/${orgId}/proj/${id}/info`,
        data: editedData,
        params: { data: "systemdtl" },
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseMaterialForm = () => {
    setIsOpen({ value: false, name: "" });
  };

  useEffect(() => {
    if (systemDetails?.systemSize) {
      setValue("systemSize", systemDetails?.systemSize);
    }
    if (systemDetails?.productionVolume) {
      setValue("productionVolume", systemDetails?.productionVolume);
    }
    if (systemDetails?.offset) {
      setValue("offset", systemDetails?.offset);
    }
    if (systemDetails?.systemCost) {
      setValue("systemCost", systemDetails?.systemCost);
    }
    if (systemDetails?.modules) {
      setValue("modules", systemDetails?.modules);
    }
    if (systemDetails?.inverters) {
      setValue("inverters", systemDetails?.inverters);
    }
    if (systemDetails?.panelCount) {
      setValue("panelCount", systemDetails?.panelCount);
    }
    if (systemDetails?.rackingRails) {
      setValue("rackingRails", systemDetails?.rackingRails);
    }
    if (systemDetails?.rackingAttachments) {
      setValue("rackingAttachments", systemDetails?.rackingAttachments);
    }
    if (systemDetails?.storage) {
      setValue("storage", systemDetails?.storage);
    }
    if (systemDetails?.storageType) {
      setValue("storageType", systemDetails?.storageType);
    }
  }, [systemDetails]);

  useEffect(() => {
    if (openModule) {
      const module = orgMatTypeList?.filter((item) => item.name === "Module");

      const moduleId = module[0]?.matType;

      dispatch(
        getModuleList({
          url: `/crm/org/${orgId}/bom/store/type/${moduleId}`,
          params: {},
        })
      );
    }
  }, [openModule]);

  useEffect(() => {
    if (openInverter) {
      const inverter = orgMatTypeList.filter(
        (item) => item.name === "Inverter"
      );

      const inverterId = inverter[0]?.matType;
      dispatch(
        getInverterList({
          url: `/crm/org/${orgId}/bom/store/type/${inverterId}`,
          params: {},
        })
      );
    }
  }, [openInverter]);

  //   useEffect(() => {
  //     setModuleList(materialList);
  //   }, [materialList]);

  return (
    <>
      <ModalHead title="System Details">
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`systemSize`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="System Size"
                    // placeholder="Property Type"
                    // register={register}
                    name="systemSize"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.systemSize?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`productionVolume`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="Annual Production kWh"
                    // placeholder="Year Built"
                    // register={register}
                    name="productionVolume"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">
                {errors.productionVolume?.message}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`offset`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="Offset"
                    // placeholder="Area"
                    // register={register}
                    name="offset"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.offset?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`systemCost`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="System Cost"
                    // placeholder="Home Type"
                    // register={register}
                    name="systemCost"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.systemCost?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`modules`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <AutoCompleteAsync
                    {...field}
                    className="input_field_style"
                    name={`modules`}
                    label="Modules"
                    open={openModule}
                    onOpen={() => {
                      setOpenModule(true);
                    }}
                    onClose={() => {
                      setOpenModule(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value.id
                    }
                    loading={materialLoading}
                    options={moduleList}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setModuleSelected(null);
                      } else {
                        setModuleSelected(newValue);
                        setValue("modules", newValue?.name);
                      }
                    }}
                    renderOption={(props, option, { index }) => {
                      const isLastOption = index === moduleList.length - 1;
                      return (
                        <>
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option.name}
                          </Box>
                          {isLastOption && (
                            <Button
                              className="w_100 p_0  d_flex align_items_center outline_button"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsOpen({ value: true, name: "Module" });
                              }}
                              leftIcon={<AddCircle />}
                            >
                              Add New Module
                            </Button>
                          )}
                        </>
                      );
                    }}
                    value={moduleSelected}
                    getOptionLabel={(option) => {
                      if (option?.name) {
                        return option?.name;
                      } else {
                        return "Please Select";
                      }
                    }}
                    fieldSize="small"
                    getOptionDisabled={(option) =>
                      moduleSelected.includes(option.id)
                    }
                  />
                )}
              />
              {/* <TextInput
                inputClass="input_field_style m_0"
                label="Modules"
                // placeholder="Home Type"
                register={register}
                name="modules"
              /> */}
              <p className="error_message">{errors.modules?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`inverters`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <AutoCompleteAsync
                    {...field}
                    className="input_field_style"
                    name={`inverters`}
                    label="Inverters"
                    open={openInverter}
                    onOpen={() => {
                      setOpenInverter(true);
                    }}
                    onClose={() => {
                      setOpenInverter(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value.id
                    }
                    loading={materialLoading}
                    options={inverterList}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setInverterSelected(null);
                      } else {
                        setInverterSelected(newValue);
                        setValue("inverters", newValue?.name);
                      }
                    }}
                    renderOption={(props, option, { index }) => {
                      const isLastOption = index === inverterList.length - 1;
                      return (
                        <>
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option.name}
                          </Box>
                          {isLastOption && (
                            <Button
                              className="w_100 p_0  d_flex align_items_center outline_button"
                              onClick={(e) => {
                                e.preventDefault();
                                // setIsOpen(true);
                                setIsOpen({ value: true, name: "Inverter" });
                              }}
                              leftIcon={<AddCircle />}
                            >
                              Add New Inverter
                            </Button>
                          )}
                        </>
                      );
                    }}
                    value={inverterSelected}
                    getOptionLabel={(option) => {
                      if (option?.name) {
                        return option?.name;
                      } else {
                        return "Please Select";
                      }
                    }}
                    fieldSize="small"
                    getOptionDisabled={(option) =>
                      inverterSelected.includes(option.id)
                    }
                  />
                )}
              />
              {/* <TextInput
                inputClass="input_field_style m_0"
                label="Inverters"
                // placeholder="Home Type"
                register={register}
                name="inverters"
                variant="mui"
                fieldSize="small"
              /> */}
              <p className="error_message">{errors.inverters?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`panelCount`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="Panel Count"
                    // placeholder="Home Type"
                    // register={register}
                    name="panelCount"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.panelCount?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`rackingRails`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="Racking Rails"
                    // placeholder="Home Type"
                    // register={register}
                    name="rackingRails"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.rackingRails?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`rackingAttachments`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="Racking Attachments"
                    // placeholder="Home Type"
                    // register={register}
                    name="rackingAttachments"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">
                {errors.rackingAttachments?.message}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`storage`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="Storage"
                    // placeholder="Home Type"
                    // register={register}
                    name="storage"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.storage?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`storageType`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    inputClass="input_field_style m_0"
                    label="Storage Type"
                    // placeholder="Home Type"
                    // register={register}
                    name="storageType"
                    variant="mui"
                    fieldSize="small"
                  />
                )}
              />
              <p className="error_message">{errors.storageType?.message}</p>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            Save
          </Button>
        </ModalFooter>
      </form>

      <BasicModal
        isOpen={isOpen.value}
        onClose={handleCloseMaterialForm}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <MaterialForm
          modalView={true}
          onClose={handleCloseMaterialForm}
          orgId={orgId}
          type={isOpen?.name}
        />
      </BasicModal>
    </>
  );
};

export default SystemDetailsForm;
