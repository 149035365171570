import React, { useEffect, useState } from "react";
import ToolBar from "../../components/toolbar/ToolBar";
import Button from "../../components/buttons/Button";
import PlusIcon from "../../assets/images/plus";
import AddModuleModal from "./AddModuleModal";
import DataTable from "../../components/table/DataTable";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getModuleList } from "../../redux/slices/moduleSlice";
const moduleColumns = [
  {
    field: "id",
    headerName: "Module Id",
    width: 80,
  },
  {
    field: "name",
    headerName: "Module Name",
    width: 250,
    renderCell: (params) => {
      return <Typography>{params?.row?.menuName}</Typography>;
    },
  },

  {
    field: "description",
    headerName: "Description",
    width: 300,
    renderCell: (params) => <Typography>{params?.row?.menuDesc}</Typography>,
  },
];
const ModulesList = () => {
  const [openAddModulesModal, setOpenAddModulesModal] = useState(false);
  const createdData = useSelector((state) => state.form.createData);
  const moduleList = useSelector((state) => state.module.moduleList);
  console.log('module list',moduleList);
  const dispatch = useDispatch();
  const loadModuleList = () => {
    dispatch(
      getModuleList({
        url: `/crm/common/module`,
      })
    );
  };
  useEffect(() => {
    loadModuleList();
  }, [createdData]);
  return (
    <div className="project">
      <ToolBar title="Modules">
        <div className="d_flex align_items_center justify_content_between">
          <Button
            type="submit"
            className="primary_button d_flex align_items_center"
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18px",
              padding: "5px 20px",
            }}
            leftIcon={<PlusIcon color="#fff" />}
            onClick={() => setOpenAddModulesModal(true)}
          >
            Add Module
          </Button>
        </div>
      </ToolBar>
      <div style={{ marginTop: "20px" }}>
        <DataTable rows={[
          ...moduleList.map((item)=>{
            return {
              ...item,
              id:item.menuId
            }
          })
        ]} columns={moduleColumns} />
      </div>
      <AddModuleModal
        open={openAddModulesModal}
        close={() => {
          setOpenAddModulesModal(false);
        }}
      />
    </div>
  );
};

export default ModulesList;
