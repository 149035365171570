import React, { useEffect, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import CustomDateRangePicker from "../../../components/dateRangePicker/CustomDateRangePicker";
import dayjs from "dayjs";
import DataTable from "../../../components/table/DataTable";
import { Typography } from "@mui/material";
import { getLeadListOrg } from "../../../redux/slices/orgSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const leadColumns = [
  {
    field: "slno",
    headerName: "Sl No.",
    width: 80,
  },
  {
    field: "id",
    headerName: "Lead Id",
    width: 80,
  },
  {
    field: "name",
    headerName: "Lead Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },
  {
    field: "address",
    headerName: "Address",
    width: 300,
    renderCell: (params) => {
      return <Typography>{params?.row?.address}</Typography>;
    },
  },
];
const Leads = () => {
  const {id}=useParams();
  const dispatch=useDispatch();
  const [leadStartFrom, setLeadStartFrom] = useState("2021-01-01");
  const [leadStartEnd, setLeadStartEnd] = useState("2029-01-01");
  const leadList = useSelector((state) => state.org.leadListOrg);
  const formatDate = (date) => {
    if (!date) return "";
    return dayjs(date).format("YYYY-MM-DD"); // Adjust format as needed
  };
  const loadLeads = () => {
    dispatch(
      getLeadListOrg({
        url: `/crm/org/${id}/leads/list`,
        params: {
          orgId: Number(id),
          page: 1,
          pageSize: 10,
          startFrom: leadStartFrom,
          startEnd: leadStartEnd,
        },
      })
    );
  };
  useEffect(() => {
    loadLeads();
  }, [leadStartFrom, leadStartEnd]);
  return (
    <CardItem height="890px">
      <div className="card_container">
        <div style={{ padding: "20px" }}>
          <ToolBar title={"Leads"}>
            <div className="d_flex align_items_center justify_content_between">
              <div className="marginR10">
                <CustomDateRangePicker
                  label={"Start Date"}
                  defaultValue={[dayjs("2021-01-01"), dayjs("2029-01-01")]}
                  onChange={(e, value) => {
                    if (e[0]) {  
                        setLeadStartFrom(formatDate(e[0]));
                    }
                    if (e[1]) {
                        setLeadStartEnd(formatDate(e[1]));
                      
                    }
                  }}
                />
              </div>
            </div>
          </ToolBar>
        </div>

        <div style={{ padding: "20px" }}>
          <DataTable
            rows={
              [...leadList].map((item,index)=>{
                return {
                  ...item,
                  slno:index+1
                }
              })
            }
            columns={leadColumns}
            onRowClick={(row) => {
             
            }}
          />
        </div>
      </div>
    </CardItem>
  );
};

export default Leads;
