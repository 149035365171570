import { Link, NavLink } from "react-router-dom";
import DashboardIcon from "../../assets/images/dashboard.svg";
import ProjectIcon from "../../assets/images/projects.svg";
import LeadIcon from "../../assets/images/lead.svg";

import InsightIcon from "../../assets/images/insights.svg";
import CalendarIcon from "../../assets/images/calendar.svg";
import OrgIcon from "../../assets/images/orgIcon.svg";

import UserIcon from "../../assets/images/user.svg";

import ReportIcon from "../../assets/images/report.svg";
import PlusIcon from "../../assets/images/plus.svg";
import "./style.scss";
import SimpleBottomNavigation from "./SimpleBottomNavigation";
import Storage from "../../utils/Storage";
import Logo from "../../assets/images/logo/Sunscape.png";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const sidebarList = [
  // { title: "Dashboards", icon: DashboardIcon, url: "/dashboard" },
  { title: "Leads", icon: LeadIcon, url: "/leads" },
  { title: "Projects", icon: ProjectIcon, url: "/projects" },
  // { title: "Stats/Insights", icon: InsightIcon, url: "/insights" },
  { title: "Calendar", icon: CalendarIcon, url: "/calendar" },
  { title: "My Organizations", icon: OrgIcon, url: "/org" },

  // isOwner ? { title: "Users", icon: UserIcon, url: "/users" } : null,
  // { title: "Report", icon: ReportIcon, url: "/report" },
  // {
  //   title: "Create Dashboard",
  //   icon: PlusIcon,
  //   url: "/dashboard/create",
  //   class: "nav_link_white",
  // },
];

const Sidebar = () => {
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const isOwner = currentOrg?.isOwner === "true" ? true : false;

  const orgLogo = currentOrg?.logodtls;
  const { t } = useTranslation();

  const combinedPermissions = useSelector(
    (state) => state.auth.combinedPermissions
  );
  const [sidebar, setSidebar] = useState(sidebarList);

  const allowedMenus = useMemo(() => {
    if (!combinedPermissions?.length) return [];
    return combinedPermissions
      .filter(
        ({ permdtl }) => permdtl && (permdtl.READ === 1 || permdtl.WRITE === 1)
      )
      .map(({ menuname }) => menuname);
  }, [combinedPermissions]);

  useEffect(() => {
    if (!isOwner) {
      const filteredSidebar = sidebarList.filter((item) =>
        allowedMenus.includes(item.title)
      );

      // Update state only if it differs
      setSidebar((prevSidebar) =>
        JSON.stringify(prevSidebar) !== JSON.stringify(filteredSidebar)
          ? filteredSidebar
          : prevSidebar
      );
    } else {
      setSidebar(sidebarList);
    }
  }, [allowedMenus, isOwner]);

  return (
    <>
      <div className="sidebar">
        <nav>
          <div className="logo_wrapper">
            <div className="logo">
              <Link className="" to="/">
                {/* SunScape */}
                <img
                  style={{
                    height: "100%",
                    width: "auto",
                    objectFit: "contain",
                  }}
                  src={orgLogo ?? Logo}
                  alt="Sunscape"
                />
              </Link>
            </div>
          </div>
          <ul>
            {sidebar?.map((item, index) => (
              <li key={index} className="nav_item">
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "active nav_link"
                      : item.class
                      ? `${item.class} nav_link`
                      : "nav_link"
                  }
                  to={item.url}
                >
                  <img className="nav_icon" src={item.icon} alt="" />{" "}
                  {/* {item.title}  */}
                  {t(item.title)}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        {orgLogo && (
          <div className="powered_by_wrapper">
            <p className="m_0 font_14_dark powered_by_text marginB10 text_white">
              {t("Powered By")}
            </p>
            <img className="powered_by_image" src={Logo} alt="Sunscape" />
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
