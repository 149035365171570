import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../../redux/slices/titleSlice";
import { useEffect, useState } from "react";
import ToolBar from "../../../components/toolbar/ToolBar";
import Button from "../../../components/buttons/Button";
import PlusIcon from "../../../assets/images/plus";
import BasicModal from "../../../components/modal/Modal";
import "./style.scss";
import { ProjectListItem } from "../../../components/project/list/ProjectListItem";
import ProjectForm from "../create/ProjectForm";
import { getProjectDashboard, getProjectStatusList, updateProjectStatus } from "../../../redux/slices/projectSlice";
import Loading from "../../../components/loading/Loading";
import { modalStyle } from "../../../components/constants/ConstData";
import FilterIcon from "../../../assets/images/filter";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import Storage from "../../../utils/Storage";
import { clearCurrentFile } from "../../../redux/slices/fileUploadSlice";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import { CiGrid2V } from "react-icons/ci";
import { TfiLayoutGrid2 } from "react-icons/tfi";
import { CiViewTable } from "react-icons/ci";
import { IoTabletPortraitOutline } from "react-icons/io5";
import DataTable from "../../../components/table/DataTable";
import dayjs from "dayjs";
import { setProjectListSliceView } from "../../../redux/slices/projectListViewSlice";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import { setCreateSnackbar } from "../../../redux/slices/formSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDialog } from "../../../hooks/useDialog";
import CustomDialog from "../../../components/dialog/CustomDialog";

const filterList = [
  { name: "All Projects", value: 1 },
  { name: "My Projects", value: 0 },
];

const ProjectList = () => {
  const statusLoading = useSelector((state) => state.project.statusLoading);
  const [statusSelectedId, setStatusSelectedId] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const {
    isOpen,
    openDialog,
    closeDialog,
    title,
    content,
    okLabel,
    cancelLabel,
    handleOk,
    handleCancel,
  } = useDialog();
  const loadProjectStatusList = () => {
    dispatch(
      getProjectStatusList({
        url: `crm/org/${orgId}/proj/status`,
        params: {},
      })
    );
  };
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null);
  };
  const handleStatus = (event, statusId) => {
    event.stopPropagation();
    //event.preventDefault();
    setAnchorElStatus(event.currentTarget);
    setStatusSelectedId(statusId);
  };
  const getDaysDifference = (createdAt, totalDays) => {
    const createdDate = new Date(createdAt);
    const dueDate = new Date(createdDate);
    dueDate.setDate(createdDate.getDate() + Number(totalDays));
    let currentDate = new Date();
    // Calculate the difference in time (milliseconds) and convert it to days
    const timeDifference = dueDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days
    return daysDifference;
  };
  const getDueStatus = (createdAt, totalDays) => {
    // Determine the message based on the difference in days
    const daysDifference = getDaysDifference(createdAt, totalDays);

    if (daysDifference > 2 || totalDays === 0) {
      return <></>;
    }
    let message = "";
    if (daysDifference > 1) {
      message = `Due in ${daysDifference} days`;
    } else if (daysDifference === 1) {
      message = "Due in 1 day";
    } else if (daysDifference === 0) {
      message = "Due today";
    } else {
      message = `${Math.abs(daysDifference)} days past due`;
    }

    // Render the message
    return (
      <>
        <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
          <div
            style={{
              width: "100%",
              //backgroundColor: daysDifference < 0 ? "#b70e00" : "#ffa500", // Red for past due, orange for warnings
              color: "white",
              fontWeight: "400",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            {message}
          </div>
        </div>
      </>
    );
  };

  const projectListColumns = [
    {
      field: "id",
      headerName: "Sl No",
      width: 80,
    },
    // {
    //   field: "id",
    //   headerName: "Sl No",
    //   width: 80,
    // },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 180,
      renderCell: (params) => (
        <Typography>
          {params?.row?.customerInfo?.pContact?.firstName}{" "}
          {params?.row?.customerInfo?.pContact?.lastName}
        </Typography>
      ),
    },
    {
      field: "createdDate",
      headerName: "CreatedDate",
      width: 120,
      renderCell: (params) => {
        console.log('params.row',params);
        return (
          <>
            <Typography>
              {dayjs(params?.row?.createdAt).format("YYYY-MM-DD")}
            </Typography>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      renderCell: (params) =>{
        // return  <Typography>{params?.row?.column}</Typography>
        return (
          <div
        //  key={index}
          style={{
           // border: "1px dashed rgb(24, 157, 61) ",
            padding: "10px",
          }}
          className="d_flex  align_items_center"
        >
          <Button
            style={{
              cursor: "pointer",
              border: "1px dashed rgb(24, 157, 61) ",
              borderRadius: "20px",
              padding: "5px 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            //  minWidth:'150px',
              backgroundColor: "transparent",
              color: "rgba(0, 0, 0, 0.6)",
              
            }}
           onClick={(event) => {
            handleStatus(event, params.row.id)
          //  console.log('inisde click event')
          //  e.stopPropagation();
           }}
           // disabled={isOwner | isModerator ? false : true}
          >
            <p style={{ fontSize: "14px", margin: 0 }}>{params.row.column}</p>
            <ArrowDropDownIcon />
          </Button>
          {
          //!statusLoading && 
          (
            <Menu
              sx={{ mt: "30px" }}
              style={{ height: "400px" }}
              id="menu-appbar"
              anchorEl={anchorElStatus}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElStatus)}
              onClose={handleCloseStatusMenu}
            >
              {projectStatusList?.map((status, index) => (
                
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    if (params.row.checklist_status === "Checklist Completed") {
                     
                      openDialog({
                        newTitle: "Update Status",
                        newContent: `Are you sure, you want to move this project from ${params.row?.column} to ${status.name}?`,
                        okLabel: "Ok, Update",
                        cancelLabel: "Cancel",
                        onOk: () => {
                          
                          const statusData = [
                            {
                              customerInfo: params.row?.customerInfo,
                              address: params.row?.address,
                              prevStatusId:params.row.statusId ,
                              status: {
                                id: status?.id,
                                name: status?.name,
                                uid: status?.uid,
                                userName: status?.userName,
                              },
                              assignedUser:
                                status?.users?.length > 0
                                  ? status?.users?.map((user) => user?.id)
                                  : [0],
                              groupId:
                                status?.groups?.length > 0
                                  ? status?.groups?.map(
                                      (group) => group?.id
                                    )
                                  : [0],
                            },
                          ];

                          dispatch(
                            updateProjectStatus({
                              url: `crm/org/${orgId}/proj/${params.row.id}/status`,
                              data: statusData,
                              params: { data: "status" },
                            })
                          );

                          handleCloseStatusMenu();
                        },
                        onCancel: handleCancel,
                      });

                    
                    } else {
                   
                      dispatch(
                        setCreateSnackbar({
                          message:
                            "You have some pending checklist for this status. Please update the checklist and try again",
                          createSuccess: true,
                          statusCode: 600,
                        })
                      );
                      handleCloseStatusMenu();
                    }
                  }}
                  disabled={params.row?.column===status.name?true:false}
                >
                  <Typography textAlign="center">
                    {status?.displayName}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          )}

        
        </div>
        )
      },
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 200,
      renderCell: (params) => (
        <Typography>{params?.row?.stagesname}</Typography>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 400,
      renderCell: (params) => <Typography>{params?.row?.address}</Typography>,
    },
    // {
   
    //   width: 250,
    //   renderCell: (params) =>{
    //     return (
    //       <>
    //         <div style={
    //           {
    //             backgroundColor:"red",
    //             borderRadius:'10px',
    //             padding:'10px',
    //             width:'100px',
    //             display:'flex',
    //             justifyContent:'center',
    //             alignItems:'center'
               
    //           }
    //         }>
    //         test
    //         </div>
    //       </>
    //     )
    //   },
    // },
  ];
  // const [tableView, setTableView] = useState(false);
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [statusFilterSelected, setStatusFilterSelected] = useState(null);
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  // const orgId = JSON.parse(sessionStorage.getItem("org"))?.id;

  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const { t } = useTranslation();
  const loading = useSelector((state) => state.project.loading);
  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);
  const createData = useSelector((state) => state.form.createData);
  const tableView = useSelector((state) => state.projectListView.tableView);
  const isOwner =
    currentOrg?.isOwner === "true" || currentOrg?.isModerator === "true"
      ? true
      : false;

  const dashValue = Storage.read(`dashowner${userInfo?.id}`);
  const [dashowner, setDashowner] = useState(
    isOwner
      ? dashValue
        ? dashValue
        : { name: "All Projects", value: 1 }
      : null
  );
  const orgInfo = useSelector((state) => state.auth.orgInfo);

  const projectDashboard = useSelector(
    (state) => state.project.projectDashboard
  );
  const navigate = useNavigate();

  // const iconSx = {
  //   backgroundColor: "rgba(55, 144, 107, 0.08)",
  //   borderRadius: "4px",
  //   marginRight: "10px",
  // };

  const createNewProject = (e) => {
    e.preventDefault();
    setOpenAddProjectModal(true);
  };

  const loadProjects = (ownerValue) => {
    const baseUrl = `crm/org/${orgId}/proj/dashboard`;
    dispatch(
      getProjectDashboard({
        url:
          ownerValue && ownerValue === 1
            ? `${baseUrl}?isOrg=${ownerValue}`
            : `${baseUrl}`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (orgId) {
      if (isOwner && dashowner?.value) {
        loadProjects(dashowner?.value);
      } else {
        loadProjects();
      }
    }
  }, [dashowner?.value, orgId]);

  const handleFilters = (event) => {
    event.preventDefault();

    setOpenFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setOpenFilter(null);
  };
  const getRowProps = (row) => {
    let style = {};
    console.log('row',row);
    // Define your condition for background color variation
    // if (row.original.someCondition) { // Replace with your actual condition
    //   style = { backgroundColor: 'lightblue' };
    // } else {
    //   style = { backgroundColor: 'white' }; // Or your default background color
    // }

    // return { style };
  };

  useEffect(() => {
    dispatch(setTitle("Projects"));
    // loadProjects();
  }, []);

  useEffect(() => {
    if (orgInfo && orgInfo.length === 0) {
      navigate("/org/create");
    }
  }, [orgInfo]);

  useEffect(() => {
    if (createData?.createSuccess) {
      if (dashowner?.value) {
        loadProjects(dashowner?.value);
      } else {
        loadProjects();
      }
      dispatch(clearCurrentFile());
    }
  }, [createData]);

  const handleClose = () => {
    setOpenAddProjectModal(false);
  };
  useEffect(()=>{
    if(orgId)
    loadProjectStatusList()
  },[orgId])
  useEffect(() => {
    if (isOwner) {
      setDashowner(dashValue ? dashValue : { name: "All Projects", value: 1 });
    }
  }, [isOwner]);

  if (!openAddProjectModal && loading) {
    return <Loading />;
  }



  return (
    <div className="project">
      <ToolBar title={t("Projects")}>
        <div className="d_flex align_items_center">
          {tableView ? (
            <>
              <div className="marginR10">
                <TfiLayoutGrid2
                  style={{
                    fontSize: "18px",
                    cursor: isHovered ? "pointer" : "default",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => {
                   
                    dispatch(setProjectListSliceView(!tableView));
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="marginR10">
                <IoTabletPortraitOutline
                  style={{
                    fontSize: "18px",
                    cursor: isHovered ? "pointer" : "default",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => {
                    dispatch(setProjectListSliceView(!tableView));
                  }}
                />
              </div>
            </>
          )}

          {tableView && (
            <>
              <div className="marginR10">
                <AutoCompleteAsync
                  sx={{
                    width: "175px",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                  name="status"
                  label={t("Status")}
                  open={openStatusFilter}
                  onOpen={() => {
                    setOpenStatusFilter(true);
                  }}
                  onClose={() => {
                    setOpenStatusFilter(false);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id
                  }
                  // loading={leadLoading}
                  options={projectDashboard?.head}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      // setOfficeSelected(null);
                      setStatusFilterSelected(null);
                    } else {
                      // setOfficeSelected(newValue);
                      setStatusFilterSelected(newValue);
                      // const editedData = {
                      //   office: newValue,
                      // };
                    }
                  }}
                  // register={register}
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 13 }} {...props}>
                      {option.name}
                    </Box>
                  )}
                  value={statusFilterSelected}
                  getOptionLabel={(option) => {
                    if (option.name) {
                      return option?.name;
                    } else {
                      return t("Please Select");
                    }
                  }}
                  // variant="standard"
                  fieldSize="small"
                />
              </div>
            </>
          )}

          {/* <CustomIconButton icon={WindowIcon} sx={iconSx} />
          <CustomIconButton icon={MenuIcon} sx={iconSx} />
          <Button
            type="submit"
            className="default_button d_flex align_items_center marginR10"
            leftIcon={<FilterIcon color="#75787D" />}
          >
            Filters
          </Button>
          <Button
            type="submit"
            className="default_button d_flex alnpm i react-beautiful-dndign_items_center marginR10"
            leftIcon={<SortIcon color="#75787D" />}
          >
            Sort by
          </Button> */}
          {isOwner && (
            <>
              <Button
                type="submit"
                className="default_button d_flex align_items_center marginR10"
                leftIcon={<FilterIcon color="#75787D" />}
                onClick={handleFilters}
              >
                {dashowner?.name}
              </Button>
              <Menu
                sx={{
                  mt: "30px",
                  ".css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "150px",
                  },
                }}
                style={{ height: "400px" }}
                id="menu-appbar"
                anchorEl={openFilter}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(openFilter)}
                onClose={handleCloseFilterMenu}
              >
                {filterList?.map((filter, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      // loadProjects(filter.value);
                      Storage.write(`dashowner${userInfo?.id}`, filter);
                      setDashowner(filter);
                      // setStatus(status);
                      // // setValue("statusId", status?.id);
                      // const statusData = {
                      //   status: {
                      //     id: status?.id,
                      //     name: status?.name,
                      //   },
                      // };
                      // dispatch(
                      //   updateProjectStatus({
                      //     url: `crm/org/${orgId}/proj/${data?.projectId}/status`,
                      //     data: statusData,
                      //     params: {},
                      //   })
                      // );
                      handleCloseFilterMenu();
                    }}
                    // {...register("statusId", {})}
                  >
                    {/* <IconButton sx={{ p: 0, marginRight: "8px" }}>
                          <Avatar
                            style={{ width: "30px", height: "30px" }}
                            {...stringAvatar(user?.name)}
                          />
                        </IconButton> */}
                    <div className="d_flex align_items_center w_100">
                      <div
                        style={{
                          width: "20%",
                          // display: "flex",
                          // alignItems: "center",
                        }}
                      >
                        {filter?.value === dashowner?.value && (
                          <DoneIcon fontSize="12px" color="success" />
                        )}
                      </div>
                      <div style={{ width: "80%" }}>
                        <Typography className="font_12 m_0">
                          {filter?.name}
                        </Typography>
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          {projectDashboard?.head?.length > 0 && (
            <Button
              type="submit"
              className="primary_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "18px",
                padding: "5px 20px",
              }}
              leftIcon={<PlusIcon color="#fff" />}
              onClick={createNewProject}
            >
              {t("Add Project")}
            </Button>
          )}
        </div>
      </ToolBar>
      {/* <div style={{ width: "100%", overflowX: "auto" }} className=""> */}
      {tableView ? (
        <>
          <div style={{ paddingTop: "10px" }}>
            <DataTable
              rows={
                statusFilterSelected?
                [
                  ...projectDashboard?.list?.filter((item,index)=>{
                    return item.column==statusFilterSelected.name
                  }).map((item,index)=>{
                    return {
                      ...item,
                     // slno:index+1,
                      id:item.id,
                    projectId:item.id

                    }
                  })
                ]
                :
                [
                ...projectDashboard?.list?.map((item, index) => {
                  return {
                    ...item,
                   // slno: index + 1,
                    id: index+1,
                    projectId:item.id
                  };
                }),
              ]
            
            }
              columns={projectListColumns}
              onRowClick={(row) => {
                navigate(`/projects/${row.row.projectId}`);
              }}
              getRowProps={getRowProps}
            />
          </div>
        </>
      ) : (
        <>
          <ProjectListItem projectDashboard={projectDashboard} />
        </>
      )}
      <BasicModal isOpen={openAddProjectModal} onClose={handleClose} sx={modalStyle}>
        <ProjectForm onClose={handleClose} />
      </BasicModal>
      <CustomDialog
        open={isOpen}
        title={title}
        message={content}
        okText={okLabel}
        cancelText={cancelLabel}
        onOk={handleOk}
        onCancel={handleCancel}
      />
      {/* </div> */}
    </div>
  );
};

export default ProjectList;
