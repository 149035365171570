import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  styled,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Button from "../../buttons/Button";
import AddIcon from "@mui/icons-material/Add";

import ProjectSentMail from "../../mail/ProjectSentMail";
import AccordionItem from "./AccordionItem";
import ComingSoon from "../../common/ComingSoon";
import TextEditor from "../../../fields/texteditor/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import { stringAvatar } from "../../../utils/util";
import UserAvatar from "../../useravatar/UserAvatar";
import dayjs from "dayjs";
import { getOrgUserList } from "../../../redux/slices/orgSlice";
import { useParams } from "react-router-dom";
import {
  createNewComment,
  getCommentList,
} from "../../../redux/slices/commentSlice";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
} from "../../constants/Constants";
import Storage from "../../../utils/Storage";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// function HighlightName({ sentence }) {
//   // Regular expression to find the name enclosed in special characters
//   // const regex = /[$][#][@]name|[$][#][@]id[0-9]+/g;
//   // const match = sentence.match(regex);

//   // if (!match) {
//   //   return <p>{sentence}</p>; // If no match found, return the original sentence
//   // }

//   // Remove special characters and make the name bold
//   const updatedSentence = sentence.replace(
//     /[$][#][@]name|[$][#][@]id[0-9]+/g,
//     (match, name) => {
//       return (
//         <span
//           dangerouslySetInnerHTML={{
//             __html: { name },
//           }}
//         />
//       );
//     }
//   );

//   return <p>{updatedSentence}</p>;
// }

function BoldName({ sentence }) {
  // Regular expression to match the name following `$#@name`
  const regex = /\$#@name(.+?)\$#@/;
  const updatedSentence = sentence.replace(regex, (match, name) => {
    return ` <strong>${name}</strong>`;
  });

  const newUpdatedSentence = updatedSentence.replace(/[$][#][@]id+[0-9]+/g, "");
  const finalComment = newUpdatedSentence.replace(/\$\#@uid\w*\s*/g, "");

  return <p dangerouslySetInnerHTML={{ __html: finalComment }} />;
}

export default function ProjectDetailsTab({ projectData, type }) {
  const [value, setValue] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const [mention, setMention] = React.useState("");
  // const [commentFormatted, setCommentFormatted] = React.useState("");
  const commentData = useSelector((state) => state.comment.commentData);

  const [focus, setFocus] = React.useState(false);
  // const [commentList, setCommentList] = React.useState(commentData ?? []);
  var relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);
  // const qillRefs = React.useRef(null);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const orgUserLoading = useSelector((state) => state.org.orgUserLoading);
  const orgUsers = useSelector((state) => state.org.orgUsers);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const [content, setContent] = React.useState(null);
  const [filteredUsers, setFilteredUsers] = React.useState();
  const [mentionList, setMentionList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleFocus = () => {
    setFocus(true);
    // React.findDOMNode(qillRefs.quillRef).focus();
    // qillRefs?.quillRef?.state?.editor.focus();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleComment = (content, delta, source, editor) => {
    setContent(content);
    const commentValue = editor.getText(content).replace(/\n/g, " ");
    setComment(commentValue);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElUser(null);
  };

  function findAndReplace(string, replacements) {
    // Iterate over each object in the replacements array
    replacements.forEach(({ name, id, uid }) => {
      // Create a regular expression to match the word globally
      // const regex = new RegExp(name, "g");
      // Replace occurrences of the word with its replacement
      string = string.replace(
        `@${name}`,
        `$#@name${name}$#@ $#@id${id} $#@uid${uid}`
      );
    });
    // Return the modified string
    return string;
  }

  const saveComment = () => {
    const commentFormatted = findAndReplace(comment, mentionList);
    const newComment = {
      userName: userDetails?.displayName,
      comment: commentFormatted,
      date: dayjs(new Date()),
    };

    dispatch(
      createNewComment({
        url: `/crm/org/${orgId}/${type}/${id}/comments`,
        data: newComment,
        params: {},
      })
    );
  };

  React.useEffect(() => {
    if (anchorElUser) {
      dispatch(
        getOrgUserList({
          url: `crm/user/organization/${orgId}/users`,
          params: {},
        })
      );
    } else {
      setFilteredUsers([]);
    }
  }, [anchorElUser]);

  function containsValue(array, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].name === value) {
        return true; // Value found
      }
    }
    return false; // Value not found
  }

  React.useEffect(() => {
    const lastWord = comment.split(" ").slice(0, -1).pop();

    if (lastWord?.length > 1 && lastWord?.startsWith("@")) {
      setMention(lastWord.substring(1));
      // For simplicity, using static mention suggestions
      const mentionIncluded = containsValue(mentionList, lastWord.substring(1));
      if (!mentionIncluded) {
        setAnchorElUser(true);
      }
    } else {
      setMention("");
      setAnchorElUser(false);
    }
  }, [comment]);

  React.useEffect(() => {
    const lastWord = comment.split(" ").slice(0, -1).pop();
    if (lastWord && !orgUserLoading && orgUsers) {
      const updatedList = orgUsers?.filter((item) =>
        item?.name?.toLowerCase().includes(lastWord.substring(1).toLowerCase())
      );
      setFilteredUsers(updatedList);
    }
  }, [comment, orgUsers, orgUserLoading]);

  const loadCommentList = () => {
    dispatch(
      getCommentList({
        url: `/crm/org/${orgId}/${type}/${id}/comments`,
        params: { page: page, pageSize: pageSize },
      })
    );
  };

  React.useEffect(() => {
    if (orgId && value === 0) {
      loadCommentList();
    }
  }, [value, page]);

  // React.useEffect(() => {
  //   setCommentList(commentData);
  // }, [commentData]);

  return (
    <div className="card_container">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          // textColor="secondary"
          // indicatorColor="secondary"

          TabIndicatorProps={{
            style: {
              backgroundColor: "#189d3d",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {/* <StyledTab
              icon={<FormatListBulletedIcon />}
              iconPosition="start"
              label="Details"
              sx={{
                fontSize: "10px",
              }}
              {...a11yProps(0)}
            /> */}
          <StyledTab
            icon={<EditNoteIcon />}
            iconPosition="start"
            label="Comments"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(0)}
          />
          {/* {type === "proj" && (
            <StyledTab
              icon={<TaskAltIcon />}
              iconPosition="start"
              label="Tasks"
              sx={{
                fontSize: "10px",
              }}
              {...a11yProps(1)}
            />
          )} */}
          {type === "proj" && (
            <StyledTab
              icon={<MarkEmailReadIcon />}
              iconPosition="start"
              label="Emails Sent"
              sx={{
                fontSize: "10px",
              }}
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
          <AccordionItem />
        </CustomTabPanel> */}
      <div className="card_content">
        <CustomTabPanel value={value} index={0}>
          <div className="d_flex align_items_start">
            <UserAvatar name={userDetails.displayName} />
            <div style={{ width: "100%", position: "relative" }}>
              {focus ? (
                <>
                  <div>
                    <TextEditor
                      // innerRef={qillRefs}
                      name="comment"
                      label="Comments"
                      // register={register}
                      value={content}
                      onChange={handleComment}
                      focus={focus}
                      height="125px"
                    />
                    {!orgUserLoading && (
                      <Menu
                        sx={{ mt: "30px" }}
                        style={{ height: "400px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        // anchorOrigin={{
                        //   vertical: "center",
                        //   horizontal: "center",
                        // }}
                        // keepMounted
                        // transformOrigin={{
                        //   vertical: "center",
                        //   horizontal: "center",
                        // }}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseStatusMenu}
                      >
                        {filteredUsers?.map((user, index) => (
                          <MenuItem
                            key={index}
                            // style={{ left: "1910px !important " }}
                            sx={{}}
                            onClick={() => {
                              const updatedContent = comment.replace(
                                `@${mention}`,
                                `@${user.name} `
                              );
                              setMentionList([
                                ...mentionList,
                                {
                                  name: user.name,
                                  id: user.userId,
                                  uid: user.uid,
                                },
                              ]);
                              setComment(updatedContent + " ");
                              setContent(`<p>${updatedContent}</p> `);
                              setMention("");

                              handleCloseStatusMenu();
                            }}
                          >
                            <IconButton sx={{ p: 0, marginRight: "8px" }}>
                              <Avatar
                                style={{ width: "30px", height: "30px" }}
                                {...stringAvatar(user?.name)}
                              />
                            </IconButton>
                            <Typography textAlign="center">
                              {user?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </div>

                  <div
                    style={{ marginTop: "80px" }}
                    className="d_flex align_items_center"
                  >
                    <Button
                      onClick={saveComment}
                      className="primary_button_small"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        setFocus(false);
                        setComment("");
                      }}
                      className="outline_button_small marginL10"
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <TextField
                  fullWidth
                  label="Add a comment..."
                  id="fullWidth"
                  onFocus={handleFocus}
                  value={comment}
                />
              )}
            </div>
          </div>
          {commentData?.commentsjson?.map(
            (item, index) =>
              item?.message && (
                <div key={index} className="comment_list marginT10">
                  <div className="d_flex align_items_start marginT10">
                    <UserAvatar name={item?.createdBy?.name} />
                    <div>
                      <div className="d_flex ">
                        <p className="m_0">
                          <b>{item?.createdBy?.name}</b>
                        </p>

                        <p className="m_0 marginL10">
                          <small>{dayjs(item.date).fromNow()}</small>
                        </p>
                      </div>
                      {/* <p className="m_0">
                        {item?.comment?.replace(
                          /[$][#][@]name|[$][#][@]id[0-9]+/g,
                          ""
                        )}
                      </p> */}
                      {/* <HighlightName sentence={} /> */}
                      <BoldName sentence={item?.message} />
                    </div>
                  </div>
                </div>
              )
          )}
          {commentData?.totalItems > 10 && (
            <div
              // style={{ position: "fixed", bottom: 0, right: 0 }}
              className="w_100 d_flex justify_content_center align_items_center"
            >
              <IconButton
                className="arrow_button"
                style={{ color: "#000" }}
                onClick={() => setPage((prev) => prev - 1)}
                disabled={page === 1}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <p>
                {page} of {commentData?.totalPages}
              </p>

              <IconButton
                className="arrow_button"
                style={{ color: "#000" }}
                onClick={() => setPage((prev) => prev + 1)}
                disabled={page === commentData?.totalPages}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          )}
        </CustomTabPanel>

        {/* {type === "proj" && (
          <CustomTabPanel value={value} index={1}>
            <div className="d_flex align_items_center justify_content_between">
              <h4>Pending Task</h4>
              <Button className="icon_button d_flex align_items_center primary_bg text_white">
                <AddIcon className="btn_icon" />
                Add New
              </Button>
            </div>

           
            <ComingSoon />
          </CustomTabPanel>
        )} */}
        {type === "proj" && (
          <CustomTabPanel value={value} index={1}>
            {/* <ComingSoon /> */}

            {projectData?.email?.map((item, index) => (
              <ProjectSentMail
                key={index}
                date={item.createdOn}
                mailId={JSON.parse(item?.recipients)?.email}
                subject={item.description}
              />
            ))}

            {/* <ProjectSentMail
            date="7/12/23 at 10:42 am"
            mailId="xyzab@cde.com"
            subject="Your solar project is underway"
          /> */}
          </CustomTabPanel>
        )}
      </div>
    </div>
  );
}
