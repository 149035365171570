import { useState } from "react";
import CustomAutoComplete from "./CustomAutoComplete";

const AutoCompleteAsync = ({
  name,
  label,
  open,
  onOpen,
  onClose,
  options,
  loading,
  // register,
  isOptionEqualToValue,
  onChange,
  renderOption,
  value,
  getOptionLabel,
  sx,
  variant,
  fieldSize,
  disabled,
  multiple,
  inputValue,
  onInputChange,
  onBlur,
  required,
}) => {
  return (
    <CustomAutoComplete
      sx={
        sx ?? {
          width: "100%",
          marginBottom: "10px",
          marginTop: "10px",
        }
      }
      
      name={name}
      required={required}
      label={label}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={loading}
      options={options}
      onChange={onChange}
      // register={register}
      renderOption={renderOption}
      value={value}
      getOptionLabel={getOptionLabel}
      variant={variant}
      fieldSize={fieldSize}
      disabled={disabled ? true : false}
      multiple={multiple ? true : false}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onBlur={onBlur}
    />
  );
};

export default AutoCompleteAsync;
