import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardData: null,
    loading: false,
  },
  reducers: {
    updateDashboard(state) {
      state.loading = true;
      return state;
    },
    setDashboard: (state, action) => {
      state.dashboardData = action.payload;
      state.loading = false;
    },
    setDashboardLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
});

export const { updateDashboard, setDashboard, setDashboardLoadingFalse } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
