import parsePhoneNumber from "libphonenumber-js";
import { useEffect } from "react";

const PhoneNumberView = ({ phoneNumber }) => {
  const parsedNumber = parsePhoneNumber(phoneNumber);

  useEffect(() => {
    // console.log(
    //   "parsedNumber.countryCallingCode=----------->",
    //   parsedNumber.countryCallingCode
    // );
    // console.log(
    //   "parsedNumber.nationalNumber=----------->",
    //   parsedNumber.nationalNumber
    // );
  }, [parsedNumber]);

  return parsedNumber ? (
    <>
      {parsedNumber?.countryCallingCode && (
        <sapn>+{parsedNumber?.countryCallingCode}-</sapn>
      )}
      {parsedNumber?.nationalNumber && (
        <span>{parsedNumber.nationalNumber}</span>
      )}
    </>
  ) : (
    <>{phoneNumber}</>
  );
};

export default PhoneNumberView;
