import CustomRadioButton from "../../fields/radio/CustomRadioButton";
import BugReportIcon from "@mui/icons-material/BugReport";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextEditor from "../../fields/texteditor/TextEditor";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "../buttons/Button";
import Storage from "../../utils/Storage";
import { deleteFile, fileUpload } from "../../redux/slices/fileUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import DragDropUploader from "../../components/fileupload/DragDropUploader";
import { IconButton } from "@mui/material";
import CloseIcon from "../../assets/images/close";
import { createNewFeedback } from "../../redux/slices/feedbackSlice";
import { Quill, Delta } from "react-quill";
import LinearProgressBar from "../progress/LinearProgressBar";
import { setCreateSuccess } from "../../redux/slices/formSlice";

const schema = yup.object().shape({});

const Feedback = ({ onClose }) => {
  const [content, setContent] = useState(null);
  const [message, setMessage] = useState(null);
  const [feedbackType, setFeedbackType] = useState(null);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const currentFile = useSelector((state) => state.upload.currentFile);
  const qillRefs = useRef(null);
  const [isPurchaseOrderFile, setIsPurchaseOrderFile] = useState([]);
  const [progress, setProgress] = useState(100);
  const uploadLoading = useSelector((state) => state.upload.loading);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const handleFeedbackType = (type) => {
    setFeedbackType(type);
  };

  const handleMessage = (content, delta, source, editor) => {
    setContent(content);
    const descValue = editor.getText(content).replace(/\n/g, " ");

    setMessage(descValue);
  };

  const imageHandler = (e) => {
    const editor = qillRefs.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        // const url = URL.createObjectURL(input.files[0]);

        const formData = new FormData();

        formData.append(`files`, file);

        dispatch(
          fileUpload({
            url: `crm/org/${orgId}/files/upload`,
            data: formData,
            params: {},
          })
        );
      }

      // editor.insertEmbed(editor.getSelection(), "image", url);
    };
  };

  useEffect(() => {
    if (currentFile?.length > 0) {
      const editor = qillRefs.current.getEditor();
      currentFile.map((item) =>
        editor.insertEmbed(editor.getSelection(), "image", item.url)
      );
    }

    // editor.insertEmbed(editor.getSelection(), "image", url);
  }, [currentFile]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image", "link"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  // const fileDelete = (name) => {
  //   if (currentFile && currentFile?.files.length > 0) {
  //     const deletedUrl = currentFile.files
  //       .filter((file, index) => file.name === name)
  //       .map((item) => {
  //         return { url: item.url };
  //       });
  //     dispatch(
  //       deleteFile({
  //         url: `crm/org/${orgId}/files/delete`,
  //         data: deletedUrl,
  //         params: {},
  //       })
  //     );
  //   }
  // };

  const handleKeyDown = () => {
    currentFile?.map(async (a) => {
      if (!content.includes(a)) {
        /* here you can add your method according to your backend, I am 
          using strapi */
        dispatch(
          deleteFile({
            url: `crm/org/${orgId}/files/delete`,
            data: a?.url,
            params: {},
          })
        );
      }
      return true;
    });
  };

  const handleClose = () => {
    if (currentFile && currentFile?.length > 0) {
      const deletedUrl = currentFile.map((file) => {
        return {
          url: file.url,
        };
      });
      dispatch(
        deleteFile({
          url: `crm/org/${orgId}/files/delete`,
          data: deletedUrl,
          params: {},
        })
      );
    }
    onClose();
  };

  const onSubmit = (data) => {
    if (currentFile && currentFile?.length > 0) {
      data.files = currentFile.map((file, index) => {
        if (isPurchaseOrderFile.includes(file?.name)) {
          return {
            name: file?.name,
            url: file?.url,
            updated: true,
            isPurchaseOrder: true,
          };
        }

        return { name: file?.name, url: file?.url, updated: true };
      });
    } else {
      data.files = [];
    }
    data.message = message;
    data.name = userInfo?.name;
    data.email = userInfo?.email;
    data.feedbackType = feedbackType;
    if (uploadLoading) {
      dispatch(
        setCreateSuccess({
          message:
            "You can save the project only after the file upload is completed",
          createSuccess: true,
          statusCode: 600,
        })
      );
    } else {
      dispatch(
        createNewFeedback({
          url: `crm/org/${orgId}/mail/feedback`,
          data: data,
          params: {},
        })
      );
    }
  };

  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (clipboardData) {
        const items = clipboardData.items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();
            if (file) {
              handleImageUpload(file);
            }
          }
        }
      }
    };

    const handleImageUpload = (file) => {
      const formData = new FormData();
      formData.append("files", file);

      dispatch(
        fileUpload({
          url: `crm/org/${orgId}/files/upload`,
          data: formData,
          params: {},
        })
      );
    };

    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <>
      <div className="modal_header">
        <p className="modal_title ">Feedback</p>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal_body">
          <div className="feedack">
            <div className="marginB10">
              <p className="mt_0">Please select a feedback type</p>
              <CustomRadioButton
                buttonList={[
                  { label: "Issue", icon: <BugReportIcon /> },
                  { label: "Idea", icon: <TipsAndUpdatesIcon /> },
                  { label: "Other", icon: <SpatialTrackingIcon /> },
                ]}
                onChange={handleFeedbackType}
                feedbackType={feedbackType}
              />
            </div>

            <TextEditor
              name="meesage"
              label="Feedback Message"
              value={content}
              onChange={handleMessage}
              modules={modules}
              innerRef={qillRefs}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="modal_footer">
          <Button type="submit" className="primary_button">
            Send
          </Button>
        </div>
      </form>
    </>
  );
};

export default Feedback;
