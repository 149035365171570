import { takeLatest, put, fork, call } from "redux-saga/effects";
import { createItem, editItem, getItem } from "../../services/service";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";

import { setDialog } from "../slices/dialogSlice";
import {
  setDashboard,
  setDashboardLoadingFalse,
  updateDashboard,
} from "../slices/dashboardSlice";

function* onLoadDashboardAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setDashboard({
        ...response?.data,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setDashboardLoadingFalse());
  }
}

function* onLoadDashboard() {
  yield takeLatest(updateDashboard.type, onLoadDashboardAsync);
}

export const dashboardSagas = [fork(onLoadDashboard)];
