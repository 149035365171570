import React, { useEffect, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import { getGroupList, getUserListOrg } from "../../../redux/slices/orgSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loading from "../../../components/loading/Loading";
import MovingIcon from "@mui/icons-material/Moving";
import AutoCompleteAssignRoles from "../../../fields/autoComplete/AutoCompleteAssignRoles";
import Button from "../../../components/buttons/Button";
const roles = [
  {
    id: 3,
    name: "Admin",
    desc: "Sample description",
  },
  {
    id: 2,
    name: "Moderator",
    desc: "Sample description",
  },
  {
    id: 1,
    name: "Client",
    desc: "Sample description",
  },
];
const AssignRoles = () => {
  const { id } = useParams();
  const userList = useSelector((state) => state.org.userListOrg);
  const group = useSelector((state) => state.org.group);
  const dispatch = useDispatch();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [groupSelected, setGroupSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  //const createData = useSelector((state) => state.form.createData);
  const updateGroupsAndUsersofStatus = (data, type, statusId, reason) => {
    // if (reason == "selectOption") {
    //   let indexToEdit = null;
    //   let groupsData = [];
    //   let usersData = [];
    //   let statusToEdit = null;
    //   groupsAndUsersOfStatus.map((item, index) => {
    //     if (item?.status?.id == statusId) {
    //       indexToEdit = index;
    //       statusToEdit = item.status;
    //       groupsData = item.groups;
    //       usersData = item.users;
    //     }
    //   });
    //   let temp = statusGroupsUserData;
    //   temp.splice(indexToEdit, 1, {
    //     status: statusToEdit,
    //     groups:
    //       type === "groups"
    //         ? data.map((item) => {
    //             return {
    //               ...item,
    //               isActive: 1,
    //             };
    //           })
    //         : groupsData,
    //     users:
    //       type === "users"
    //         ? data.map((item) => {
    //             return {
    //               ...item,
    //               isActive: 1,
    //             };
    //           })
    //         : usersData,
    //   });
    //   setStatusGroupsUserData(temp);
    // } else if (reason == "removeOption") {
    //   let indexToEdit = null;
    //   let groupsData = [];
    //   let usersData = [];
    //   let statusToEdit = null;
    //   groupsAndUsersOfStatus.map((item, index) => {
    //     if (item.status.id == statusId) {
    //       indexToEdit = index;
    //       statusToEdit = item.status;
    //       groupsData = item.groups;
    //       usersData = item.users;
    //     }
    //   });
    //   let result = [];
    //   if (type == "groups") {
    //     result = groupsData.filter(
    //       (largeItem) =>
    //         !data.some((smallItem) => largeItem.id === smallItem.id)
    //     );
    //     data = [
    //       ...data,
    //       ...result.map((item) => {
    //         return {
    //           ...item,
    //           isActive: 0,
    //         };
    //       }),
    //     ];
    //   } else if (type == "users") {
    //     result = usersData.filter(
    //       (largeItem) =>
    //         !data.some((smallItem) => largeItem.id === smallItem.id)
    //     );
    //     data = [
    //       ...data,
    //       ...result.map((item) => {
    //         return {
    //           ...item,
    //           isActive: 0,
    //         };
    //       }),
    //     ];
    //   }
    //   let temp = statusGroupsUserData;
    //   temp.splice(indexToEdit, 1, {
    //     status: statusToEdit,
    //     groups: type === "groups" ? data : groupsData,
    //     users: type === "users" ? data : usersData,
    //   });
    //   setStatusGroupsUserData(temp);
    // }
  };

  const saveUsersAndGroupsData = () => {
    // dispatch(
    //   createStatusGroupUser({
    //     url: `/crm/org/${id}/status/map`,
    //     data: statusGroupsUserData.map((item) => {
    //       return {
    //         status: {
    //           id: item.status.id,
    //           name: item.status.name,
    //         },
    //         groups: item.groups,
    //         users: item.users,
    //       };
    //     }),
    //     params: { orgId: id },
    //     //params:{}
    //   })
    // );
  };
  const loadGroups = () => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${id}`,
        params: {},
      })
    );
  };
  const loadUsers = () => {
    dispatch(
      getUserListOrg({
        url: `/crm/user/organization/${id}/users`,
      })
    );
  };
  useEffect(() => {
    loadGroups();
  }, []);
  useEffect(() => {
    loadUsers();
  }, []);
  console.log("groups", group);
  return (
    <>
      <CardItem height="810px">
        <div className="card_container">
          {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
          <div style={{ padding: "20px" }}>
            <ToolBar title={"Assign Roles"}></ToolBar>
          </div>
          <div style={{ padding: "20px" }}>
            <div style={{ height: "650px", overflowY: "scroll" }}>
              <div className="org_list_wrapper">
                {roles.length > 0 ? (
                  //!statusLoading &&
                  roles?.map((role) => {
                    return (
                      <>
                        <div key={role.id} className="light_bg_border">
                          <div className="d_flex justify_content_between w_100">
                            <div className="w_100">
                              <div className="d_flex align_items_center">
                                <MovingIcon
                                  style={{ color: "#189d3d" }}
                                  className="org_icon"
                                />
                                <div>
                                  <h4 className="my_0">
                                    {role?.displayName
                                      ? role.displayName
                                      : role.name}
                                  </h4>
                                </div>
                              </div>
                              <div className="d_flex align_items_center  w_100">
                                <AutoCompleteAssignRoles
                                  saveData={() => {
                                    setShowSaveButton(true);
                                  }}
                                  saveUsersAndGroupsData={
                                    saveUsersAndGroupsData
                                  }
                                  updateGroupsAndUsersofStatus={
                                    updateGroupsAndUsersofStatus
                                  }
                                  // setStatusGroupsUserData={setStatusGroupsUserData}
                                  role={role}
                                  alreadyPresentGroups={
                                    role.groups ? role.groups : []
                                  }
                                  alreadyPresentUsers={
                                    role.users ? role.users : []
                                  }
                                  users={userList}
                                  groups={group}
                                  view="role"
                                  name="group"
                                  type="group"
                                  // label="Group"
                                  userLabel="Add Default Assignee"
                                  onGroupChange={(event, newValue, reason) => {
                                    if (reason === "clear") {
                                      setGroupSelected(null);
                                    }
                                    const groupData = [
                                      {
                                        role: {
                                          id: role?.id,
                                          name: role?.name,
                                        },
                                        groups: {
                                          id: newValue?.id,
                                          name: newValue?.name,
                                        },
                                      },
                                    ];
                                  }}
                                  onUserChange={(event, newValue, reason) => {
                                    if (reason === "clear") {
                                      setUserSelected(null);
                                    }
                                    const userData = [
                                      {
                                        group: {
                                          id: role?.groupId,
                                          name: role?.groupName,
                                        },
                                        user: {
                                          id: newValue?.id,
                                          name: newValue?.name,
                                        },
                                      },
                                    ];
                                    // dispatch(
                                    //   createStatusGroupUser({
                                    //     url: `/crm/org/${id}/groups/map/user`,
                                    //     data: userData,
                                    //     params: {},
                                    //   })
                                    // );
                                  }}
                                  value={
                                    role?.groupId && !role?.userId
                                      ? {
                                          userId: null,
                                          name: null,
                                          groupId: role?.groupId ?? null,
                                          groupName: role?.groupName ?? null,
                                        }
                                      : {
                                          userId: role?.userId ?? null,
                                          name: role?.userName ?? null,
                                          groupId: role?.groupId ?? null,
                                          groupName: role?.groupName ?? null,
                                        }
                                  }
                                  groupWidth="40%"
                                  userWidth="40%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <Loading />
                )}
              </div>
            </div>
            {showSaveButton && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="primary_button marginT10"
                  onClick={saveUsersAndGroupsData}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </CardItem>
    </>
  );
};

export default AssignRoles;
