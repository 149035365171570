import { call, fork, put, takeEvery } from "redux-saga/effects";
import { createItem, getItem } from "../../services/service";
import { setDialog } from "../slices/dialogSlice";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import { createModule, getModuleList, setModuleList } from "../slices/moduleSlice";

function* onLoadModuleListAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status === 200) {
      console.log("moduel get response",response);
      yield put(setModuleList([...response.data]));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}
function* onCreateModuleAsync({ payload }) {
    try {
      console.log('inside create module async',payload);
      const { url, data, params } = payload;
      console.log('payload',payload);
      const response = yield call(createItem, { url, data, params }); 
      console.log('response',response);
      yield put(
        setCreateSuccess({
          message:"Module added",
          createSuccess: response?.status === 201 ? true : false,
          statusCode: response?.status,
        })
      );
    } catch (error) {
      console.log('error',error);
      if (error.message.includes("Firebase ID token has expired.")) {
        yield put(setDialog(true));
      } else {
        yield put(
          setCreateFailed({
            ...error?.errors?.data,
            createSuccess: false,
            statusCode: error.errors.status,
          })
        );
      }
    }
  }

function* getModuleData() {
  yield takeEvery(getModuleList.type, onLoadModuleListAsync);
}

function* createModuleData(){
    yield takeEvery(createModule.type,onCreateModuleAsync)
}


export const moduleSagas = [
    fork(getModuleData),
    fork(createModuleData)
];
