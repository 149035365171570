import { useDispatch, useSelector } from "react-redux";
import Button from "../components/buttons/Button";
import { getUserLogout } from "../redux/slices/authSlice";
import ResponsiveAppBar from "../components/header/Header";
import Storage from "../utils/Storage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NoPermission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(getUserLogout());
  };

  return (
    <div className="no_permission">
      <ResponsiveAppBar />

      <div className="d_flex flex_column align_items_center justify_content_center h_100">
        <p>
          You don't have any permission in this organization. Please Contact
          your admin to allow permissions
        </p>
        <Button onClick={logout} className="primary_button">
          Logout
        </Button>
      </div>
    </div>
  );
};

export default NoPermission;
