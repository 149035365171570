import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBomList } from "../../../../redux/slices/materialSlice";
import Storage from "../../../../utils/Storage";
import Button from "../../../buttons/Button";
import { modalStyle } from "../../../constants/ConstData";
import CurrencyView from "../../../currency/CurrencyView";
import CardTitle from "../../../dashboard/CardTitle";
import EmptyList from "../../../emptylist/EmptyList";
import Loading from "../../../loading/Loading";
import BasicModal from "../../../modal/Modal";
import BOMForm from "./BOMForm";
import BOMFormItem from "./BOMFormItem";
// import { generatePdf } from "../../../../utils/generatePdf";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";
const dealBreakdownList = [
  {
    name: "SOLAREDGE S440 POWER OPTIMIZERS",
    quantity: 29,
  },
  {
    name: "SOLAREDGE SE10000H-US (240V/10000W) INVERTER",
    quantity: 1,
  },
  {
    name: "EZ SOLARDECK JUNCTION BOX 2, EZSLR, JB-1.2",
    quantity: 1,
  },
];

const BOMCard = ({ title, type, folderList, projectData, isAdder }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenEdit, setIsOpenEdit] = React.useState(false);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const { id } = useParams();
  const bomList = useSelector((state) => state.material.bomList);
  const bomLoading = useSelector((state) => state.material.bomLoading);
  const fileList = useSelector((state) => state.upload.fileList);
  const [totalBomPrice, setTotalBomPrice] = React.useState(0);

  const [editItem, setEditItem] = React.useState(null);

  const dispatch = useDispatch();

  const loadBom = () => {
    const baseUrl = `/crm/org/${orgId}/proj/${id}/bom`;
    const url = type === "adder" ? `${baseUrl}/adder` : baseUrl;
    dispatch(getBomList({ url: url, params: {} }));
  };

  const newBOM = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const downloadAsPdf = (e) => {
    e.preventDefault();
    let itemDetailsHeaders = [
      "Sl.No",
      "Item Name",
      "Price",
      "Unit",
      "Quantity",
      "Total",
    ];
    console.log("bomList[0]?.materials", bomList[0]?.materials);
    const itemDetailsRows = bomList[0]?.materials?.map((item, index) => [
      (index + 1)?.toString(),
      item.name?.toString(),
      item.price?.toString(),
      item?.uom?.toString(),
      item.qty?.toString(),
      (Number(item.price) * Number(item?.qty)).toString(),
    ]);
    let columnWidths = [15, 50, 30, 30, 30, 30];
    // Define table styles
    const headerStyles = {
      fillColor: [240, 240, 240],
      textColor: [0],
      fontFamily: "Newsreader",
      fontStyle: "bold",
    };
    generatePdf(
      itemDetailsHeaders,
      itemDetailsRows,
      columnWidths,
      headerStyles
    );
  };

  const generatePdf = async (
    headerRow,
    itemsData,
    columnWidths,
    headStyles
  ) => {
    const orgLogo = currentOrg?.logodtls;
    const pdf = new jsPDF();
    const imageUrl = orgLogo;
    let encodedUrl = await urlToBase64(orgLogo);
    pdf.addImage(imageUrl, "JPEG", 10, 5, 40, 12);
    pdf.setFontSize(10);
    pdf.setFont("custom", "bold");
    pdf.setFontSize(16);
    pdf.setFont("Newsreader", "bold");
    const customerNameY = 15;
    pdf.setFontSize(12);
    let orgName = currentOrg?.name;
    console.log("orgName", orgName);
    pdf.text("Organization Name: " + orgName, 13, customerNameY);
    pdf.setFontSize(10);
    // pdf.text("Customer Name: " + "customer name", 130, customerNameY);
    // pdf.text("Customer ID: " + "customer id", 130, customerNameY + 5);

    // pdf.text(orgName, 13, 18);
    pdf.setFontSize(10);
    let customerFirstName = projectData?.customerInfo?.pContact?.firstName;
    let customerLastName = projectData?.customerInfo?.pContact?.lastName
      ? projectData?.customerInfo?.pContact?.lastName
      : "";
    let customerFullName = customerFirstName + " " + customerLastName;
    let area = projectData?.addressDetails?.area;
    let city = projectData?.addressDetails?.city;
    let state = projectData?.addressDetails?.state;
    let country = projectData?.addressDetails?.country;

    pdf.text(customerFullName, 130, customerNameY); // Example customer detail
    pdf.text(area + " , " + city, 130, customerNameY + 4); //
    pdf.text(state + " , " + country, 130, customerNameY + 9);
    // pdf.text(state,130,customerNameY+14);
    // pdf.text(country,130,customerNameY+19);
    //pdf.text("BOM Details", 78, 15);
    const itemDetailsYStart = 28;
    pdf.autoTable({
      head: [headerRow],
      body: itemsData,
      startY: itemDetailsYStart, // Adjust the Y position as needed
      headStyles: {
        fillColor: headStyles.fillColor,
        textColor: headStyles.textColor,
        fontStyle: headStyles.fontStyle,
        fontSize: 10, // Adjust the font size as needed
        font: "Newsreader", // Set the font family
        halign: "left",
      },
      columnStyles: {
        0: { cellWidth: columnWidths[0] }, // Adjust column widths as needed
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        4: { cellWidth: columnWidths[4] },
      },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      bodyStyles: {
        fontSize: 10, // Adjust the font size for the body
        font: "Newsreader", // Set the font family for the body
        cellPadding: { top: 1, right: 5, bottom: 1, left: 2 }, // Adjust cell padding
        textColor: [0, 0, 0], // Set text color for the body
        rowPageBreak: "avoid", // Avoid row page breaks
      },
      //margin: { top: 10, left: 13 },
    });
    const summaryYStart = pdf.internal.pageSize.getHeight() - 50;

    // pdf.setFont('Newsreader', 'noraml')
    // pdf.text('Thanking You,', 13, summaryYStart + 20)
    // pdf.text('Yours Faithfully,', 13, summaryYStart + 24)
    // pdf.text('For ', 13, summaryYStart + 28)
    // pdf.setFont('Newsreader', 'bold')
    // pdf.text('Aalam Info Solutions LLP', 19, summaryYStart + 28)

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.line(10, 283, 200, 283);
      pdf.setPage(i);
      pdf.setFont("Newsreader");
      // pdf.text(
      //     `Page ${i} of ${totalPages}`,
      //     185,
      //     pdf.internal.pageSize.getHeight() - 5
      // );
    }
    pdf.save(`BOM.pdf`);
    // const pdfDataUri = pdf.output('datauristring');
    //    const newTab = window.open();
    //   newTab?.document.write(
    //     `<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`
    //   );

    // const containsName = folderList?.some(
    //   (item) => item.name === 'BOM'
    // );
    // if (containsName) {
    //     //upload pdf file into BOM folder
    //     const formData = new FormData();
    //     const pdfBlob=pdf.output('blob')
    //    // formData.append("logo", files[0]);
    //     formData.append('files', pdfBlob, 'BOM.pdf');
    //     let folderDetails=folderList.filter((item)=>item.name=='BOM');
    //     console.log('folder details',folderDetails);
    //     dispatch(
    //       singleFileUpload({
    //         url: `/crm/org/${orgId}/proj/${id}/file/${'BOM'}/${folderDetails[0].id}`,
    //         data: formData,
    //         params: {},
    //         onUploadProgress:(progress) => {
    //           console.log("handleProgress=------->", progress);
    //         }
    //       })
    //     );
    // }
  };
  async function urlToBase64(s3ImageUrl) {
    try {
      console.log("inside url to base 64");
      const response = await fetch(s3ImageUrl, {
        headers: {
          origin: "http//localhost:8084",
        },
      });
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Data = reader.result;
        // Use the base64Data for your PDF generation
        console.log("base64Data", base64Data);
        return base64Data;
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  const handleEdit = (item) => {
    console.log("Item=---------->", item);
    setEditItem(item);

    setIsOpenEdit(true);
  };

  const handleClose = () => {
    setEditItem(null);
    setIsOpen(false);
    setIsOpenEdit(false);
  };

  React.useEffect(() => {
    if (orgId) {
      loadBom();
    }
  }, []);

  React.useEffect(() => {
    if (snackbarData?.createSuccess) {
      handleClose();
      loadBom();
    }
  }, [snackbarData]);

  React.useEffect(() => {
    console.log("bomList=------------->", bomList);
  }, [bomList]);

  React.useEffect(() => {
    const totalValue = bomList[0]?.materials?.reduce((accumulator, item) => {
      return accumulator + item.qty * item.price;
    }, 0);
    setTotalBomPrice(totalValue);
  }, [bomList]);

  // React.useEffect(() => {
  //   if (folderList) {
  //     const containsName = folderList?.some((item) => item.name === "BOM");

  //     console.log("containsName", containsName);

  //       //if no folder named BOM create a folder
  //       const newData = { folders: [{ name: "BOM", isactive: 1 }] };
  //       dispatch(
  //         createNewFolder({
  //           // /crm/org/{orgId}/proj/{projectId}/file/folder
  //           url: `/crm/org/${orgId}/proj/${id}/file/folder`,
  //           data: newData, //upload pdf file into BOM folder
  //           params: {},
  //         })
  //       );
  //     } else {
  //       const folderDetails = folderList?.filter((item) => item.name === "BOM");
  //       console.log("folder details", folderDetails);
  //       dispatch(
  //         getFileList({
  //           url: `/crm/org/${orgId}/proj/${id}/file/folder/${folderDetails[0].id}`,
  //         })
  //       );
  //     }
  //   }
  // }, []);

  if (bomLoading) {
    return <Loading />;
  }

  return (
    <>
      <CardTitle
        title={totalBomPrice > 0 && <CurrencyView amount={totalBomPrice} />}
        type="project_view"
        style={{ padding: "20px 0" }}
      >
        <div>
          <div className="d_flex">
            <div style={{ marginRight: "10px" }}>
              {bomList[0]?.materials?.length > 0 && type === "bom" && (
                <>
                  <Tooltip title="Generate pdf" placement="top-start">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={downloadAsPdf}
                    >
                      <PictureAsPdfIcon className="btn_icon" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
            <div className="d_flex " style={{ alignItems: "center" }}>
              <Button
                onClick={newBOM}
                className="icon_button d_flex align_items_center primary_bg text_white"
              >
                <AddIcon className="btn_icon" />
                {t("Add New")}
              </Button>
            </div>
          </div>
        </div>
      </CardTitle>
      {bomList[0]?.materials?.length > 0 ? (
        bomList[0]?.materials?.map((item, index) => (
          <Accordion
            key={index}
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              background: "rgba(55, 144, 107, 0.08)",
              border: "none",
              boxShadow: "none",
              marginBottom: "10px",
              ":before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#189d3d",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="w_100 d_flex align_items_center justify_content_between">
                <Typography
                  sx={{
                    color: "#189d3d",
                  }}
                  className="font_12_green_600"
                >
                  {item?.name ?? item?.itemName}
                </Typography>

                <CurrencyView amount={item?.price * item?.qty} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d_flex align_items_center justify_content_between">
                <Typography className="m_0 font_12">
                  <b>{t("Price")}:</b> {item?.price}
                </Typography>
                <Typography className="m_0 font_12">
                  <b>{t("Quantity")}:</b> {item?.qty}
                </Typography>
                <Typography className="m_0 font_12">
                  <b>{t("Unit")}:</b> {item?.uom}
                </Typography>
              </div>
              <div className="d_flex align_items_center justify_content_end">
                <IconButton size="small" onClick={() => handleEdit(item)}>
                  <Edit />
                </IconButton>
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <EmptyList
          icon={<DeveloperBoardOffIcon />}
          message={`No ${title} found`}
        />
      )}
      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <BOMForm
          onClose={handleClose}
          // editItem={editItem}
          view={bomList[0]?.materials.length > 0 ? "edit" : "new"}
          bomId={bomList[0]?.id}
          existingMaterials={bomList[0]?.materials}
          type={type}
        />
      </BasicModal>
      <BasicModal
        isOpen={isOpenEdit}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <BOMFormItem
          onClose={handleClose}
          editItem={editItem}
          bomId={bomList[0]?.id}
          existingMaterials={bomList[0]?.materials}
        />
      </BasicModal>
    </>
  );
};

export default BOMCard;
