import React, { useState } from "react";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import { Box, Grid, IconButton } from "@mui/material";
import Button from "../../../components/buttons/Button";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/images/close";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
const AssignRolesModal = ({ open, close, groups, users,RoleName }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  console.log('RoleName',RoleName);
  const [openGroupsDropDown,setOpenGroupsDropDown]=useState(false);
  const [openUsersDropDown,setOpenUsersDropDown]=useState(false);
  const assignRole = () => {};
  return (
    <BasicModal
      isOpen={open}
      onClose={close}
      boxStyle={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "42%",
        bgcolor: "background.paper",
        boxShadow: 24,
        height: "400px",
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 768px)": {
          // Adjust the max-width as needed
          width: "80%", // Width for mobile devices
        },
      }}
      sx={modalStyle}
    >
      <>
        <div className="modal_header">
          <p className="modal_title ">{RoleName} {">>"}Assign Roles</p>
          <div className="modal_header_button_wrapper">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className="modal_body">
          <div
            className="organisation"
            style={{ display: "block", padding: "0px" }}
          >
            <div className="">
              <form noValidate onSubmit={handleSubmit(assignRole)}>
                <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <label>Groups</label>
                    <CustomAutoComplete
                    multiple={true}
                    width={'100%'}
                      name="groupId"
                      className="user_access_level"
                      open={openGroupsDropDown}
                      onOpen={() => {
                        setOpenGroupsDropDown(true);
                      }}
                      onClose={() => {
                        setOpenGroupsDropDown(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value.id
                      }
                      // loading={loading}
                      options={groups}
                      onChange={(event, newValue) => {
                       // setUserSelected(newValue);
                        //setGroupSelected(newValue.id);
                      }}
                      register={register}
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option.name}
                        </Box>
                      )}
                      //value={groupSelected}
                      getOptionLabel={(option) => {
                        if (option.name) {
                          return option?.name;
                        } else {
                          return "Please Select";
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <label>Users</label>
                    <CustomAutoComplete
                    multiple={true}
                     width={'100%'}
                      name="groupId"
                      className="user_access_level"
                      open={openUsersDropDown}
                      onOpen={() => {
                        setOpenUsersDropDown(true);
                      }}
                      onClose={() => {
                        setOpenUsersDropDown(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value.id
                      }
                      // loading={loading}
                      options={users}
                      onChange={(event, newValue) => {
                       // setUserSelected(newValue);
                        //setGroupSelected(newValue.id);
                      }}
                      register={register}
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option.name}
                        </Box>
                      )}
                      //value={groupSelected}
                      getOptionLabel={(option) => {
                        if (option.name) {
                          return option?.name;
                        } else {
                          return "Please Select";
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Button type="submit" className="primary_button marginT10">
                  Add Status
                </Button>
              </form>
            </div>
          </div>
        </div>
        {/* </form> */}
      </>
    </BasicModal>
  );
};

export default AssignRolesModal;
