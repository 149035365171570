import { useEffect } from "react";
import Button from "../../components/buttons/Button";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  getSignInWithFacebook,
  getSignInWithGoogle,
  getUserLogin,
} from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/loading/Loading";
import { IconButton } from "@mui/material";
import GoogleIcon from "../../assets/images/google.svg";
import FacebookIcon from "../../assets/images/facebook.svg";
import LinkedInIcon from "../../assets/images/linkedin.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LeftBanner from "./LeftBanner";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email address is invalid")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be 8 characters at minimum")
    .max(32, '"Password must be 32 characters at maximum"')
    .required("Password is required"),
});

const Login = () => {
  const {t}=useTranslation();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    dispatch(getUserLogin(data));
  };

  const signInWithGoogle = () => {
    dispatch(getSignInWithGoogle());
  };

  const signInWithFacebook = () => {
    dispatch(getSignInWithFacebook());
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="login">
      <LeftBanner />
      <div className="login_right">
        <div className="login_form_wrapper">
          <h2 className="lets_started">{t("Member Login")}</h2>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            {error && (
              <p className="error_message">Incorrect Email or Password </p>
            )}
            <label className="text_medium">Email</label>
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              })}
            />

            <p className="error_message">{errors.email?.message}</p>
            <label className="text_medium">Password</label>
            <input
              type="password"
              name="password"
              {...register("password", { required: true, minLength: 6 })}
            />
            <p className="error_message">{errors.password?.message}</p>

            <div className="d_flex align_items_center justify_content_between">
              <label className="custom_checkbox">
                <input
                  type="checkbox"
                  name="rememberMe"
                  {...register("rememberMe")}
                />
                Remember me
                <span className="greenmark"></span>
              </label>
              <label className="font_14_dark">
                Trouble signing in?{" "}
                <Link className="terms_text" to="/passwordreset">
                  Forgot Password
                </Link>
              </label>
            </div>

            <Button type="submit" className="button_lg primary_button">
              Sign In
            </Button>
            <p className="text_medium text_light_gray text_center">
              New to our platform?{" "}
              {/* <Link className="terms_text" to="/register">
                Create an Account
              </Link> */}
              <Link
                className="terms_text"
                to="https://outlook.office.com/bookwithme/user/ad144e10f8ae4c0685ab98b43041f27a@energyscaperenewables.com?anonymous&ep=pcard"
              >
                Book a demo
              </Link>
            </p>
          </form>
          {/* <p className="hr_lines"> Or sign in with </p>
          <div className="d_flex align_items_center justify_content_center">
            <IconButton onClick={signInWithGoogle}>
              <img src={GoogleIcon} alt="Google" />
            </IconButton>
            <IconButton onClick={signInWithFacebook}>
              <img src={FacebookIcon} alt="Facebook" />
            </IconButton>
            <IconButton>
              <img src={LinkedInIcon} alt="LinkedIn" />
            </IconButton>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
