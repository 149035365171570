import React, { useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { COLUMN_NAMES_LIST } from "./constants";
import { tasks } from "./tasks";
import "./style.scss";
import UserDetails from "../../userdetails/UserDetails";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZoomInOutlined, ZoomOutOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Status from "../../status/Status";
import EditIcon from "../../../assets/images/projectedit.svg";
import { Link } from "react-router-dom";
import { updateProjectStatus } from "../../../redux/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import ProjectImage from "../../../assets/images/project.jpeg";
import DateView from "../../date/DateView";
import {
  setCreateSnackbar,
  setCreateSuccess,
} from "../../../redux/slices/formSlice";
import CustomDialog from "../../dialog/CustomDialog";
import Storage from "../../../utils/Storage";
import IconText from "../../common/IconText";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import CurrencyView from "../../currency/CurrencyView";
import { useDialog } from "../../../hooks/useDialog";

const MovableItem = ({
  projectItem,
  name,
  // address,
  // stageName,
  // date,
  index,
  // currentColumnName,
  // itemId,
  moveCardHandler,
  setItems,
  fontSize,
  expandView,
  status,
  columnList,
  // statusId,
  // customerInfo,
}) => {
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [stId, setStId] = useState();
  // const [showHover, setShowHover] = useState(false);
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const isOwner =
    Storage.read(`org${userInfo?.id}`)?.isOwner === "true" ? true : false;
  const isModerator =
    Storage.read(`org${userInfo?.id}`)?.isModerator === "true" ? true : false;

  const {
    isOpen,
    openDialog,
    closeDialog,
    title,
    content,
    okLabel,
    cancelLabel,
    handleOk,
    handleCancel,
  } = useDialog();

  const changeItemColumn = (currentItem, columnName) => {
    setItems((prevState) => {
      return prevState.map((e) => {
        return {
          ...e,
          column: e.id === currentItem.itemId ? columnName : e.column,
        };
      });
    });
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "Our first type",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 1;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const loadSnackbar = (id) => {
    openDialog({
      newTitle: "Project Already Exists",
      newContent:
        "This project already exist in this. Do you want to close (delete) the current status?",
      okLabel: "Delete this status",
      cancelLabel: "Cancel",
      onOk: closeStatus,
      onCancel: handleCancel,
    });
    setStId(id);

    // dispatch(
    //   setCreateSuccess({
    //     message: "This Project already exist in this status",
    //   })
    // );
  };

  const [, drag] = useDrag({
    type: "Our first type",
    canDrag: () => (isOwner | isModerator ? true : false),
    item: {
      index,
      name,
      currentColumnName: projectItem?.column,
      itemId: projectItem?.id,
      statusId: projectItem?.statusId,
      address: projectItem?.address,
      customerInfo: projectItem?.customerInfo,
      checklistStatus: projectItem?.checklist_status,
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      const colNameList = columnList.map((item, index) => item.name);
      if (item?.checklistStatus === "Checklist Completed") {
        if (dropResult) {
          const { name, id, uid, userName, groups, users } = dropResult;

          openDialog({
            newTitle: "Update Status",
            newContent: `Are you sure, you want to move this project from ${item.currentColumnName} to ${name}?`,
            okLabel: "Ok, Update",
            cancelLabel: "Cancel",
            onOk: () => {
              if (colNameList.includes(name)) {
                const data = [
                  {
                    customerInfo: item?.customerInfo,
                    address: item?.address,
                    prevStatusId: item.statusId,
                    status: {
                      name: name,
                      id: id,
                      uid: uid,
                      userName: userName,
                    },
                    assignedUser:
                      users?.length > 0 ? users?.map((user) => user?.id) : [0],
                    groupId:
                      groups?.length > 0
                        ? groups?.map((group) => group?.id)
                        : [0],
                  },
                ];

                dispatch(
                  updateProjectStatus({
                    url: `crm/org/${orgId}/proj/${projectItem?.id}/status`,
                    data: data,
                    params: {},
                  })
                );

                // changeItemColumn(item, name);
              }
            },
            onCancel: handleCancel,
          });

          // const { DO_IT, IN_PROGRESS, AWAITING_REVIEW, DONE } = COLUMN_NAMES;
          // switch (name) {
          //   case colNameList[0]:
          //     changeItemColumn(item, colNameList[0]);
          //     break;
          //   case colNameList[1]:
          //     changeItemColumn(item, colNameList[1]);
          //     break;
          //   case colNameList[2]:
          //     changeItemColumn(item, colNameList[2]);
          //     break;
          //   case colNameList[3]:
          //     changeItemColumn(item, colNameList[3]);
          //     break;
          //   default:
          //     break;
          // }
        } else {
          loadSnackbar(item?.statusId);
        }
      } else {
        dispatch(
          setCreateSnackbar({
            message:
              "You have some pending checklist for this status. Please update the checklist and try again",
            createSuccess: true,
            statusCode: 600,
          })
        );
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  const closeStatus = () => {
    dispatch(
      updateProjectStatus({
        url: `crm/org/${orgId}/proj/${projectItem?.id}/status/${stId}/close`,
        data: {},
        params: {},
      })
    );
    setDialogOpen(false);
  };

  // const cancelClose = () => {
  //   setDialogOpen(false);
  // };

  // const handleHoverView = () => {
  //   setShowHover(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowHover(false);
  // };

  return (
    <>
      <div
        ref={ref}
        style={{
          padding: "10px",
          width: "100%",
          height: expandView ? "150px" : "150px",
          backgroundColor: "#fff",
          // filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10))",

          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "8px",
          marginBottom: "8px",
          position: "relative",
        }}
        // onMouseOver={handleHoverView}
        // onMouseLeave={handleMouseLeave}
      >
        {/* {showHover && (
          <div
            style={{
              position: "absolute",
              top: "-100px",
              left: "0px",
              backgroundColor: "#fff",
              zIndex: "9",
              padding: "15px",
              filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.10))",
              width: "270px",
            }}
          >
            <IconText
              className="draggable_project_card_text"
              style={{ fontSize: `${fontSize.title}px` }}
              icon={<PersonIcon fontSize="small" className="marginR10" />}
              title={name}
            />
            <IconText
              className="draggable_project_card_text"
              style={{ fontSize: `${fontSize.title}px` }}
              icon={<PlaceIcon fontSize="small" className="marginR10" />}
              title={address}
            />
          </div>
        )} */}
        <Link to={`/projects/${projectItem?.id}`}>
          <div className="d_flex justify_content_between ">
            <div>
              <p
                style={{ fontSize: `${fontSize.title}px` }}
                className="draggable_project_card_text"
                // title={name}
              >
                {name?.length > 15 ? name?.substring(0, 20) + "..." : name}
              </p>
              <p style={{ fontSize: `${fontSize.title}px` }}>
                {projectItem?.address}
              </p>
            </div>
            <UserDetails image={ProjectImage} />
          </div>
          <p
            style={{ fontSize: `${fontSize.date}px`, margin: 0 }}
            className="draggable_project_card_date"
          >
            <small>
              <DateView date={projectItem?.createdAt} />
            </small>
          </p>
          <div className="">
            {/* {expandView && ( */}

            {/* )} */}
          </div>
          <div style={{ position: "absolute", bottom: "10px" }}>
            <Status
              status={projectItem?.stagesname ?? ""}
              statusType="success"
            />
          </div>
        </Link>
      </div>
      <CustomDialog
        open={isOpen}
        title={title}
        message={content}
        okText={okLabel}
        cancelText={cancelLabel}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

const Column = ({
  children,
  className,
  title,
  columnName,
  totalSystemCost,
  totalSystemSize,
  colWidth,
  fontSize,
  expandView,
  count,
  columnId,
  projectList,
  uid,
  userName,
  groups,
  users,
}) => {
  const dispatch = useDispatch();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "Our first type",
    drop: () => ({
      name: columnName,
      id: columnId,
      uid: uid,
      userName: userName,
      groups: groups,
      users: users,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    // Override monitor.canDrop() function
    canDrop: (item) => {
      const { currentColumnName, itemId } = item;

      const existingProjectId = projectList
        .filter((item) => item.column === columnName)
        .map((items) => items.id);
      const idExist = existingProjectId?.includes(itemId);
      if (currentColumnName !== columnName && idExist) {
        return false;
      } else {
        return true;
      }
    },
  });

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return "rgb(138 225 162)";
      } else if (!canDrop) {
        return "rgb(255,188,188)";
      }
    } else {
      return "";
    }
  };
  const adjustFontSize = (element) => {
    console.log("fontSize.title", fontSize.title);

    const maxFontSize = fontSize.title; // maximum font size in px
    const minFontSize = 9; // minimum font size in px
    const contentLength = element.innerText.length;

    // Calculate font size based on content length
    let newFontSize = fontSize.title;
    if (element.innerText.length <= 18) {
      newFontSize = fontSize.title;
    } else {
      newFontSize = Math.max(minFontSize, maxFontSize - contentLength / 10);
    }
    element.style.fontSize = `${newFontSize}px`;
  };

  return (
    <div
      ref={drop}
      className={className}
      style={{ backgroundColor: getBackgroundColor(), width: `${colWidth}px` }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // justifyContent: expandView ? "space-between" : "center",
          width: "100%",
          height: "40px",
          overflow: "hidden",
          backgroundColor: "#fff",
          filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.10))",
          borderRadius: "8px",
          marginBottom: "8px",
          padding: "10px",
        }}
      >
        {title.split("").length >= 18 && title.split("").length <= 48 ? (
          <>
            <Tooltip title={title} style={{ fontSize: "14px" }}>
              <p
                style={{ color: "#189d3d", fontSize: `${fontSize.title}px` }}
                className="draggable_project_card_text d_flex align_items_center"
                ref={(el) => {
                  if (el) {
                    adjustFontSize(el);
                  }
                }}
              >
                {title}

                {expandView && (
                  <span
                    className="d_flex align_items_center justify_content_center marginL10"
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      border: "1px solid #EBEBEB",
                      color: "#192F1E",
                      fontSize: "8px",
                    }}
                  >
                    {count}
                  </span>
                )}
              </p>
            </Tooltip>
          </>
        ) : title.split("").length > 48 ? (
          <>
            <Tooltip title={title} style={{ fontSize: "14px" }}>
              <p
                style={{ color: "#189d3d", fontSize: `${fontSize.title}px` }}
                className="draggable_project_card_text d_flex align_items_center"
                ref={(el) => {
                  if (el) {
                    adjustFontSize(el);
                  }
                }}
              >
                {title.slice(0, 18) + "..."}

                {expandView && (
                  <span
                    className="d_flex align_items_center justify_content_center marginL10"
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      border: "1px solid #EBEBEB",
                      color: "#192F1E",
                      fontSize: "8px",
                    }}
                  >
                    {count}
                  </span>
                )}
              </p>
            </Tooltip>
          </>
        ) : (
          <>
            <p
              style={{ color: "#189d3d", fontSize: `${fontSize.title}px` }}
              className="draggable_project_card_text d_flex align_items_center"
              ref={(el) => {
                if (el) {
                  adjustFontSize(el);
                }
              }}
            >
              {title}

              {expandView && (
                <span
                  className="d_flex align_items_center justify_content_center marginL10"
                  style={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    border: "1px solid #EBEBEB",
                    color: "#192F1E",
                    fontSize: "8px",
                  }}
                >
                  {count}
                </span>
              )}
            </p>
          </>
        )}

        <p
          style={{ fontSize: `${fontSize.title}px` }}
          className="m_0 draggable_project_card_text"
        >
          <CurrencyView amount={totalSystemCost} />
        </p>
        <p
          style={{ fontSize: `${fontSize.title}px` }}
          className="m_0 draggable_project_card_text"
        >
          {totalSystemSize} <span>kW</span>
        </p>
        {/* {expandView && (
          <div className="d_flex align_items_center justify_content_center">
            <IconButton>
              <img src={EditIcon} alt="Edit" />
            </IconButton>
            <div className="dot_kebab"></div>
          </div>
        )} */}
      </div>

      {children}
    </div>
  );
};

export const ProjectListItem = ({ projectDashboard }) => {
  const [colWidth, setColWidth] = useState(140);
  const [fontSize, setFontSize] = useState({ title: 10, date: 10 });
  const [expandView, setExpandView] = useState(false);

  const dispatch = useDispatch();
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const searchValue = useSelector((state) => state.search.searchValue);

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const [items, setItems] = useState(projectDashboard?.list);
  const [columnList, setColumnList] = useState(projectDashboard?.head);
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    if (projectDashboard) {
      setItems(projectDashboard?.list);
      const head = projectDashboard?.head?.map((item) => {
        let matchingItems = projectDashboard?.list?.filter(
          (entry) => entry.statusId === item.id
        );
        let totalSystemCost = matchingItems.reduce(
          (sum, entry) => sum + entry.systemCost,
          0
        );
        let totalSystemSize = matchingItems.reduce(
          (sum, entry) => sum + entry.systemSize,
          0
        );

        return {
          ...item,
          totalSystemCost: totalSystemCost,
          totalSystemSize: totalSystemSize,
        };
      });
      setColumnList(head);
    }
  }, [projectDashboard]);

  useEffect(() => {
    if (searchValue !== "") {
      const newList = items?.filter(
        (entry) =>
          entry?.address?.toLowerCase().includes(searchValue) ||
          entry?.stagesname?.toLowerCase().includes(searchValue) ||
          (entry?.firstname + " " + entry?.lastname)
            .toLowerCase()
            .includes(searchValue)

        // Object.values(entry).some(
        //   (val) =>
        //     typeof val === "string" &&
        //     val.toLowerCase().includes(searchValue.toLowerCase())
        // )
      );
      setSearchList(newList);
    } else {
      setItems(projectDashboard?.list);
    }
  }, [searchValue]);

  const [settings, setSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,

    customPaging: (i) => <div className="slick_page_no">{i + 1}</div>,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });

  const handleZoomIn = () => {
    if (colWidth < 220) {
      if (colWidth >= 180) {
        setExpandView(true);
      }
      setColWidth((prev) => prev + 20);
      setFontSize((prev) => {
        return { title: prev.title + 1, date: prev.date + 1 };
      });
      setSettings((prev) => {
        return {
          ...prev,
          slidesToShow: prev.slidesToShow - 1,
          slidesToScroll: prev.slidesToScroll - 1,
          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: prev.slidesToShow - 1,
                slidesToScroll: prev.slidesToScroll - 1,
              },
            },
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: prev.slidesToShow - 1,
                slidesToScroll: prev.slidesToScroll - 1,
              },
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: prev.slidesToShow - 1,
                slidesToScroll: prev.slidesToScroll - 1,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: prev.slidesToShow - 1,
                slidesToScroll: prev.slidesToScroll - 1,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: prev.slidesToShow - 1,
                slidesToScroll: prev.slidesToScroll - 1,
              },
            },
            {
              breakpoint: 750,
              settings: {
                slidesToShow: prev.slidesToShow - 1,
                slidesToScroll: prev.slidesToScroll - 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: prev.slidesToShow - 1,
                slidesToScroll: prev.slidesToScroll - 1,
              },
            },
          ],
        };
      });
    }
  };

  const handleZoomOut = () => {
    if (colWidth > 140) {
      if (colWidth < 220) {
        setExpandView(false);
      }

      setColWidth((prev) => prev - 20);
      setFontSize((prev) => {
        return { title: prev.title - 1, date: prev.date - 1 };
      });
      setSettings((prev) => {
        return {
          ...prev,
          slidesToShow: prev.slidesToShow + 1,
          slidesToScroll: prev.slidesToScroll + 1,
          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: prev.slidesToShow + 1,
                slidesToScroll: prev.slidesToScroll + 1,
              },
            },
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: prev.slidesToShow + 1,
                slidesToScroll: prev.slidesToScroll + 1,
              },
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: prev.slidesToShow + 1,
                slidesToScroll: prev.slidesToScroll + 1,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: prev.slidesToShow + 1,
                slidesToScroll: prev.slidesToScroll + 1,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: prev.slidesToShow + 1,
                slidesToScroll: prev.slidesToScroll + 1,
              },
            },
            {
              breakpoint: 750,
              settings: {
                slidesToShow: prev.slidesToShow + 1,
                slidesToScroll: prev.slidesToScroll + 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: prev.slidesToShow + 1,
                slidesToScroll: prev.slidesToScroll + 1,
              },
            },
          ],
        };
      });
    }
  };

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem =
      searchValue !== "" ? searchList[dragIndex] : items[dragIndex];

    if (dragItem) {
      setItems((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  };

  const returnItemsForColumn = (columnName, displayName, fontSize) => {
    const newItems = searchValue !== "" ? searchList : items;
    return newItems
      ?.filter((item) => item.column === columnName)
      .map((item, index) => (
        <React.Fragment key={item?.id}>
          <MovableItem
            key={index}
            name={`${item?.firstname} ${item?.lastname}`}
            projectItem={item}
            // date={item?.createdAt}
            // address={item?.address}
            // stageName={item?.stagesname}
            // currentColumnName={item?.column}
            // itemId={item?.id}
            // statusId={item?.statusId}
            // customerInfo={item?.customerInfo}
            status={displayName}
            // statusType={item.statusType}
            setItems={setItems}
            index={index}
            moveCardHandler={moveCardHandler}
            fontSize={fontSize}
            expandView={expandView}
            columnList={columnList}
          />
        </React.Fragment>
      ));
  };

  const sliderRef = useRef();
  const containerRef = useRef();

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  function onDragStartHandler(ev) {
    const evt = ev;
    var x = evt.pageX;
    var y = evt.pageY;

    if (x > window.innerWidth - 100) {
      gotoNext();
    }
    if (x < 300) {
      gotoPrev();
    }

    if (y < 300) {
      if (containerRef.current) {
        containerRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }

    if (y > window.innerHeight - 100) {
      if (containerRef.current) {
        containerRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }

  return columnList && columnList.length > 0 ? (
    <>
      <IconButton
        sx={{ display: { xs: "none", sm: "none", md: "none", lg: "unset" } }}
        onClick={handleZoomIn}
      >
        <ZoomInOutlined />
      </IconButton>

      <IconButton
        sx={{ display: { xs: "none", sm: "none", md: "none", lg: "unset" } }}
        onClick={handleZoomOut}
      >
        <ZoomOutOutlined />
      </IconButton>

      <div
        ref={containerRef}
        onDrag={onDragStartHandler}
        // onDragStart={gotoNext}
        // onDragEnd={gotoPrev}
        className="project_container"
      >
        <DndProvider backend={HTML5Backend}>
          <Slider ref={sliderRef} {...settings}>
            {columnList?.map((item, index) => (
              <React.Fragment key={item?.id}>
                <Column
                  key={index}
                  title={item?.displayName}
                  columnName={item?.name}
                  columnId={item?.id}
                  totalSystemCost={item?.totalSystemCost}
                  totalSystemSize={item?.totalSystemSize}
                  uid={item?.uid}
                  userName={item?.userName}
                  groups={item?.groups}
                  users={item?.users}
                  className="project_card_column"
                  colWidth={colWidth}
                  fontSize={fontSize}
                  expandView={expandView}
                  count={item?.count}
                  projectList={searchValue !== "" ? searchList : items}
                >
                  {returnItemsForColumn(
                    item?.name,
                    item?.displayName,
                    fontSize
                  )}
                </Column>
              </React.Fragment>
            ))}
          </Slider>
        </DndProvider>
      </div>
    </>
  ) : (
    <div className="d_flex align_items_center justify_content_center">
      <p>No projects to display</p>
    </div>
  );
};
