import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const MyCustomNoRowsOverlay = () => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p style={{ margin: 0 }}>No data to display</p>
    </div>
  );
};

export default function DataTable({
  rows,
  columns,
  onRowClick,
  style,
  loading,
  checkboxSelection,
  onRowSelectionModelChange,
  disableRowSelectionOnClick,
  isRowSelectable
}) {
  const [tableLoading, setTableLoading] = React.useState(loading);

  React.useEffect(() => {
    if (loading) {
      setTableLoading(true);
    } else {
      setTableLoading(false);
    }
  }, [loading]);

  return (
    <div style={style ?? { height: "100%", width: "100%" }}>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaders": {
            background: "#F8F8F9",
          },
          ".MuiDataGrid-overlayWrapper": {
            height: "100px",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: "600",
            lineHeight: "19.018px",
          },
          ".MuiDataGrid-cellContent": {
            fontSize: "12px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            lineHeight: "19.018px",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
          "& .MuiTypography-root": {
            fontSize: "12px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            lineHeight: "19.018px",
          },
          borderRadius: "8px",
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        onRowClick={onRowClick}
        slots={{
          noRowsOverlay: MyCustomNoRowsOverlay,
        }}
        loading={tableLoading}
        checkboxSelection={ checkboxSelection?true:false}
        onRowSelectionModelChange={onRowSelectionModelChange}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        isRowSelectable={isRowSelectable}
    
      />
    </div>
  );
}
