import Button from "../../buttons/Button";
import CurrencyView from "../../currency/CurrencyView";

const LabelValue = ({
  label,
  value,
  buttonLabel,
  onClick,
  className,
  type,
}) => {
  return (
    <p className={className ?? ""}>
      <b>{label}: </b>
      <span>
        {value && value !== "" ? (
          type === "currency" ? (
            <CurrencyView amount={value} />
          ) : (
            value
          )
        ) : (
          <Button
            onClick={onClick}
            className="default_button link_blue border_none p_0"
          >
            {buttonLabel}
          </Button>
        )}
      </span>
    </p>
  );
};

export default LabelValue;
