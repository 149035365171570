import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "../../../assets/images/close";
import PlusIcon from "../../../assets/images/plus";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Button from "../../../components/buttons/Button";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";
import {
  createProjectStatusStageOrg,
  deleteProjectStatusStageOrg,
  getProjectStatusStageListOrg,
  updateProjectStatusStageOrderOrg,
} from "../../../redux/slices/orgSlice";
import { CardItem } from "../../../utils/CardItem";
import CustomBreadcrumbOrg from "../view/CustomBreadCrumbOrg";
import StageCard from "../view/StageCard";

const WorkFlowStages = () => {
  const { id, status_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectStatus } = location.state;
  const projectStatusStageListOrg = useSelector(
    (state) => state.org.projectStatusStageListOrg
  );
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const loading = useSelector((state) => state.org.loading);
  const projectStatusStageLoading = useSelector(
    (state) => state.org.projectStatusStageLoading
  );

  // const projectStatusStageListOrg=useSelector((state)=>state.org.projectStatusStageListOrg)
  const [showAddStageModal, setShowAddStageModal] = useState(false);
  const [openStageEditModal, setOpenEditModal] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [droppedItem, setDroppedItem] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [stagesList, setStagesList] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemDeleted, setItemDeleted] = useState(false);
  const dispatch = useDispatch();
  const breadcrumbs = [
    <Link key="1" to={`/org/${id}/workflow`}>
      <b>
        {projectStatus.displayName
          ? projectStatus.displayName
          : projectStatus.name}
      </b>
    </Link>,
    <Typography key="2" color="text.primary">
      Stages
    </Typography>,
  ];
  const {
    register: registerAddForm,
    handleSubmit: handleSubmitAddForm,
    control,
    formState: { errors },
    reset: resetAddForm,
    getValues: getAddFormValue,
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const {
    register: registerEditForm,
    handleSubmit: handleSubmitEditForm,
    //control,
    formState: { errors:errorsEdit },
    reset: resetEditForm,
    setValue: setEditFormValue,
    getValues: getEditFormValue,
  } = useForm({
  });

  const loadProjectStages = () => {
    dispatch(
      getProjectStatusStageListOrg({
        url: `/crm/org/${id}/proj/status/${status_id}/stages`,
      })
    );
  };

  let count = 0;
  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = stagesList[dragIndex];
    if (dragItem) {
      setStagesList((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
        //  reArrangeOrder(coppiedStateArray);
        return coppiedStateArray;
      });
    }
  };

  const reArrangeOrder = (stages) => {
    dispatch(
      updateProjectStatusStageOrderOrg({
        url: `/crm/user/organization/${id}/status`,
        params: {
          orgId: id,
          // id: status_id,
        },
        data: {
          status: [
            {
              id: status_id,
              name: projectStatus?.name,
              order: projectStatus.order,
              isactive: true,
              displayName: projectStatus?.name,
              stages: stages.map((item, index) => {
                return {
                  ...item,
                  order: index,
                };
              }),
            },
          ],
        },
      })
    );
  };

  const handleDelete = (stage) => {
    setStagesList(projectStatusStageListOrg);
    let indexToDelete = null;
    let stageItemToDelete = null;
    stagesList.map((item, index) => {
      if (item.id == stage.id) {
        indexToDelete = index;
        stageItemToDelete = item;
      }
    });
    stageItemToDelete = {
      ...stageItemToDelete,
      isactive: false,
    };
    let temp = [...stagesList];
    temp[indexToDelete] = stageItemToDelete;
    setItemToDelete(stageItemToDelete);
    setItemDeleted(true);
    dispatch(
      deleteProjectStatusStageOrg({
        url: `/crm/user/organization/${id}/status`,
        params: {
          orgId: id,
          // id: status_id,
        },
        data: {
          status: [
            {
              id: status_id,
              name: projectStatus.name,
              order: projectStatus.order,
              isactive: true,
              displayName: projectStatus.displayName
                ? projectStatus.displayName
                : projectStatus.name,
              stages: temp,
            },
          ],
        },
      })
    );
  };

  const addStage = (data) => {
    dispatch(
      createProjectStatusStageOrg({
        url: `/crm/user/organization/${id}/status`,
        params: {
          orgId: id,
          // id: status_id,
        },
        data: {
          status: [
            {
              id: status_id,
              name: projectStatus.name,
              order: projectStatus.order,
              isactive: true,
              displayName: projectStatus.displayName,
              stages: [
                ...stagesList?.map((item, index) => {
                  return { ...item, order: index };
                }),
                {
                  name: data.name,
                  displayName: data.displayName,
                  order: stagesList.length ? stagesList.length : 0,
                  isactive: true,
                  totalDays: Number(data.totalDays),
                },
              ],
            },
          ],
        },
      })
    );
    //goBack()
  };
  const editStage = (data) => {
    let indexToEdit = null,
      stageItemToEdit = null;
    stagesList.map((item, index) => {
      if (item.id == itemToEdit.id) {
        indexToEdit = index;
        stageItemToEdit = item;
      }
    });
    stageItemToEdit = {
      ...stageItemToEdit,
      ...data,
    };
    let temp = [...stagesList];
    temp[indexToEdit] = stageItemToEdit;
    dispatch(
      createProjectStatusStageOrg({
        url: `/crm/user/organization/${id}/status`,
        params: {
          orgId: id,
          // id: status_id,
        },
        data: {
          status: [
            {
              id: status_id,
              name: projectStatus.name,
              order: projectStatus.order,
              isactive: true,
              displayName: projectStatus.displayName
                ? projectStatus.displayName
                : projectStatus.name,
              stages: temp,
            },
          ],
        },
      })
    );
  };

  const handleEdit = (stage) => {
    let stageItemToEdit = stagesList.filter((item) => item.name == stage.name);
    console.log("stage item to edit", stageItemToEdit);
    setEditFormValue("name", stageItemToEdit[0]?.name);
    setEditFormValue(
      "displayName",
      stageItemToEdit[0]?.displayName
        ? stageItemToEdit[0]?.displayName
        : stageItemToEdit[0]?.name
    );
    setEditFormValue(
      "totalDays",
     (Number(stageItemToEdit[0].totalDays)!==0 ) ?    Number(stageItemToEdit[0].totalDays) : null
    );
    setItemToEdit(stageItemToEdit[0]);
    setOpenEditModal(true);
  };

  const getMaximumValueOfDaysPossible = () => {
    let totalStagesDays = projectStatusStageListOrg.reduce(
      (sum, item) => sum + Number(item?.totalDays),
      0
    );
    console.log("total stage days", totalStagesDays);
    console.log("project status total days", Number(projectStatus?.totalDays));
    let possibleDays = projectStatus?.totalDays - totalStagesDays;
    console.log("possible days", possibleDays);
    return possibleDays;
    // return 0;
  };
console.log('edit',errorsEdit);
  useEffect(() => {
    if (snackbarData?.createSuccess) {
      setShowAddStageModal(false);
      resetAddForm();
      setOpenEditModal(false);
      resetEditForm();
    }
    loadProjectStages();
  }, [snackbarData]);

  useEffect(() => {
    setStagesList(projectStatusStageListOrg);
  }, [projectStatusStageListOrg]);
  return (
    <>
      <CardItem height="890px">
        {
          // projectStatusStageLoading ? (
          //   <Loading />
          // ) :

          <>
            <div style={{ padding: "20px" }}>
              <div className="d_flex justify_content_between">
                <div className="d_flex align_items_center">
                  <CustomBreadcrumbOrg breadcrumbs={breadcrumbs} />
                </div>
                <Button
                  type="submit"
                  className="primary_button d_flex align_items_center"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    padding: "5px 20px",
                  }}
                  leftIcon={<PlusIcon color="#fff" />}
                  onClick={() => {
                    setStagesList(projectStatusStageListOrg);
                    setShowAddStageModal(true);
                  }}
                >
                  Add Stage
                </Button>
              </div>
              <DndProvider backend={HTML5Backend}>
                <div
                  className="card_body"
                  style={{
                    padding: "20px",
                    overflowY: "scroll",
                    maxHeight: "680px",
                  }}
                >
                  <div
                    className="d_flex  marginT10"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {stagesList?.map((item, index) => {
                      return (
                        <>
                          <StageCard
                            stage={item}
                            index={index}
                            // moveListItem={moveListItem}
                            //handleDrop={handleDrop}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            moveCardHandler={moveCardHandler}
                            stagesList={stagesList}
                            reArrangeOrder={reArrangeOrder}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </DndProvider>
            </div>
          </>
        }

        <BasicModal
          isOpen={showAddStageModal}
          onClose={() => {
            setShowAddStageModal(false);
            resetAddForm();
          }}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "42%",
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "430px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <>
            <div className="modal_header">
              <p className="modal_title ">Add Stage</p>
              <div className="modal_header_button_wrapper">
                <IconButton
                  onClick={() => {
                    setShowAddStageModal(false);
                    resetAddForm();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <div className="modal_body">
              <div
                className="organisation"
                style={{ display: "block", padding: "0px" }}
              >
                <div className="">
                  <form noValidate onSubmit={handleSubmitAddForm(addStage)}>
                    <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={7} lg={7}>
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          {...registerAddForm("name", {
                            required: true,
                            //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <label>Display</label>
                        <input
                          type="text"
                          name="displayName"
                          placeholder="Display Name"
                          {...registerAddForm("displayName", {
                            required: true,
                            //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,5
                          })}
                        />
                      </Grid>
                      {Number(projectStatus?.totalDays) !== 0 && (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <label>Rotting Time (In Days)</label>
                            <input
                              type="text"
                              name="totalDays"
                              placeholder="Rotting time"
                              {...registerAddForm("totalDays", {
                               // required: "Rotting time is required.",
                                validate: (value) =>
                                  !isNaN(value) ||
                                  "Rotting time must be a number.",
                                min: {
                                  value: 0,
                                  message: "Rotting time cannot be negative.",
                                },
                                max: {
                                  value: getMaximumValueOfDaysPossible(),
                                  message: `Rotting time should be less than ${getMaximumValueOfDaysPossible()}`,
                                },
                              })}
                            />
                            {errors.totalDays && (
                              <p className="error">
                                {errors.totalDays.message}
                              </p>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Button type="submit" className="primary_button marginT10">
                      Add Stage
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </>
        </BasicModal>
        <BasicModal
          isOpen={openStageEditModal}
          onClose={() => {
            setOpenEditModal(false);
            resetEditForm();
          }}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "42%",
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "430px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <>
            <div className="modal_header">
              <p className="modal_title ">Edit Stage</p>
              <div className="modal_header_button_wrapper">
                <IconButton
                  onClick={() => {
                    setOpenEditModal(false);
                    resetEditForm();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <div className="modal_body">
              <div
                className="organisation"
                style={{ display: "block", padding: "0px" }}
              >
                <div className="">
                  <form noValidate onSubmit={handleSubmitEditForm(editStage)}>
                    <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={7} lg={7}>
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          {...registerEditForm("name", {
                            required: true,
                            //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <label>Display</label>
                        <input
                          type="text"
                          name="displayName"
                          placeholder="Display Name"
                          {...registerEditForm("displayName", {
                            required: true,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <label>Rotting Time (In Days)</label>
                        <input
                          type="text"
                          name="totalDays"
                          placeholder="Rotting time"
                          {...registerEditForm("totalDays", {
                            validate: (value) =>
                              !isNaN(value) || "Rotting time must be a number.",
                            min: {
                              value: 0,
                              message: "Rotting time cannot be negative.",
                            },
                            max: {
                              value: getMaximumValueOfDaysPossible(),
                              message: `Rotting time should be less than ${getMaximumValueOfDaysPossible()}`,
                            },
                          })}
                        />
                        {errorsEdit.totalDays && (
                          <p className="error">{errorsEdit?.totalDays.message}</p>
                        )}
                      </Grid>
                    </Grid>
                    <Button type="submit" className="primary_button marginT10">
                      Edit Stage
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </>
        </BasicModal> 
      </CardItem>
    </>
  );
};

export default WorkFlowStages;
