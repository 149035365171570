import EditIcon from "@mui/icons-material/Edit";
import { Grid, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../../components/breadcrumb/CustomBreadcrumb";
import Button from "../../../components/buttons/Button";
import IconText from "../../../components/common/IconText";
import CardTitle from "../../../components/dashboard/CardTitle";
import DataTable from "../../../components/table/DataTable";
import {
  getUsersInGroup,
  removeUsersFromGroup,
} from "../../../redux/slices/groupSlice";
import { CardItem } from "../../../utils/CardItem";
import Storage from "../../../utils/Storage";
import { modalStyle } from "../../../components/constants/ConstData";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/images/close";
import BasicModal from "../../../components/modal/Modal";
const breadcrumbs = [
  <Link key="1" to="/groups">
    Groups
  </Link>,
  <Typography key="2" color="text.primary">
    Group View
  </Typography>,
];
const columns = [
  {
    field: "id",
    headerName: "User Id",
    width: 80,
  },
  {
    field: "mame",
    headerName: "User Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },

  {
    field: "email",
    headerName: "Email",
    width: 300,
    renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
  },
  {
    field: "user_type",
    headerName: "User Type",
    width: 300,
    renderCell: (params) => {
      let userType = "User";
      if (params.row.isOwner === "true") userType = "Owner";
      else if (params?.row?.isModerator == "true") userType = "Moderator";

      return <Typography>{userType}</Typography>;
    },
  },
];

const GroupView = () => {
  const location = useLocation();
  const { name, desc } = location.state || {};
  const [groupDescription, setGroupDescription] = useState(desc);
  const [groupName, setGroupName] = useState(name);
  const groupUsers = useSelector((state) => state.group.usersInGroup);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currentOrganization = Storage.read(`org${userInfo?.id}`);
  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const getUsers = () => {
    dispatch(
      getUsersInGroup({
        url: `crm/user/groups/${id}/users`,
        params: {},
      })
    );
  };
  const removeUser = () => {
    if (rowsSelected.length > 0) {
      const usrgrps = rowsSelected.map((item) => {
        return {
          userid: item,
          isactive: 0,
        };
      });
      dispatch(
        removeUsersFromGroup({
          url: `/crm/org/${currentOrganization.id}/groups/${id}/users`,
          params: {
            // orgId: currentOrganization.id,
            // id: id,
          },
          data: usrgrps,
        })
      );
      setRowsSelected([]);
      setReload(!reload);
    }
  };
  const editGroup = () => {};
  useEffect(() => {
    getUsers();
  }, [reload]);
  return (
    <div className="project_view">
      <CustomBreadcrumb breadcrumbs={breadcrumbs} />
      <Grid sx={{ marginTop: "16px" }} container spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <CardItem height="810px">
            <div className="card_container">
              <CardTitle title={`${name}`} type="project_view"></CardTitle>
              <div style={{ padding: "20px" }}>
                <DataTable
                  rows={[...groupUsers]}
                  columns={columns}
                  checkboxSelection={true}
                  onRowSelectionModelChange={(row) => {
                    setRowsSelected(row);
                  }}
                  //onRowClick={(row) => navigate(`/leads/${row.id}`)}
                  //loading={loading}
                  disableSelectionOnClick={(params) => {
                    return params.row.disableSelection || false;
                  }}
                />
              </div>
              <div style={{ padding: "20px" }}>
                {rowsSelected.length > 0 && (
                  <>
                    <Button
                      className="primary_button marginT10"
                      onClick={removeUser}
                    >
                      Remove User
                    </Button>
                  </>
                )}
              </div>
            </div>
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={4}>
          <CardItem height="410px">
            <div className="card_container">
              <CardTitle title="Group" type="project_view">
                {/* <EditIcon
                  className="btn_icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenEditGroupModal(true)}
                /> */}
              </CardTitle>
              <div className="card_content">
                <IconText title={`Name         :${name}`} />
                <IconText title={`Description :${desc}`} />
              </div>
            </div>
            <div></div>
          </CardItem>
        </Grid>
      </Grid>
      <BasicModal
        isOpen={openEditGroupModal}
        onClose={() => setOpenEditGroupModal(false)}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "600px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Edit Group</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={() => setOpenEditGroupModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(editGroup)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label className="text_medium">Group Name</label>
                      <input
                        type="text"
                        name="name"
                        value={groupName}
                        onChange={(e) => {
                          setGroupName(e.target.value);
                        }}
                        // value={"test"}
                        // defaultValue={name}
                        {...register("name", {
                          required: true,
                        })}
                      />
                      <p className="error_message">{errors.name?.message}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label className="text_medium">Description</label>desc
                      <textarea
                        type="text"
                        name="description"
                        rows={8}
                        value={groupDescription}
                        onChange={(e) => {
                          setGroupDescription(e.target.value);
                        }}
                        //defaultValue={desc}
                        {...register("description", {})}
                      />
                      <p className="error_message">
                        {errors.description?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </div>
  );
};

export default GroupView;
